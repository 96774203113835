import React, { useEffect } from 'react';
import { useProductDetails } from './hooks/ProductDetails.hook';
import { useLocation } from 'react-router-dom';
import ProductDetailsView from './views/ProductDetailsView';
import LoadingComponent from '@/components/general/Loading/LoadingComponent';

const ProductDetailController = () => {
  const location = useLocation();
  const { spaceData } = location.state || {};
  const spaceId = spaceData?.id_relationship;
  console.log('ID que se pasa al hook :C :', spaceId);
  const { isLoading, spacesData } = useProductDetails(spaceId);

  if (isLoading) {
    return <LoadingComponent loading={isLoading} />;
  } else {
    return (
      <ProductDetailsView spacesData={spacesData} spaceDetail={spaceData} />
    );
  }
};

export default ProductDetailController;
