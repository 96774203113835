import { combineReducers } from 'redux';

const initialUserState = null;
const initialFormDataState = null;
const initialLenguageState = { lang: null, isButton: false };
const initialLocationState = { detected: false, data: null };

const userReducer = (state = initialUserState, action) => {
  switch (action.type) {
    case 'SET_USER':
      return action.payload;
    case 'CLEAR_USER':
      return null;
    default:
      return state;
  }
};

const searchFormDataReducer = (state = initialFormDataState, action) => {
  switch (action.type) {
    case 'SET_SEARCH_FORM_DATA':
      return action.payload;
    case 'CLEAR_SEARCH_FORM_DATA':
      return null;
    default:
      return state;
  }
};

const lenguageDataReducer = (state = initialLenguageState, action) => {
  switch (action.type) {
    case 'SET_LENGUAGE_DATA':
      return action.payload;
    case 'CLEAR_LENGUAGE_DATA':
      return null;
    default:
      return state;
  }
};

const locationReducer = (state = initialLocationState, action) => {
  switch (action.type) {
    case 'SET_LOCATION':
      return {
        data: action.payload,
        detected: true,
      };
    case 'CLEAR_LOCATION':
      return { detected: false, data: null };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  user: userReducer,
  searchFormData: searchFormDataReducer,
  lenguage: lenguageDataReducer,
  location: locationReducer,
});

export default rootReducer;
