import * as Yup from 'yup';

export const getSpacesValidationSchema = () => {
  return Yup.object({
    space_name: Yup.string().required('Space Name is required'),
    space_address: Yup.string().required('Space Address is required'),
    space_title: Yup.string().required('Space Title is required'),
    main_image: Yup.array()
      .of(
        Yup.mixed()
          .required('La imagen es requerida')
          .test('fileSize', 'El archivo es demasiado grande', value => {
            return value && value.size <= 15000000;
          })
          .test('fileType', 'El formato no es válido', value => {
            return value && ['image/jpeg', 'image/png'].includes(value.type);
          }),
      )
      .required('Se requiere al menos una imagen'),
    host_id: Yup.number().required('Host ID is required'),
    house_number: Yup.number().required('House Number is required'),
    space_latitude: Yup.string().required('Latitude is required'),
    space_logitude: Yup.string().required('Longitude is required'),
    street_reference: Yup.string().required('Street Reference is required'),
    zip_code: Yup.number().required('Zip Code is required'),
    is_reserved: Yup.number().required('Reserved status is required'),
    gmaps_url: Yup.string()
      .url('Invalid URL')
      .required('Google Maps URL is required'),
    space_name_en: Yup.string().required('Space Name in english is required'),
    space_title_en: Yup.string().required('Space Title in english is required'),
    seo_title: Yup.string().required('SEO Title is required'),
    seo_description: Yup.string().required('SEO Description is required'),
    seo_keywords: Yup.string().required('SEO Keywords is required'),
    subtypeRelationship: Yup.array()
      .of(
        Yup.object({
          subtype_id: Yup.number().required('Subtype ID is required'),
          host_capacity: Yup.number().required('Host Capacity is required'),
          pet_friendly: Yup.number().required(
            'Pet Friendly status is required',
          ),
          coffe_water: Yup.number().required('Coffee/Water status is required'),
          hourly_rate: Yup.number().required('Hourly Rate is required'),
          rate_4_hours: Yup.number().required('4 Hours Rate is required'),
          rate_8_hours: Yup.number().required('8 Hours Rate is required'),
          state_id: Yup.number().required('State ID is required'),
          parking: Yup.number().required('Parking availability is required'),
          order: Yup.number().required('Order availability is required'),
          main_image_subtype: Yup.array()
            .of(
              Yup.mixed()
                .required('La imagen es requerida')
                .test('fileSize', 'El archivo es demasiado grande', value => {
                  return value && value.size <= 15000000;
                })
                .test('fileType', 'El formato no es válido', value => {
                  return (
                    value && ['image/jpeg', 'image/png'].includes(value.type)
                  );
                }),
            )
            .required('Se requiere al menos una imagen'),
          rate_24_hours: Yup.number().required('24 Hours Rate is required'),
          staff: Yup.number().required('Reserved staff is required'),
          cleaning_service: Yup.number().required(
            'Reserved cleaning service is required',
          ),
          phone_service: Yup.number().required(
            'Reserved phone service is required',
          ),
          printing_service: Yup.number().required(
            'Reserved printing is required',
          ),
          common_areas: Yup.number().required(
            'Reserved common areas is required',
          ),
          convention_hall: Yup.number().required(
            'Reserved convention all is required',
          ),
          snacks: Yup.number().required('Reserved snacks is required'),
        }),
      )
      .required('At least one relationship is required'),
  });
};
