export const setLocation = (locationData) => {
  return {
    type: 'SET_LOCATION',
    payload: locationData,
  };
};

export const clearLaocation = () => ({
  type: 'CLEAR_LOCATION',
});
