import React, { useState, useEffect } from 'react';
import Rating from 'rating-react';
import { FlexContainer } from '@/components/containers/FlexContainer/FlexContainer';

const IndividualCommentComponent = props => {
  const { name, stars, detail } = props;
  return (
    <FlexContainer
      className={'flex-column borderGray050 border-rad8px padlr40 padtb20'}
    >
      <FlexContainer className={'gap-8 text-18'}>
        {stars} Estrellas{' '}
        <Rating
          value={stars}
          size={'sm'}
          activeColor="#bdbdbd"
          color="#bdbdbd"
        />
      </FlexContainer>
      <FlexContainer className={'gap-8 text-18'}>
        <strong>{name}</strong>
      </FlexContainer>
      <FlexContainer className={'gap-8 text-18'}>
        {detail ? detail : 'Sin comentarios'}
      </FlexContainer>
    </FlexContainer>
  );
};

export default IndividualCommentComponent;
