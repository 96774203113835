import React from 'react';
import { FlexContainer } from '@/components/containers/FlexContainer/FlexContainer';
import { PageContainer } from '@/components/containers/PageContainer/PageContainer';
import Label from '@/components/form/Label/Label';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';

const Footer = (props) => {
  const {} = props;
  const lenguageData = useSelector((state) => state.lenguage?.lang);
  return (
    <PageContainer className={'bgBlue400'}>
      <FlexContainer className={'flex-column padt20 padb60'} justifyBetween alignItemsStart>
        <FlexContainer className={'m-flex-column width100 margt20'}>
          <FlexContainer className={'flex-column format100'}>
            <img src="logo.png" alt="Foto 1" style={{ width: '35%' }} className={''} />
            <FlexContainer className={'width100'}>
              <a
                href="https://www.facebook.com/popnestmexico"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-facebook-f fontWhite text-18 margl10" />
              </a>
              <a
                href="https://www.tiktok.com/@popnest.connect"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-tiktok fontWhite text-18 margl10" />
              </a>
              <a
                href="https://www.instagram.com/popnest_connect/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-instagram fontWhite text-18 margl10" />
              </a>
              <a
                href="https://www.linkedin.com/company/popnest/posts/?feedView=all"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-linkedin-in fontWhite text-18 margl10" />
              </a>
            </FlexContainer>
            <p className={'fontWhite margt20 text-10 padlr8'}>
              {lenguageData === 'ES' ? (
                <>
                  Somos un equipo de apasionados por la innovación y el buen vivir. Ofrecemos
                  espacios de trabajo únicos, con privacidad, buen diseño y sin ruido.
                </>
              ) : (
                <>
                  We're passionate about innovation and modern living. Popnest offers unique
                  workspaces with privacy, great design, and a quiet atmosphere.
                </>
              )}
            </p>
          </FlexContainer>
          <FlexContainer className={'flex-column padlr8 format100 m-formatAuto'}>
            <ul
              className={'fontWhite subTitle1 text-12'}
              style={{ listStyleType: 'none', padding: 0 }}
            >
              <li className={'margb20'}>
                <Link to="/" className={'fontWhite'} style={{ textDecoration: 'none' }}>
                  {lenguageData === 'ES' ? <>Inicio</> : <>Home</>}
                </Link>
              </li>
              <li className={'margb20'}>
                <Link
                  to={lenguageData === 'ES' ? '/espacios' : '/spaces'}
                  className={'fontWhite'}
                  style={{ textDecoration: 'none' }}
                >
                  {lenguageData === 'ES' ? <>Espacios</> : <>Places</>}
                </Link>
              </li>
              <li className={'margb20'}>
                <Link
                  to={lenguageData === 'ES' ? '/blog-popnest' : '/blog'}
                  className={'fontWhite'}
                  style={{ textDecoration: 'none' }}
                >
                  {lenguageData === 'ES' ? <>Blog</> : <>Blog</>}
                </Link>
              </li>
            </ul>
          </FlexContainer>
          <FlexContainer className={'flex-column padlr8 format100 m-formatAuto'}>
            <ul
              className={'fontWhite subTitle1 text-12'}
              style={{ listStyleType: 'none', padding: 0 }}
            >
              {/*
              <li className={'margb20'}>
                <Link to="/" className={'fontWhite'} style={{ textDecoration: 'none' }}>
                  {lenguageData === 'ES' ? <>Mi Perfil</> : <>My Profile</>}
                </Link>
              </li>
              */}
              <li className={'margb20'}>
                <Link
                  to={
                    lenguageData === 'ES'
                      ? '/terminos-condiciones-invitado'
                      : '/terms-conditions-guest'
                  }
                  className={'fontWhite'}
                  style={{ textDecoration: 'none' }}
                >
                  {lenguageData === 'ES' ? (
                    <>Términos y Condiciones Invitado</>
                  ) : (
                    <>Host Terms and Conditions</>
                  )}
                </Link>
              </li>
              <li className={'margb20'}>
                <Link
                  to={
                    lenguageData === 'ES'
                      ? '/terminos-condiciones-anfitrion'
                      : '/terms-conditions-host'
                  }
                  className={'fontWhite'}
                  style={{ textDecoration: 'none' }}
                >
                  {lenguageData === 'ES' ? (
                    <>Términos y Condiciones Anfitrión</>
                  ) : (
                    <>Guest Terms and Conditions</>
                  )}
                </Link>
              </li>
            </ul>
          </FlexContainer>
          <FlexContainer className={'flex-column padlr8 format100 m-formatAuto'}>
            <ul
              className={'fontWhite subTitle1 text-12'}
              style={{ listStyleType: 'none', padding: 0 }}
            >
              {/*
              <li className={'margb20'}>
                <Link to="/" className={'fontWhite'} style={{ textDecoration: 'none' }}>
                  {lenguageData === 'ES' ? <>Mi Perfil</> : <>My Profile</>}
                </Link>
              </li>
              */}
              <li className={'margb20'}>
                <Link
                  to={lenguageData === 'ES' ? '/condiciones-privacidad' : '/privacy-conditions'}
                  className={'fontWhite'}
                  style={{ textDecoration: 'none' }}
                >
                  {lenguageData === 'ES' ? <> Aviso de Privacidad</> : <>Privacy Notice</>}
                </Link>
              </li>
            </ul>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer className={'width100 margt60'}>
          <Label className={'subTitle2 text-10 m-text-8 fontWhite margt8 padlr8'}>
            {lenguageData === 'ES' ? (
              <>Copyright © 2024 Finovix Sapi de CV. Todos los derechos reservados.</>
            ) : (
              <>Copyright © 2024 Finovix SAPI de CV. All rights reserved.</>
            )}
          </Label>
        </FlexContainer>
      </FlexContainer>
    </PageContainer>
  );
};

export default Footer;
