import React, { useState, useEffect } from 'react';
import ParadiseMudNavbar from 'paradise-mud-navbar';
import Modal from 'react-modal';
import GeneralLoginForm from '@/components/general/Forms/GeneralLoginForm/GeneralLoginForm';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { FlexContainer } from '@/components/containers/FlexContainer/FlexContainer';
import { useNavigate } from 'react-router-dom';
import { setLenguageData } from '@/redux/actions/lenguageDataActions';
import 'paradise-mud-navbar/src/components/ParadiseMudNavbar.css';
import './PersonalizedHeaderStyles.scss';

const Header = (props) => {
  const {} = props;
  const dispatch = useDispatch();
  const lenguageData = useSelector((state) => state.lenguage.lang);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [modalIsOpen, setIsOpen] = React.useState(false);

  let subtitle;

  const customStyles = {
    overlay: {
      position: 'fixed',
      zIndex: 3,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(255, 255, 255, 0.75)',
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  const routesWithoutHeaderOptions = [
    '/paseDeCoworkingPolanco',
    '/dayPassCoworkingPolanco',
    '/paseDeCoworkingCondesa',
    '/dayPassCoworkingCondesa',
    '/salaDeJuntasPolanco',
    '/meetingRoomPolanco',
  ];

  const location = useLocation();
  const effectOnScroll = location.pathname === '/';
  const hideHeaderOptions = routesWithoutHeaderOptions?.includes(location.pathname);

  const navigate = useNavigate();

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  const hideMobileMenu = () => {
    const modal = document.querySelector('.paradise-mud-mobile-navbar-modal.visible');
    if (modal) {
      modal.classList.remove('visible');
    }
  };

  const changueLang = () => {
    if (lenguageData === 'EN') {
      dispatch(setLenguageData({ lang: 'ES', isButton: true }));
    } else {
      dispatch(setLenguageData({ lang: 'EN', isButton: true }));
    }
  };

  const goToHome = () => {
    hideMobileMenu();
    navigate('/');
  };

  const goToSpaces = () => {
    hideMobileMenu();
    navigate(lenguageData === 'ES' ? '/espacios' : '/spaces');
  };

  const goToBlog = () => {
    hideMobileMenu();
    navigate(lenguageData === 'ES' ? '/blog-popnest' : '/blog');
  };

  const goToYourSpaces = () => {
    hideMobileMenu();
    navigate(lenguageData === 'ES' ? '/tu-espacio' : '/your-spaces');
  };

  const openModal = () => {
    hideMobileMenu();
    setIsOpen(true);
  };

  const afterOpenModal = () => {
    subtitle.style.color = '#f00';
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    const logo = document.querySelector('.paradise-mud-navbar-logo-img');

    if (logo) {
      logo.style.cursor = 'pointer'; // Asegurar que se vea clickeable
      logo.addEventListener('click', () => navigate('/')); // Redirigir al hacer clic
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (logo) {
        logo.removeEventListener('click', () => navigate('/'));
      }
    };
  }, []);

  const navBarStyleConditions = scrollPosition > 280 || !effectOnScroll;
  const className = navBarStyleConditions ? '' : 'transparent-navbar';
  const navbarMainLogo = navBarStyleConditions ? '/logo2.png' : '/logo.png';
  const user = useSelector((state) => state.user);

  return (
    <FlexContainer
      className={
        hideHeaderOptions ? 'flex-column myMainNavBarHideToogle' : 'flex-column myMainNavBar'
      }
    >
      <ParadiseMudNavbar
        navbarClassNames={[className]}
        navbarLogos={[navbarMainLogo]}
        navbarLinks={
          hideHeaderOptions
            ? []
            : [
                {
                  title: lenguageData === 'ES' ? 'Inicio' : 'Home',
                  onClick: goToHome,
                },
                {
                  title: lenguageData === 'ES' ? 'Espacios' : 'Places',
                  onClick: goToSpaces,
                },
                { title: lenguageData === 'ES' ? 'Blog' : 'Blog', onClick: goToBlog },
                {
                  title: lenguageData === 'ES' ? 'Ver en Ingles' : 'See in Spanish',
                  onClick: changueLang,
                },
                //{ title: 'Publica tu espacio', onClick: goToYourSpaces },
                { title: user ? 'Hola ' + user?.name : 'Iniciar Sesión', onClick: openModal },
              ]
        }
        //navbarButtons={[{ title: 'Click Me', onClick: () => alert('Button clicked!') }]}
        linksPosition={'right'}
      />
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2>
        <button className={'fright'} onClick={closeModal}>
          x
        </button>
        <GeneralLoginForm
          inputValue={''}
          onRequestClose={closeModal}
          className={'marglr40 m-marglr20 margt40'}
        />
      </Modal>
    </FlexContainer>
  );
};

export default Header;
