import React from 'react';
import { FlexContainer } from '@/components/containers/FlexContainer/FlexContainer';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { useSelector } from 'react-redux';
import Label from '@/components/form/Label/Label';
import classNames from 'classnames';
import Rating from 'rating-react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import './NewSpaceCardDetailVersion.scss';

const truncateDescription = (description, limit) => {
  if (description.length > limit) {
    return description.substring(0, limit) + '...';
  }
  return description;
};

const HeadCard = (props) => {
  const { spaceInfo, extraClass, lenguage } = props;
  const containerClass = 'align-self-left ' + extraClass;
  return (
    <FlexContainer className={containerClass}>
      <Label className={'fontGray250 margt20 margb10 subTitle2 text-18 m-text-16'}>
        <div
          style={{ fontWeight: 500 }}
          dangerouslySetInnerHTML={{
            __html: truncateDescription(
              lenguage === 'ES' ? spaceInfo?.space_title : spaceInfo?.space_title_en,
              40,
            ).toUpperCase(),
          }}
        />
      </Label>
    </FlexContainer>
  );
};

const NewSpaceCardDetailVersion = (props) => {
  const { spaceInfo, handleDetailButton, priceAndCapacityDetails } = props;
  const lenguage = useSelector((state) => state.lenguage?.lang);
  const containerClass = classNames('NewSpaceCardDetailVersion flex-column format100 bgWhite');
  const textContainerClass = classNames('flex-column margt20 justify-around');
  const imageItemStyle = { width: '100%' };
  const typeSpanStyle = { zIndex: 2 };

  const translations = {
    'Sala de Juntas': 'Meeting Room',
    Terrazas: 'Terrace',
    Coworking: 'Coworking',
  };
  const translatedTitle = translations[spaceInfo?.subtype_title] || spaceInfo?.subtype_title;

  const imagesArray = (() => {
    try {
      const parsed = JSON.parse(spaceInfo?.main_image);
      return Array.isArray(parsed) && parsed.length > 0
        ? parsed
        : [spaceInfo?.main_image].filter(Boolean);
    } catch {
      return [spaceInfo?.main_image].filter(Boolean);
    }
  })();

  return (
    <FlexContainer className={containerClass} onClick={handleDetailButton}>
      {spaceInfo?.subtype_title && (
        <span
          style={typeSpanStyle}
          className={
            'bgWhite border-rad4px text-14 padt12 padb12 padlr10 pos-abs margt10 margb20 marglr10'
          }
        >
          {lenguage === 'EN' ? translatedTitle : spaceInfo?.subtype_title}
        </span>
      )}
      <FlexContainer className={'width100 d-hide'}>
        <Swiper
          navigation
          autoplay={{
            delay: 10500,
            disableOnInteraction: true,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay, Pagination, Navigation]}
          spaceBetween={30}
          slidesPerView={1}
        >
          {imagesArray.map((image, index) => (
            <SwiperSlide key={index}>
              <img src={image} alt={spaceInfo?.space_title} style={imageItemStyle} />
            </SwiperSlide>
          ))}
        </Swiper>
      </FlexContainer>
      <FlexContainer className={'width100 m-hide'}>
        <img src={imagesArray[0]} alt={spaceInfo?.space_title} style={imageItemStyle} />
      </FlexContainer>
      <HeadCard spaceInfo={spaceInfo} lenguage={lenguage}/>
      <FlexContainer className={textContainerClass}>
        <FlexContainer className={'gap-8 text-18'}>
          {lenguage === 'EN' ? ' Stars' : ' Estrellas'}{' '}
          <Rating value={4} size={'sm'} activeColor="#bdbdbd" color="#bdbdbd" />
        </FlexContainer>
        <Label type="text-16">
          <div
            dangerouslySetInnerHTML={{
              __html: truncateDescription(
                lenguage === 'EN' ? spaceInfo?.space_details_en : spaceInfo?.space_details,
                120,
              ),
            }}
          />
        </Label>
        {priceAndCapacityDetails && (
          <FlexContainer className={'margt10 margb10 padt10 width100 gap-20'}>
            <FlexContainer className={'width100 flex-column gap-4'}>
              <FlexContainer className={'width100'}>
                <Label className={'fontGray400'} type={'text-20'}>
                  <strong>
                    ${' '}
                    {parseFloat(
                      spaceInfo?.subtype_title === 'Coworking'
                        ? spaceInfo?.rate_24_hours
                        : spaceInfo?.hourly_rate,
                    )
                      .toFixed(2)
                      .replace(/\.00$/, '')}{' '}
                    MXN
                  </strong>
                </Label>
              </FlexContainer>
              <FlexContainer className={'width100'}>
                <Label className={'fontGray6'} type="text-14">
                  {lenguage === 'ES' ? 'Capacidad:' : 'Capacity:'} {spaceInfo?.host_capacity}{' '}
                  {lenguage === 'ES' ? ' Personas' : ' People'}
                </Label>
              </FlexContainer>
              <FlexContainer className={'width100'}>
                <Label className={'fontGray6'} type={'text-14'}>
                  {spaceInfo?.subtype_title === 'Coworking' ? 'Precio por día.' : 'Precio por h.'}
                </Label>
              </FlexContainer>
            </FlexContainer>
            <FlexContainer className={'width100  flex-column gap-10'}>
              <FlexContainer className={'width100'}>
                <button
                  onClick={handleDetailButton}
                  className={
                    'width100 fontWhite bgBlue400 border-rad18px padlr20 padtb10 borderNone text-14'
                  }
                >
                  <strong> {lenguage === 'ES' ? 'Reservar' : 'Book'}</strong>
                </button>
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
        )}
      </FlexContainer>
    </FlexContainer>
  );
};

export default NewSpaceCardDetailVersion;
