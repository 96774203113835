import React, { useState } from 'react';
import Modal from 'react-modal';
import { FlexContainer } from '@/components/containers/FlexContainer/FlexContainer';
import Label from '@/components/form/Label/Label';
import classNames from 'classnames';
import { FaCog } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import DetailedSpaceInfo from '@/components/reusable/DetailedSpaceInfo';
import './NewSpaceCardShortVersion.scss';

Modal.setAppElement('#root');

const truncateDescription = (description, limit) => {
  if (description?.length > limit) {
    return description.substring(0, limit) + '...';
  }
  return description;
};

const NewSpaceCardShortVersion = (props) => {
  const {
    spaceInfo,
    isMobileStyle,
    handleDetailButton,
    isInlineStyle,
    isWhiteBg,
    priceAndCapacityDetails,
  } = props;

  const lenguage = useSelector((state) => state.lenguage?.lang);
  const user = useSelector((state) => state.user);
  console.log('ver user;', user);

  const translations = {
    'Sala de Juntas': 'Meeting Room',
    Terrazas: 'Terrace',
    Coworking: 'Coworking',
  };

  const translatedTitle = translations[spaceInfo?.subtype_title] || spaceInfo?.subtype_title;

  const containerClass = classNames('borderGray050 border-rad8px', {
    'gap-20 m-flex-column': isInlineStyle,
    'flex-column format100': !isInlineStyle,
    margtb20: isMobileStyle,
    bgWhite: isWhiteBg,
  });

  const textContainerClass = classNames('flex-column padlr20', {
    'justify-around': isInlineStyle,
  });

  let imageItemStyle = {};
  if (isInlineStyle) {
    imageItemStyle = { width: '100%' };
  } else {
    imageItemStyle = isMobileStyle
      ? {
          height: '00px',
          width: '100%',
          borderTopLeftRadius: '18px',
          borderTopRightRadius: '18px',
        }
      : {
          width: '100%',
          height: '300px',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
        };
  }

  const imageToDisplay = (() => {
    try {
      const parsed = JSON.parse(spaceInfo?.main_image);
      return Array.isArray(parsed) && parsed.length > 0 ? parsed[0] : spaceInfo?.main_image;
    } catch {
      return spaceInfo?.main_image;
    }
  })();

  const typeSpanStyle = { zIndex: 2 };

  const [modalIsOpen, setModalIsOpen] = useState(false);
  let subtitle; // referencia al título del modal

  const customModalStyles = {
    overlay: {
      position: 'fixed',
      zIndex: 1000,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      padding: '20px',
    },
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const afterOpenModal = () => {
    if (subtitle) {
      subtitle.style.color = '#f00';
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <FlexContainer
      className={containerClass}
      onClick={handleDetailButton}
      style={{ position: 'relative' }}
    >
      {user && (
        <div
          className="icon-container"
          onClick={(e) => {
            e.stopPropagation();
            console.log('Icon clicked', spaceInfo);
            openModal();
          }}
        >
          <FaCog size={24} color="#808080" />
        </div>
      )}

      {spaceInfo?.subtype_title && (
        <span
          style={typeSpanStyle}
          className="bgWhite border-rad4px text-14 padt12 padb12 padlr10 pos-abs margt20 marglr10"
        >
          {lenguage === 'EN' ? translatedTitle : spaceInfo?.subtype_title}
        </span>
      )}
      <FlexContainer className="width100">
        <img src={imageToDisplay} alt={spaceInfo?.space_name} style={imageItemStyle} />
      </FlexContainer>
      <FlexContainer className={textContainerClass}>
        <Label className="margt20 text-20 align-self-start">
          <strong>
            <div
              style={{ fontWeight: 500 }}
              dangerouslySetInnerHTML={{
                __html: truncateDescription(spaceInfo?.space_title, 40),
              }}
            />
          </strong>
        </Label>
        <Label type="text-14 align-self-start">
          {truncateDescription(spaceInfo?.space_address, 30)}
        </Label>
        <Label type="text-14 align-self-start">
          {lenguage === 'ES' ? 'Capacidad:' : 'Capacity:'} {spaceInfo?.host_capacity}{' '}
          {lenguage === 'ES' ? ' Personas' : ' People'}
        </Label>
        {priceAndCapacityDetails && (
          <FlexContainer className="borderTp margt30 margb10 padt20 width100">
            <FlexContainer className="width100 flex-column gap-6">
              <FlexContainer className="width100">
                <Label className="fontGray6" type="text-14">
                  {spaceInfo?.host_capacity} {lenguage === 'ES' ? ' Personas' : ' People'}
                </Label>
              </FlexContainer>
              <FlexContainer className="width100">
                <Label className="fontGray6" type="text-14">
                  {lenguage === 'ES' ? 'Precio por' : 'Cost per'}{' '}
                  {spaceInfo?.subtype_title === 'Coworking' ? 'Un día' : '1 h'}.
                </Label>
              </FlexContainer>
            </FlexContainer>
            <FlexContainer className="width100 flex-column gap-10">
              <FlexContainer className="width100">
                <Label className="fontGray400" type={isInlineStyle ? 'text-18' : 'text-20'}>
                  <strong>
                    ${' '}
                    {parseFloat(
                      spaceInfo?.subtype_title === 'Coworking'
                        ? spaceInfo?.rate_24_hours
                        : spaceInfo?.hourly_rate,
                    )
                      .toFixed(2)
                      .replace(/\.00$/, '')}{' '}
                    MXN
                  </strong>
                </Label>
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
        )}
      </FlexContainer>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customModalStyles}
        contentLabel="Detailed Space Info"
      >
        <div onClick={(e) => e.stopPropagation()}>
          <DetailedSpaceInfo spaceInfo={spaceInfo} closeModal={closeModal} />
        </div>
      </Modal>
    </FlexContainer>
  );
};

export default NewSpaceCardShortVersion;
