import React, { useState, useEffect } from 'react';
import { PageContainer } from '@/components/containers/PageContainer/PageContainer';
import { FlexContainer } from '@/components/containers/FlexContainer/FlexContainer';
import { useNavigate } from 'react-router-dom';
import PaymentForm from '@/components/general/Forms/PaymentForm/PaymentForm';
import NewSpaceCardShortVersion from '@/components/reusable/NewSpaceCardShortVersion/NewSpaceCardShortVersion';
import Label from '@/components/form/Label/Label';
import { useSelector } from 'react-redux';
import HelmetComponent from '@/components/general/Helmet/HelmetComponent';
import './Checkout.scss';

const SpaceDetailInfo = props => {
  const { spaceDetail } = props;
  const lenguageData = useSelector(state => state.lenguage.lang);
  const esDescription =
    'Realiza pagos seguros y flexibles en Popnest. Elige tu plan, reserva tu espacio y trabaja sin preocupaciones.';
  const enDescription =
    'Make secure and flexible payments with Popnest. Choose your plan, book your space, and work hassle-free.';

  return (
    <FlexContainer className={'flex-column format100 m-hide'}>
      <HelmetComponent
        title={lenguageData === 'EN' ? 'Pay - Popnest' : 'Pago - Popnest'}
        description={lenguageData === 'EN' ? enDescription : esDescription}
        graphTitle={lenguageData === 'EN' ? 'Pay - Popnest' : 'Pago - Popnest'}
        graphDescription={lenguageData === 'EN' ? enDescription : esDescription}
      />
      <NewSpaceCardShortVersion
        spaceInfo={spaceDetail}
        isInlineStyle
        imgUrl={spaceDetail?.main_image}
        spaceTitle={spaceDetail?.space_title}
        spaceDescription={spaceDetail?.space_details}
        price={spaceDetail?.price}
        disableButton
        wifi
        coffee
        parking
        isWhiteBg
      />
    </FlexContainer>
  );
};

const PaymentSection = props => {
  const { spaceDetail, formValues } = props;
  return (
    <FlexContainer className={'flex-column format100'}>
      <PaymentForm spaceDetail={spaceDetail} formValues={formValues} />
    </FlexContainer>
  );
};

const Checkout = props => {
  const { spaceDetail, formValues } = props;
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleBack = () => {
    navigate(-1);
  };
  return (
    <PageContainer className={''}>
      <FlexContainer className={'flex-column margt72'}>
        <FlexContainer className={'margt50 m-margt20'}>
          <Label type="Subtitle-3 width100 m-padlr10 text-26 m-text-16 m-hide">
            Realice el pago de su reserva en {spaceDetail?.subtype_title}:{' '}
            {spaceDetail?.space_title}
          </Label>
          <Label type="width100 text-18 m-text-20 Subtitle-3 d-hide">
            Realice el pago
          </Label>
          <button
            type="button"
            className={
              'bgGray050 border-rad4px borderGray050 fontGray500 text-16'
            }
            onClick={handleBack}
          >
            <span
              className={'padlr10'}
              style={{ display: 'inline-flex', gap: '6px' }}
            >
              <i className={'fa fa-arrow-left margr6'} aria-hidden="true" />{' '}
              Atrás
            </span>
          </button>
        </FlexContainer>

        <FlexContainer className={'m-flex-column margt20 margb120 gap-20'}>
          <SpaceDetailInfo spaceDetail={spaceDetail} />
          <PaymentSection spaceDetail={spaceDetail} formValues={formValues} />
        </FlexContainer>
      </FlexContainer>
    </PageContainer>
  );
};

export default Checkout;
