import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

const HelmetComponent = (props) => {
  const { title, description, graphTitle, graphDescription } = props;
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={graphTitle} />
      <meta property="og:description" content={graphDescription} />
    </Helmet>
  );
};

export default HelmetComponent;
