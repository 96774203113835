import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { FlexContainer } from '@/components/containers/FlexContainer/FlexContainer';
import { TypeAnimation } from 'react-type-animation';
import { useSelector } from 'react-redux';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import './NewHomeSlider.scss';

const AnimationTitle = (props) => {
  const { sequence } = props;
  return (
    <TypeAnimation
      sequence={sequence}
      wrapper="h2"
      className={'text-22 m-text-20 sliderInfo-box-titles lineHeight-1-21 margb20'}
      speed={20}
      style={{ display: 'inline-block' }}
      repeat={Infinity}
    />
  );
};

const SliderBox = (props) => {
  const { sequence, personalizedPlaceholder, showNewForm, dynamickey } = props;
  return (
    <FlexContainer className={'sliderInfo-box flex-column align-items-center'}>
      <FlexContainer className={'flex-column text-center'}>
        <AnimationTitle sequence={sequence} key={dynamickey} />
      </FlexContainer>
      <FlexContainer className={'flex-column sliderInfo-box-input'}>
        <input
          type="text"
          className={
            'sliderFormInput grayShadow16 borderWhite border-rad24px padtb20 text-16 text-center m-hide t-hide'
          }
          placeholder={personalizedPlaceholder}
          onFocus={() => showNewForm()}
        />
        <input
          type="text"
          className={
            'sliderFormInput grayShadow16 borderWhite border-rad24px padtb20 text-16 text-center d-hide'
          }
          placeholder={personalizedPlaceholder}
          onFocus={() => showNewForm()}
        />
      </FlexContainer>
    </FlexContainer>
  );
};

const NewHomeSlider = (props) => {
  const {
    spacesData,
    personalizedContainerClass,
    sequence,
    personalizedPlaceholder,
    imagesArray,
    showNewForm,
  } = props;
  const languageIsFromButton = useSelector((state) => state.lenguage?.isButton);
  const languageData = useSelector((state) => state.lenguage?.lang);
  const [heroSwiper, setSwiperRef] = useState(null);
  const [dynamicKey, setDynamicKey] = useState(1);

  useEffect(() => {
    if (languageIsFromButton) {
      setDynamicKey(Math.random());
    }
  }, [languageIsFromButton, languageData]);

  const playHero = () => {
    heroSwiper.autoplay.start();
  };
  const pauseHero = () => {
    heroSwiper.autoplay.stop();
  };
  const containerClass = 'mySwiper items-align-center ' + personalizedContainerClass;
  return (
    <FlexContainer column className={containerClass}>
      <SliderBox
        dynamickey={dynamicKey}
        playHero={playHero}
        pauseHero={pauseHero}
        showNewForm={showNewForm}
        spacesData={spacesData}
        sequence={sequence}
        personalizedPlaceholder={personalizedPlaceholder}
      />
      <Swiper
        dynamickey={dynamicKey}
        onSwiper={setSwiperRef}
        navigation
        autoplay={{
          delay: 10500,
          disableOnInteraction: true,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination, Navigation]}
        spaceBetween={0}
        slidesPerView={1}
      >
        {imagesArray.map((image, index) => (
          <SwiperSlide key={index}>
            <img
              src={image}
              alt={`Slider ${index + 1}`}
              style={{ width: '100%' }}
              className="sliderImage"
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </FlexContainer>
  );
};

export default NewHomeSlider;
