import React, { useState, useEffect } from 'react';
import { FlexContainer } from '@/components/containers/FlexContainer/FlexContainer';
import { PageContainer } from '@/components/containers/PageContainer/PageContainer';
import ReservationForm from '@/components/general/Forms/ReservationForm/ReservationForm';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import { useNavigate } from 'react-router-dom';
import { CommentSection } from 'react-comments-section';
import { useSelector } from 'react-redux';
import IndividualCommentComponent from '@/components/pages/ProductPreList/components/IndividualCommentComponent';
import Rating from 'rating-react';
import Label from '@/components/form/Label/Label';
import ReactPixel from 'react-facebook-pixel';
import NewSpaceCardDetailVersion from '@/components/reusable/NewSpaceCardDetailVersion/NewSpaceCardDetailVersion';
import HelmetComponent from '@/components/general/Helmet/HelmetComponent';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import './ProductDetails.scss';
import 'react-comments-section/dist/index.css';

const IndividualAmenitie = (props) => {
  const { type } = props;
  return (
    <FlexContainer className={'border-rad10px borderBlack margtb5 padlr6 padtb6 fontGray300'}>
      {type === 'wifi' && (
        <FlexContainer className={'gap-4'}>
          <i className={'fas fa-wifi m-text-10 text-12'} />
          <Label className={'text-10'}>Wifi</Label>
        </FlexContainer>
      )}
      {type === 'coffee' && (
        <FlexContainer className={'gap-4'}>
          <i className={'fas fa-coffee m-text-10 text-12'} />
          <Label className={'text-10'}>Café Ilimitado</Label>
        </FlexContainer>
      )}
      {type === 'parking' && (
        <FlexContainer className={'gap-4'}>
          <i className={'fas fa-car-side m-text-10 text-12'} />
          <Label className={'text-10'}>Estacionamiento</Label>
        </FlexContainer>
      )}
      {type === 'staff' && (
        <FlexContainer className={'gap-4'}>
          <i className={'fas fa-users m-text-10 text-12'} />
          <Label className={'text-10'}>Staff</Label>
        </FlexContainer>
      )}
      {type === 'cleaning_service' && (
        <FlexContainer className={'gap-4'}>
          <i className={'fas fa-broom m-text-10 text-12'} />
          <Label className={'text-10'}>Cleaning Service</Label>
        </FlexContainer>
      )}
      {type === 'printing_service' && (
        <FlexContainer className={'gap-4'}>
          <i className={'fas fa-print m-text-10 text-12'} />
          <Label className={'text-10'}>Printing Service</Label>
        </FlexContainer>
      )}
      {type === 'common_areas' && (
        <FlexContainer className={'gap-4'}>
          <i className={'fas fa-building  m-text-10 text-12'} />
          <Label className={'text-10'}>Common Areas</Label>
        </FlexContainer>
      )}
      {type === 'convention_hall' && (
        <FlexContainer className={'gap-4'}>
          <i className={'fas fa-chalkboard  m-text-10 text-12'} />
          <Label className={'text-10'}>Conference Room</Label>
        </FlexContainer>
      )}
      {type === 'snacks' && (
        <FlexContainer className={'gap-4'}>
          <i className={'fas fa-candy-cane  m-text-10 text-12'} />
          <Label className={'text-10'}>Snacks</Label>
        </FlexContainer>
      )}
      {type === 'phone_service' && (
        <FlexContainer className={'gap-4'}>
          <i className={'fas fa-phone  m-text-10 text-12'} />
          <Label className={'text-10'}>Phone Booths</Label>
        </FlexContainer>
      )}
    </FlexContainer>
  );
};

const AmenitiesSection = (props) => {
  const {
    wifi,
    coffee,
    parking,
    staff,
    cleaning_service,
    printing_service,
    common_areas,
    convention_hall,
    snacks,
    phone_service,
  } = props;
  return (
    <FlexContainer className={'width100'}>
      <FlexContainer
        className={'items-align-start width100 margt20 align-items-center fontGray075 gap-10'}
        style={{
          flexWrap: 'wrap',
        }}
      >
        {wifi && <IndividualAmenitie type="wifi" />}
        {coffee && <IndividualAmenitie type="coffee" />}
        {parking && <IndividualAmenitie type="parking" />}
        {staff && <IndividualAmenitie type="staff" />}
        {cleaning_service && <IndividualAmenitie type="cleaning_service" />}
        {printing_service && <IndividualAmenitie type="printing_service" />}
        {common_areas && <IndividualAmenitie type="common_areas" />}
        {convention_hall && <IndividualAmenitie type="convention_hall" />}
        {snacks && <IndividualAmenitie type="snacks" />}
        {phone_service && <IndividualAmenitie type="phone_service" />}
      </FlexContainer>
    </FlexContainer>
  );
};

const SpaceDetailInfo = (props) => {
  console.log('Props recibidos en ProductDetails:', props);
  const { closeButton, spaceData, spaceComment } = props;

  const lenguage = useSelector((state) => state.lenguage?.lang);
  const navigate = useNavigate();
  const goToSpaceDetails = () => {
    ReactPixel.trackCustom('clickReservationButton', { clave: spaceData });
    navigate(lenguage === 'ES' ? '/confirmar-reservacion' : '/confirm-reservation', {
      state: { spaceData },
    });
  };
  const wifi = true;
  const coffee = spaceData?.coffe_water === '1' ? true : false;
  const parking = spaceData?.parking === '1' ? true : false;
  const staff = spaceData?.staff === '1' ? true : false;
  const cleaning_service = spaceData?.cleaning_service === '1' ? true : false;
  const printing_service = spaceData?.printing_service === '1' ? true : false;
  const common_areas = spaceData?.common_areas === '1' ? true : false;
  const convention_hall = spaceData?.convention_hall === '1' ? true : false;
  const snacks = spaceData?.snacks === '1' ? true : false;
  const phone_service = spaceData?.phone_service === '1' ? true : false;

  const data = [];

  const imagesArray = (() => {
    try {
      const parsed = JSON.parse(spaceData?.main_image);
      return Array.isArray(parsed) && parsed.length > 0 ? parsed : [spaceData?.main_image];
    } catch {
      return [spaceData?.main_image];
    }
  })();

  const center = {
    lat: parseFloat(spaceData?.space_latitude.trim()),
    lng: parseFloat(spaceData?.space_logitude.trim()),
    image: imagesArray[0],
    spaceData: spaceData,
  };

  const markers = [center];

  if (spaceData) {
    return (
      <FlexContainer className={'flex-column productPreList padt20 m-hide t-hide width100'}>
        <NewSpaceCardDetailVersion
          key={1}
          spaceInfo={spaceData}
          handleDetailButton={goToSpaceDetails}
        />
        <Label className={'text-22 Subtitle-3 width100 margt40 text-justify'}>
          {lenguage === 'EN' ? 'Amenities' : 'Amenidades'}
        </Label>
        <AmenitiesSection
          wifi={wifi}
          coffee={coffee}
          parking={parking}
          staff={staff}
          cleaning_service={cleaning_service}
          printing_service={printing_service}
          common_areas={common_areas}
          convention_hall={convention_hall}
          snacks={snacks}
          phone_service={phone_service}
        />
        <Label className={'text-22 Subtitle-3 width100 margt40 text-justify'}>
          {lenguage === 'EN' ? 'Maximum Capacity' : 'Capacidad Máxima'}
        </Label>
        {lenguage === 'EN' && (
          <Label type="text-16" className={'width100 margtb20 text-justify'}>
            Up to {spaceData?.host_capacity} people
          </Label>
        )}
        {lenguage === 'ES' && (
          <Label type="text-16" className={'width100 margtb20 text-justify'}>
            Hasta {spaceData?.host_capacity} personas
          </Label>
        )}
        <Label className={'text-22 Subtitle-3 width100 margt40 text-justify'}>
          {lenguage === 'EN' ? 'Operating Hours' : 'Horario de operación'}
        </Label>
        <Label type="text-16" className={'width100 margtb20 text-justify'}>
          {lenguage === 'EN' ? '9 a.m. to 8 p.m' : '09 a 20 horas'}
        </Label>
        <Label className={'text-22 Subtitle-3 width100 margt40 text-justify'}>
          {lenguage === 'EN' ? 'Location Features' : 'Características de la ubicación'}
        </Label>
        <Label type="text-16" className={'width100 margtb20 text-justify'}>
          <div
            dangerouslySetInnerHTML={{
              __html: lenguage === 'EN' ? spaceData?.space_details_en : spaceData?.space_details,
            }}
          />
          {/*<div
            dangerouslySetInnerHTML={{
              __html:
                lenguage === 'EN'
                  ? spaceData?.space_details_general_en
                  : spaceData?.space_details_general,
            }}
          />*/}
        </Label>
        <Label className={'text-22 Subtitle-3 width100 margt40 text-justify'}>
          {lenguage === 'EN' ? 'Space location' : 'Ubicación del espacio'}
        </Label>
        <Label type="text-16" className={'width100 margtb20 text-justify'}>
          <div
            dangerouslySetInnerHTML={{
              __html: spaceData?.space_address?.replace(/\d+/g, '****'),
            }}
          />
        </Label>
        <Label className={'text-22 Subtitle-3 width100 margt40 text-justify'}>
          {' '}
          {lenguage === 'EN' ? 'Reviews' : 'Opiniones'}
        </Label>
        <Label type="text-16" className={'width100 margtb20 text-justify'}>
          {lenguage === 'EN' ? 'Rating: 5 stars' : 'Clasificación: 5 estrellas'}
        </Label>
        {/*
        <CommentSection
          currentUser={{
            currentUserId: '01a',
            currentUserImg: 'https://ui-avatars.com/api/name=Riya&background=random',
            currentUserProfile: 'https://www.linkedin.com/in/riya-negi-8879631a9/',
            currentUserFullName: 'Riya Negi',
          }}
          commentData={data}
          logIn={{
            onLogin: () => alert('Call login function '),
            signUpLink: 'http://localhost:3001/',
          }}
          placeHolder="Escribe tu comentario..."
          onSubmitAction={({}) => console.log('check submit, ', data)}
          currentData={(data) => {
            console.log('current data', data);
          }}
        />
        */}
        <FlexContainer className={'width100 margt10 d-hide'}>
          <Swiper
            navigation
            autoplay={{
              delay: 10500,
              disableOnInteraction: true,
            }}
            modules={[Autoplay, Navigation]}
            spaceBetween={30}
            slidesPerView={1}
          >
            {spaceComment?.length > 0 ? (
              spaceComment.map((comment, index) => (
                <SwiperSlide key={index}>
                  <IndividualCommentComponent
                    name={comment.user_name}
                    stars={comment.comment_stars}
                  />
                </SwiperSlide>
              ))
            ) : (
              <SwiperSlide>
                <Label className="text-16 width100 margtb20 text-justify">
                  {lenguage === 'EN' ? 'No comments yet' : 'Aún no hay comentarios'}
                </Label>
              </SwiperSlide>
            )}
          </Swiper>
        </FlexContainer>
        <FlexContainer className={'flex-column width100 margt10 m-hide gap-20'}>
          {spaceComment?.length > 0 ? (
            spaceComment.map((comment, index) => (
              <IndividualCommentComponent
                key={index}
                name={comment.user_name}
                stars={comment.comment_stars}
                detail={comment.comment_detail}
              />
            ))
          ) : (
            <Label className="text-16 width100 margtb20 text-justify">
              {lenguage === 'EN' ? 'No comments yet' : 'Aún no hay comentarios'}
            </Label>
          )}
        </FlexContainer>

        <Label className={'text-22 Subtitle-3 width100 margt40 text-justify'}>
          {lenguage === 'EN' ? 'Cancellation policies' : 'Políticas de cancelación'}
        </Label>
        <Label type="text-16" className={'width100 margtb20 text-justify'}>
          {lenguage === 'EN'
            ? 'Read our cancellation policies ->'
            : 'Lee nuestras políticas de cancelación ->'}
        </Label>
        <Label className={'text-22 Subtitle-3 width100 margt40 text-justify d-hide'}>
          {lenguage === 'EN' ? 'Other sites similar to this one' : 'Otros sitios similares a este'}
        </Label>
        <Label type="text-16" className={'width100 margtb20 text-justify d-hide'}>
          {lenguage === 'EN'
            ? 'We show you sites whose rates are calculated with these values:'
            : 'Te mostramos sitios cuya tarifa está calculada con estos valores:'}
        </Label>
      </FlexContainer>
    );
  } else {
    return <></>;
  }
};

const ProductDetailReservationForm = (props) => {
  const { spaceDetail, lenguage } = props;
  const navigate = useNavigate();
  const goToCheckout = (values) => {
    ReactPixel.trackCustom('goToCheckout', {
      reservationData: {
        reservationDetails: values,
        spaceDetails: spaceDetail,
      },
    });

    navigate(lenguage === 'ES' ? '/pago' : '/pay', {
      state: { spaceDetail, values },
    });
  };
  return (
    <FlexContainer className={'flex-column width100 margtb20'}>
      <ReservationForm
        className={'bgWhite border-rad8px padlr20 m-padlr10'}
        spaceDetail={spaceDetail}
        onSubmitFunction={(values) => goToCheckout(values)}
      />
    </FlexContainer>
  );
};

const ProductDetails = (props) => {
  /*  console.log('Props recibidos en ProductDetails:', props); */
  const { spaceDetail, spacesData } = props;
  const lenguage = useSelector((state) => state.lenguage?.lang);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <PageContainer className={'bgWhite'}>
      <FlexContainer className={'m-flex-column margt72 gap-20 bgWhite'}>
        <SpaceDetailInfo
          spaceData={spaceDetail}
          spaceComment={spacesData}
          lenguage={lenguage}
          closeButton={''}
        />
        <ProductDetailReservationForm spaceDetail={spaceDetail} lenguage={lenguage} />
      </FlexContainer>
    </PageContainer>
  );
};

export default ProductDetails;
