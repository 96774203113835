import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import dayjs from 'dayjs';
import { FlexContainer } from '@/components/containers/FlexContainer/FlexContainer';
import { PaymentElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import ReactPixel from 'react-facebook-pixel';
import Label from '@/components/form/Label/Label';

const apiUrl = process.env.REACT_APP_API_URL;

const calculateHoursDifference = (startTime, endTime) => {
  if (!startTime || !endTime) return 0;
  const start = dayjs(`2025-02-01 ${startTime}`, 'YYYY-MM-DD HH:mm');
  const end = dayjs(`2025-02-01 ${endTime}`, 'YYYY-MM-DD HH:mm');
  return end.diff(start, 'hour', true);
};

const CheckoutForm = (props) => {
  const { spaceDetail, formValues } = props;
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoadingCheckout, setIsLoadingCheckout] = useState(false);
  const searchFormData = useSelector((state) => state.searchFormData);

  const handleSubmit = async (event) => {
    setIsLoadingCheckout(true);
    event.preventDefault();
    if (elements == null) {
      return;
    }

    const formattedStartDate = format(new Date(searchFormData?.startDate), 'yyyy-MM-dd');

    const { error: submitError } = await elements.submit();
    if (submitError) {
      setErrorMessage(submitError.message);
      return;
    }

    const spaceTypeFormData = searchFormData?.spaceType;

    const pricePerTime =
      spaceTypeFormData === 'coworking' ? spaceDetail?.rate_24_hours : spaceDetail?.hourly_rate;
    const hours = calculateHoursDifference(formValues?.startTime, formValues?.endTime);

    const totalPrice = () => {
      if (spaceTypeFormData === 'coworking') {
        return (pricePerTime * (formValues?.guestQuantyty || 1)).toFixed(2);
      } else {
        return (pricePerTime * (hours > 0 ? hours : 0)).toFixed(2);
      }
    };

    const amount = Math.round(parseFloat(totalPrice()) * 100);
    //const currency = 'usd';
    const currency = 'mxn';

    const reservation = {
      host_id: 1,
      guest_id: 2,
      space_subtype_relationship: spaceDetail?.id_relationship,
      start_date: formattedStartDate,
      finish_date: formattedStartDate,
      initial_price: parseFloat(totalPrice()),
      final_price: parseFloat(totalPrice()),
      id_state: 1,
      payment_method: 1,
      method_status: 'p',
      method_details: 'Credit Card',
    };

    const res = await fetch(`${apiUrl}/POST/checkout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        amount,
        currency,
        reservation: reservation,
      }),
    });

    const { client_secret, createdReservation } = await res.json();
    console.log('ver secret', client_secret);
    console.log('ver reservacion', createdReservation);

    ReactPixel.trackCustom('StripePaymentButton', {
      clave: true,
    });

    const { error } = await stripe.confirmPayment({
      elements,
      clientSecret: client_secret,
      confirmParams: {
        /*
        return_url:
          'http://localhost:3000/reserva-confirmada?transaction_id=' +
          createdReservation?.transaction_id,
          */
        //return_url: 'https://popnest.org/reserva-confirmada',
        return_url:
          'https://popnest.org/reserva-confirmada?transaction_id=' +
          createdReservation?.transaction_id,
      },
    });

    if (error) {
      ReactPixel.trackCustom('StripePaymentError', {
        errorMessage: error.message,
      });
      setErrorMessage(error.message);
      setIsLoadingCheckout(false);
    } else {
      console.log('Pago exitoso');
      setIsLoadingCheckout(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      {!isLoadingCheckout && (
        <button
          type="submit"
          className={
            'bgBlue400 width100 border-rad24px borderGray050 fontWhite grayShadow16 margtb30 padlr40 padtb10 text-18'
          }
          disabled={!stripe || !elements}
        >
          <strong>Pagar</strong>
        </button>
      )}
      {isLoadingCheckout && <Label className={'margtb10'}>Cargando...</Label>}
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
};

const stripePromise = loadStripe(
  'pk_live_51MuKSIA0gqRxLoT3YHraiqMUanidK11hZZB8vptnBPNDPQUwEWNfKx8yMDbONCyPA5BHHjGryNJYEp2BZzX1m67k00ZbJdQDlT',
);

//const stripePromise = loadStripe('pk_test_6pRNASCoBOKtIshFeQd4XMUh');

const PaymentForm = (props) => {
  const { spaceDetail, formValues } = props;
  const searchFormData = useSelector((state) => state.searchFormData);
  const spaceTypeFormData = searchFormData?.spaceType;
  const pricePerTime =
    spaceTypeFormData === 'coworking' ? spaceDetail?.rate_24_hours : spaceDetail?.hourly_rate;
  const hours = calculateHoursDifference(formValues?.startTime, formValues?.endTime);

  const totalPrice = () => {
    if (spaceTypeFormData === 'coworking') {
      return pricePerTime * (formValues?.guestQuantyty || 1);
    } else {
      return pricePerTime * (hours > 0 ? hours : 0);
    }
  };

  const amount = parseFloat(totalPrice().toFixed(2));

  console.log('esto se registrará', JSON.stringify(searchFormData));
  console.log('esto se registrará k', JSON.stringify(spaceDetail));

  const options = {
    mode: 'payment',
    amount: Math.round(amount * 100),
    //currency: 'usd',
    currency: 'mxn',
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  };
  return (
    <Elements stripe={stripePromise} options={options}>
      <FlexContainer className="padtb10 bgGray025 margt6" />
      <Label className={'margt10 text-16 m-text-12'}>
        Precio total a pagar: <span className={'bold'}>${totalPrice().toFixed(2)} MX</span>
      </Label>
      <CheckoutForm spaceDetail={spaceDetail} formValues={formValues} />
    </Elements>
  );
};

export default PaymentForm;
