export const setLenguageData = (lenguageData) => {
  console.log('SET_LENGUAGE_DATA: ', lenguageData);
  return {
    type: 'SET_LENGUAGE_DATA',
    payload: lenguageData,
  };
};

export const clearLenguageData = () => ({
  type: 'CLEAR_LENGUAGE_DATA',
});
