import React from 'react';
import { FlexContainer } from '@/components/containers/FlexContainer/FlexContainer';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { useSelector } from 'react-redux';
import Label from '@/components/form/Label/Label';
import classNames from 'classnames';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import './NewSpaceCard.scss';

const truncateDescription = (description, limit) => {
  if (description.length > limit) {
    return description.substring(0, limit) + '...';
  }
  return description;
};

const IndividualAmenitie = (props) => {
  const { type } = props;
  const lenguage = useSelector((state) => state.lenguage?.lang);
  return (
    <FlexContainer className={'border-rad10px borderBlack margtb5 padlr6 padtb6 fontGray300'}>
      {type === 'wifi' && (
        <FlexContainer className={'gap-4'}>
          <i className={'fas fa-wifi text-10'} />
          <Label className={'text-10'}>{lenguage === 'ES' ? 'Wifi' : 'Wifi'}</Label>
        </FlexContainer>
      )}
      {type === 'coffee' && (
        <FlexContainer className={'gap-4'}>
          <i className={'fas fa-coffee text-10'} />
          <Label className={'text-10'}>
            {lenguage === 'ES' ? 'Café Ilimitado' : 'Unlimited Coffee'}
          </Label>
        </FlexContainer>
      )}
      {type === 'parking' && (
        <FlexContainer className={'gap-4'}>
          <i className={'fas fa-car-side text-10'} />
          <Label className={'text-10'}>{lenguage === 'ES' ? 'Estacionamiento' : 'Parking'}</Label>
        </FlexContainer>
      )}
      {type === 'staff' && (
        <FlexContainer className={'gap-4'}>
          <i className={'fas fa-users text-10'} />
          <Label className={'text-10'}>{lenguage === 'ES' ? 'Personal' : 'Staff'}</Label>
        </FlexContainer>
      )}
      {type === 'cleaning_service' && (
        <FlexContainer className={'gap-4'}>
          <i className={'fas fa-broom text-10'} />
          <Label className={'text-10'}>
            {lenguage === 'ES' ? 'Servicio de Limpieza' : 'Cleaning Service'}
          </Label>
        </FlexContainer>
      )}
      {type === 'printing_service' && (
        <FlexContainer className={'gap-4'}>
          <i className={'fas fa-print text-10'} />
          <Label className={'text-10'}>
            {lenguage === 'ES' ? 'Servicio de Impresión' : 'Printing Service'}
          </Label>
        </FlexContainer>
      )}
      {type === 'common_areas' && (
        <FlexContainer className={'gap-4'}>
          <i className={'fas fa-building text-10'} />
          <Label className={'text-10'}>
            {lenguage === 'ES' ? 'Áreas Comunes' : 'Common Areas'}
          </Label>
        </FlexContainer>
      )}
      {type === 'convention_hall' && (
        <FlexContainer className={'gap-4'}>
          <i className={'fas fa-chalkboard text-10'} />
          <Label className={'text-10'}>
            {lenguage === 'ES' ? 'Salón de Convenciones' : 'Conference Room'}
          </Label>
        </FlexContainer>
      )}
      {type === 'snacks' && (
        <FlexContainer className={'gap-4'}>
          <i className={'fas fa-candy-cane text-10'} />
          <Label className={'text-10'}>{lenguage === 'ES' ? 'Snacks' : 'Snacks'}</Label>
        </FlexContainer>
      )}
      {type === 'phone_service' && (
        <FlexContainer className={'gap-4'}>
          <i className={'fas fa-phone text-10'} />
          <Label className={'text-10'}>
            {lenguage === 'ES' ? 'Servicio Telefónico' : 'Phone Booths'}
          </Label>
        </FlexContainer>
      )}
    </FlexContainer>
  );
};

const MobileAmenitiesSection = (props) => {
  const {
    wifi,
    coffee,
    parking,
    staff,
    cleaning_service,
    printing_service,
    common_areas,
    convention_hall,
    snacks,
    phone_service,
  } = props;
  return (
    <FlexContainer className={'width100 d-hide'}>
      <FlexContainer
        className={'items-align-start width100 align-items-center fontGray075 gap-10'}
        style={{
          flexWrap: 'wrap',
        }}
      >
        {wifi && <IndividualAmenitie type="wifi" />}
        {coffee && <IndividualAmenitie type="coffee" />}
        {parking && <IndividualAmenitie type="parking" />}
        {staff && <IndividualAmenitie type="staff" />}
        {cleaning_service && <IndividualAmenitie type="cleaning_service" />}
        {printing_service && <IndividualAmenitie type="printing_service" />}
        {common_areas && <IndividualAmenitie type="common_areas" />}
        {convention_hall && <IndividualAmenitie type="convention_hall" />}
        {snacks && <IndividualAmenitie type="snacks" />}
        {phone_service && <IndividualAmenitie type="phone_service" />}
      </FlexContainer>
    </FlexContainer>
  );
};

const HeadCard = (props) => {
  const { spaceInfo, extraClass, lenguage } = props;
  return (
    <FlexContainer className={extraClass}>
      <Label className={'fontGray250 subTitle2'}>
        <div
          style={{ fontWeight: 500 }}
          dangerouslySetInnerHTML={{
            __html: truncateDescription(
              lenguage === 'ES' ? spaceInfo?.space_title : spaceInfo?.space_title_en,
              40,
            ).toUpperCase(),
          }}
        />
      </Label>
    </FlexContainer>
  );
};

const TypeSpaceCardPill = (props) => {
  const { spaceInfo, lenguage } = props;
  const translations = {
    'Sala de Juntas': 'Meeting Room',
    Terrazas: 'Terrace',
    Coworking: 'Coworking',
  };
  const translatedTitle = translations[spaceInfo?.subtype_title] || spaceInfo?.subtype_title;
  return (
    <span
      style={{ zIndex: 2 }}
      className={
        'bgWhite border-rad4px text-12 m-text-14 padt12 padb12 padlr10 pos-abs margt10 m-margt90 marglr10'
      }
    >
      {lenguage === 'EN' ? translatedTitle : spaceInfo?.subtype_title}
    </span>
  );
};

const NewSpaceCard = (props) => {
  const { spaceInfo, wifi, handleDetailButton, isWhiteBg, priceAndCapacityDetails } = props;
  const lenguage = useSelector((state) => state.lenguage?.lang);
  const containerClass = classNames(
    'NewSpaceCard borderGray050 border-rad8px m-gap-20 flex-column format100',
    {
      bgWhite: isWhiteBg,
    },
  );

  const textContainerClass = classNames('flex-column padlr20 margt10 m-margt0');

  const coffee = spaceInfo?.coffe_water === '1' ? true : false;
  const parking = spaceInfo?.parking === '1' ? true : false;
  const staff = spaceInfo?.staff === '1' ? true : false;
  const cleaning_service = spaceInfo?.cleaning_service === '1' ? true : false;
  const printing_service = spaceInfo?.printing_service === '1' ? true : false;
  const common_areas = spaceInfo?.common_areas === '1' ? true : false;
  const convention_hall = spaceInfo?.convention_hall === '1' ? true : false;
  const snacks = spaceInfo?.snacks === '1' ? true : false;
  const phone_service = spaceInfo?.phone_service === '1' ? true : false;

  const imagesArray = (() => {
    try {
      const parsed = JSON.parse(spaceInfo?.main_image);
      return Array.isArray(parsed) && parsed.length > 0
        ? parsed
        : [spaceInfo?.main_image].filter(Boolean);
    } catch {
      return [spaceInfo?.main_image].filter(Boolean);
    }
  })();

  return (
    <FlexContainer className={containerClass} onClick={handleDetailButton}>
      <HeadCard
        spaceInfo={spaceInfo}
        lenguage={lenguage}
        extraClass={'align-self-center d-hide text-16 margt34'}
      />
      {spaceInfo?.subtype_title && <TypeSpaceCardPill spaceInfo={spaceInfo} lenguage={lenguage} />}
      <FlexContainer className={'width100 d-hide'}>
        <Swiper
          navigation
          autoplay={{
            delay: 10500,
            disableOnInteraction: true,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay, Pagination, Navigation]}
          spaceBetween={30}
          slidesPerView={1}
        >
          {imagesArray.map((image, index) => (
            <SwiperSlide key={index}>
              <img src={image} alt={spaceInfo?.space_title} className={'width100'} />
            </SwiperSlide>
          ))}
        </Swiper>
      </FlexContainer>
      <FlexContainer className={'width100 m-hide'}>
        <img src={imagesArray[0]} alt={spaceInfo?.space_title} className={'width100 spaceImage'} />
      </FlexContainer>
      <FlexContainer className={textContainerClass}>
        <HeadCard
          spaceInfo={spaceInfo}
          lenguage={lenguage}
          extraClass={'m-hide text-12 margtb20'}
        />
        <Label type="text-14">
          <strong>{truncateDescription(spaceInfo?.space_address, 30)}</strong>
        </Label>
        <Label type="text-14 m-hide">
          <div
            dangerouslySetInnerHTML={{
              __html: truncateDescription(
                lenguage === 'ES' ? spaceInfo?.space_details : spaceInfo?.space_details_en,
                50,
              ),
            }}
          />
        </Label>
        <Label type="m-text-16 d-hide">
          <div
            dangerouslySetInnerHTML={{
              __html: truncateDescription(
                lenguage === 'ES' ? spaceInfo?.space_details : spaceInfo?.space_details_en,
                120,
              ),
            }}
          />
        </Label>
        <MobileAmenitiesSection
          wifi={wifi}
          coffee={coffee}
          parking={parking}
          staff={staff}
          cleaning_service={cleaning_service}
          printing_service={printing_service}
          common_areas={common_areas}
          convention_hall={convention_hall}
          snacks={snacks}
          phone_service={phone_service}
        />
        {priceAndCapacityDetails && (
          <FlexContainer className={'borderTp m-margt20 margb10 padt20 width100'}>
            <FlexContainer className={'width100 flex-column gap-10'}>
              <FlexContainer className={'width100'}>
                <Label className={'fontGray6'} type="text-12 m-text-14">
                  {lenguage === 'ES' ? 'Capacidad:' : 'Capacity:'}
                </Label>
              </FlexContainer>
              <FlexContainer className={'width100'}>
                <Label className={'fontGray6'} type={'text-12 m-text-14'}>
                  {spaceInfo?.host_capacity} {lenguage === 'ES' ? ' Personas' : ' People'}
                </Label>
              </FlexContainer>
            </FlexContainer>
            <FlexContainer className={'width100 flex-column gap-10'}>
              <FlexContainer className={'width100'}>
                <Label className={'fontGray6'} type="text-12 m-text-14">
                  {lenguage === 'ES' ? 'Costo por' : 'Cost per'}
                </Label>
              </FlexContainer>
              <FlexContainer className={'width100'}>
                {lenguage === 'ES' && (
                  <Label className={'fontGray6'} type={'text-12 m-text-14'}>
                    {spaceInfo?.subtype_title === 'Coworking' ? 'Un día' : '1 hora.'}
                  </Label>
                )}
                {lenguage === 'EN' && (
                  <Label className={'fontGray6'} type={'text-12 m-text-14'}>
                    {spaceInfo?.subtype_title === 'Coworking' ? 'One day' : '1 hour.'}
                  </Label>
                )}
              </FlexContainer>
            </FlexContainer>
            <FlexContainer className={'width100  flex-column gap-10'}>
              <FlexContainer className={'width100'}>
                <Label className={'fontGray400'} type={'text-16 m-text-20'}>
                  <strong>
                    ${' '}
                    {parseFloat(
                      spaceInfo?.subtype_title === 'Coworking'
                        ? spaceInfo?.rate_24_hours
                        : spaceInfo?.hourly_rate,
                    )
                      .toFixed(2)
                      .replace(/\.00$/, '')}{' '}
                    MXN
                  </strong>
                </Label>
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
        )}
      </FlexContainer>
    </FlexContainer>
  );
};

export default NewSpaceCard;
