import React, { useState, useEffect } from 'react';
import { FlexContainer } from '@/components/containers/FlexContainer/FlexContainer';
import Label from '@/components/form/Label/Label';
import { useSelector } from 'react-redux';
import HelmetComponent from '@/components/general/Helmet/HelmetComponent';
import './BlogView.scss';

const YouTubeEmbed = () => {
  return (
    <>
      <FlexContainer className={'width100 m-hide'}>
        <iframe
          width="60%"
          height="315"
          src="https://www.youtube.com/embed/x-3jXqI1jS4"
          title="YouTube Video"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </FlexContainer>
      <FlexContainer className={'width100 d-hide'}>
        <iframe
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/x-3jXqI1jS4"
          title="YouTube Video"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </FlexContainer>
    </>
  );
};

const BlogEntry = props => {
  const { entryData, setDetailEntryData } = props;
  return (
    <FlexContainer
      className={'flex-column width100'}
      onClick={() => setDetailEntryData(entryData)}
    >
      <FlexContainer className={'width100 bgBlue400 height-220px'}>
        <img
          src={entryData?.entryImage}
          alt="Foto 1"
          style={{ width: '100%', height: '220px' }}
          className={''}
        />
      </FlexContainer>
      <FlexContainer className={'flex-column width100 height-220px'}>
        <Label className="text-12 margtb14">{entryData?.entryRegDate}</Label>
        <Label className="text-18 margb40 margt10 bold">
          <strong>{entryData?.entryTitle}</strong>
        </Label>
        <Label className="text-16 margb20">{entryData?.entryDescription}</Label>
      </FlexContainer>
    </FlexContainer>
  );
};

const EntriesRow = props => {
  const { category, entriesData, setDetailEntryData } = props;
  return (
    <FlexContainer className={'width100 flex-column margtb30 m-margtb20'}>
      <Label className="text-32 m-text-24 marglr20 m-marglr0 margt20 m-margt10 margb60 m-margb40 bold">
        <strong>Entradas populares en la categoría {category}</strong>
      </Label>
      <FlexContainer
        className={'m-flex-column padlr20 m-padlr0 width100 gap-10'}
      >
        {entriesData.map((entry, index) => (
          <BlogEntry
            key={index}
            entryData={entry}
            setDetailEntryData={setDetailEntryData}
          />
        ))}
      </FlexContainer>
    </FlexContainer>
  );
};

const Blog = props => {
  const { spacesDataFromBackend } = props;
  const [isShowDetail, setShowDetail] = useState(false);
  const [detailData, setDetailData] = useState({});
  const lenguageData = useSelector(state => state.lenguage.lang);
  const esDescription =
    'Explora tendencias de coworking, productividad y trabajo remoto con Popnest. Consejos, inspiración y espacios flexibles para profesionales modernos.';
  const enDescription =
    'Explore coworking trends, productivity tips, and remote work insights with Popnest. Inspiration and flexible spaces for modern professionals.';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const setDetailEntryData = entryData => {
    window.scrollTo(0, 0);
    setShowDetail(true);
    setDetailData(entryData);
    const currentPath = window.location.pathname;
    const newPath = `${currentPath}-detail`;

    window.history.pushState({}, '', newPath);
  };

  const spacesCatEntriesData = [
    {
      entryTitle: 'Beneficios de trabajar en espacios de coworking',
      entryDescription:
        'Descubre cómo los espacios de coworking fomentan la creatividad, la colaboración y aumentan la productividad en entornos flexibles.',
      entryImage: 'spaces/slider1.jpg',
      entryRegDate: '15.06.24',
      longDescription: `
        <p>Los espacios de coworking han revolucionado la manera en la que profesionales independientes, startups y empresas consolidadas trabajan día a día. A través de un entorno compartido, se promueve la <strong>colaboración</strong> y se eliminan las distracciones propias del trabajo en casa.</p>
        <p>Entre los principales beneficios de trabajar en un coworking se encuentran:</p>
        <ul>
          <li><strong>Networking:</strong> Al interactuar con otros profesionales, se generan conexiones valiosas para futuros proyectos y oportunidades laborales.</li>
          <li><strong>Flexibilidad:</strong> Los coworkings ofrecen horarios adaptados a las necesidades del trabajador moderno, permitiendo mayor libertad.</li>
          <li><strong>Entorno Inspirador:</strong> Estas instalaciones están diseñadas para estimular la creatividad y productividad.</li>
          <li><strong>Costos Accesibles:</strong> A comparación de rentar oficinas privadas, un espacio compartido es más económico.</li>
        </ul>
        <p>Estudios recientes también muestran cómo este modelo impacta positivamente en la salud mental, reduciendo el aislamiento y generando un sentimiento de comunidad.</p>
        <h4>Referencias</h4>
        <ul>
          <li><a href="https://www.wework.com">WeWork: El futuro del trabajo</a></li>
          <li><a href="https://www.coworkingresources.org">Coworking Resources: Beneficios de coworking</a></li>
          <li><a href="https://www.forbes.com">Forbes: Impacto del coworking en empresas modernas</a></li>
        </ul>
      `,
    },
    {
      entryTitle: 'Top 5 espacios de coworking en tu ciudad',
      entryDescription:
        'Conoce los mejores espacios de coworking con instalaciones modernas, buena ubicación y ambiente profesional.',
      entryImage: 'spaces/slider2.jpg',
      entryRegDate: '20.06.24',
      longDescription: `
        <p>Explorar los mejores espacios de coworking te permite encontrar opciones que se ajusten a tu estilo de trabajo y necesidades.</p>
        <p>Aquí te presentamos los <strong>Top 5 espacios de coworking</strong> en tu ciudad, seleccionados por sus instalaciones modernas, servicios y ubicación estratégica:</p>
        <ol>
          <li><strong>HubWork Central:</strong> Ofrece salas de reuniones, internet de alta velocidad y áreas de descanso.</li>
          <li><strong>SpaceFlex:</strong> Ideal para freelancers, con planes económicos y opciones 24/7.</li>
          <li><strong>WorkHive:</strong> Cuenta con servicios premium como cabinas privadas y gimnasio incluido.</li>
          <li><strong>CollaborateHub:</strong> Espacio moderno que incentiva la creatividad con diseños innovadores.</li>
          <li><strong>OpenDesk Loft:</strong> Perfecto para equipos pequeños y startups en crecimiento.</li>
        </ol>
        <p>Al elegir un coworking, evalúa aspectos como la accesibilidad, el ambiente profesional y los servicios que ofrecen.</p>
        <h4>Referencias</h4>
        <ul>
          <li><a href="https://www.coworking.com">Coworking.com: Directorio de espacios</a></li>
          <li><a href="https://www.thehive.com">The Hive: Espacios innovadores</a></li>
          <li><a href="https://www.remote.co">Remote.co: Mejores coworkings del mundo</a></li>
        </ul>
      `,
    },
    {
      entryTitle: 'Coworking vs Trabajo Remoto: ¿Cuál es la mejor opción?',
      entryDescription:
        'Analizamos las diferencias entre trabajar desde casa y hacerlo en un espacio de coworking para optimizar tu rendimiento laboral.',
      entryImage: 'spaces/slider3.jpg',
      entryRegDate: '25.06.24',
      longDescription: `
        <p>En el debate entre <strong>trabajo remoto</strong> y <strong>espacios de coworking</strong>, cada opción tiene ventajas y desventajas.</p>
        <h5>Trabajo Remoto</h5>
        <p>Trabajar desde casa es cómodo y económico, pero puede generar <em>aislamiento</em> y menor productividad debido a las distracciones del hogar.</p>
        <h5>Espacios de Coworking</h5>
        <p>Los coworkings ofrecen:</p>
        <ul>
          <li>Un <strong>ambiente profesional</strong> que mejora el enfoque.</li>
          <li>Posibilidades de <strong>networking</strong> con otros profesionales.</li>
          <li>Instalaciones como internet de alta velocidad y salas de reuniones.</li>
        </ul>
        <p>La mejor opción dependerá de tus necesidades y estilo de trabajo. Si buscas productividad y colaboración, un coworking es ideal.</p>
        <h4>Referencias</h4>
        <ul>
          <li><a href="https://www.cnbc.com">CNBC: Coworking frente al teletrabajo</a></li>
          <li><a href="https://www.bbc.com">BBC: ¿Espacios de coworking o casa?</a></li>
          <li><a href="https://www.fastcompany.com">FastCompany: Eficiencia en coworking</a></li>
        </ul>
      `,
    },
    {
      entryTitle: 'Cómo elegir el mejor espacio de coworking para tu equipo',
      entryDescription:
        'Guía práctica para seleccionar un espacio de coworking que se ajuste a las necesidades de tu equipo y tu presupuesto.',
      entryImage: 'spaces/spaceslider.png',
      entryRegDate: '28.06.24',
      longDescription: `
        <p>Seleccionar el mejor <strong>espacio de coworking</strong> para tu equipo puede ser crucial para el éxito y bienestar laboral.</p>
        <h5>Factores a considerar:</h5>
        <ul>
          <li><strong>Ubicación:</strong> Debe ser accesible para todos los miembros del equipo.</li>
          <li><strong>Servicios:</strong> Evalúa las instalaciones como salas de reuniones, cafeterías y conexión a internet.</li>
          <li><strong>Flexibilidad:</strong> Planes adaptados a tus necesidades, ya sea por horas, días o tiempo completo.</li>
          <li><strong>Ambiente:</strong> Un entorno inspirador que motive al equipo.</li>
        </ul>
        <p>Visita los espacios antes de tomar una decisión final y asegúrate de que cumplan con tus expectativas y presupuesto.</p>
        <h4>Referencias</h4>
        <ul>
          <li><a href="https://www.forbes.com">Forbes: Cómo elegir un coworking</a></li>
          <li><a href="https://www.entrepreneur.com">Entrepreneur: Consejos para startups</a></li>
          <li><a href="https://www.flexjobs.com">FlexJobs: Espacios recomendados</a></li>
        </ul>
      `,
    },
  ];

  const hostCatEntriesData = [
    {
      entryTitle: 'Cómo ser un anfitrión exitoso en espacios de trabajo',
      entryDescription:
        'Aprende las mejores prácticas para gestionar un espacio de coworking y ofrecer una experiencia única a tus invitados.',
      entryImage: 'spaces/slider1.jpg',
      entryRegDate: '10.06.24',
      longDescription: `
        <p>Convertirte en un <strong>anfitrión exitoso</strong> en espacios de coworking requiere atención al detalle, hospitalidad y habilidades de gestión. No se trata solo de alquilar un espacio, sino de crear una experiencia inolvidable para tus invitados.</p>
        <h5>Prácticas Clave:</h5>
        <ul>
          <li><strong>Comunicación Clara:</strong> Asegúrate de que los invitados conozcan las normas y servicios disponibles en el espacio.</li>
          <li><strong>Flexibilidad:</strong> Ofrece opciones de horarios y paquetes que se ajusten a las necesidades de freelancers y equipos.</li>
          <li><strong>Ambiente Inspirador:</strong> Decora el espacio con elementos que fomenten la creatividad y el confort.</li>
          <li><strong>Atención Personalizada:</strong> Escucha el feedback de tus usuarios y mejora continuamente.</li>
        </ul>
        <p>Ser anfitrión no solo implica administrar un lugar, sino ser un facilitador para que los demás trabajen mejor y se sientan como en casa.</p>
        <h4>Referencias</h4>
        <ul>
          <li><a href="https://www.hostspaces.com">HostSpaces: Cómo ser un buen anfitrión</a></li>
          <li><a href="https://www.coworkingresources.org">Coworking Resources</a></li>
          <li><a href="https://www.forbes.com">Forbes: Guía para anfitriones</a></li>
        </ul>
      `,
    },
    {
      entryTitle: 'Claves para atraer más profesionales a tu espacio',
      entryDescription:
        'Estrategias efectivas para promocionar tu espacio de trabajo y captar la atención de freelancers y empresas.',
      entryImage: 'spaces/slider2.jpg',
      entryRegDate: '14.06.24',
      longDescription: `
        <p>Para atraer más <strong>profesionales</strong> a tu espacio de coworking, necesitas implementar estrategias de marketing enfocadas en tus usuarios ideales.</p>
        <h5>Estrategias Efectivas:</h5>
        <ul>
          <li><strong>Marketing Digital:</strong> Utiliza redes sociales, Google Ads y SEO para promocionar tu espacio.</li>
          <li><strong>Eventos y Workshops:</strong> Organiza eventos gratuitos que atraigan a la comunidad local.</li>
          <li><strong>Paquetes Atractivos:</strong> Ofrece planes de membresía flexibles y descuentos por referencias.</li>
          <li><strong>Testimonios y Reseñas:</strong> Comparte historias de éxito y opiniones de tus usuarios.</li>
        </ul>
        <p>Los profesionales buscan más que solo un lugar para trabajar; buscan comunidad, comodidad y herramientas que faciliten su día a día.</p>
        <h4>Referencias</h4>
        <ul>
          <li><a href="https://www.coworking.com">Coworking.com</a></li>
          <li><a href="https://www.spacesworks.com">Spaces Works: Estrategias de promoción</a></li>
          <li><a href="https://www.entrepreneur.com">Entrepreneur: Marketing para espacios</a></li>
        </ul>
      `,
    },
    {
      entryTitle: 'Historias de éxito: anfitriones que inspiran',
      entryDescription:
        'Conoce testimonios de anfitriones que han convertido sus espacios en referentes para profesionales y empresas.',
      entryImage: 'spaces/slider3.jpg',
      entryRegDate: '18.06.24',
      longDescription: `
        <p>Las <strong>historias de éxito</strong> de anfitriones en espacios de coworking demuestran cómo una buena gestión puede transformar un espacio común en un lugar de inspiración y productividad.</p>
        <h5>Ejemplos Inspiradores:</h5>
        <ul>
          <li><strong>WorkHub NYC:</strong> Comenzaron con un pequeño espacio de 5 escritorios y ahora alojan a más de 100 startups.</li>
          <li><strong>GreenSpace LA:</strong> Crearon un ambiente sostenible que atrae a empresas eco-conscientes.</li>
          <li><strong>Collaborative Minds:</strong> Un espacio temático que fomenta la colaboración entre industrias creativas.</li>
        </ul>
        <p>Estos anfitriones han demostrado que el éxito se basa en entender las necesidades de los profesionales y ofrecer un servicio de calidad.</p>
        <h4>Referencias</h4>
        <ul>
          <li><a href="https://www.successcoworking.com">Success Coworking</a></li>
          <li><a href="https://www.sharedesk.net">ShareDesk</a></li>
          <li><a href="https://www.inc.com">Inc: Coworking success stories</a></li>
        </ul>
      `,
    },
    {
      entryTitle: 'Herramientas esenciales para gestionar tu espacio',
      entryDescription:
        'Explora las herramientas y plataformas que facilitan la administración y operación de espacios compartidos.',
      entryImage: 'spaces/spaceslider.png',
      entryRegDate: '22.06.24',
      longDescription: `
        <p>La gestión eficiente de un <strong>espacio de coworking</strong> requiere herramientas tecnológicas que simplifiquen las tareas diarias y mejoren la experiencia de los usuarios.</p>
        <h5>Herramientas Esenciales:</h5>
        <ul>
          <li><strong>Softwares de Gestión:</strong> Plataformas como Nexudus o OfficeRnD permiten administrar membresías y pagos fácilmente.</li>
          <li><strong>Plataformas de Reservas:</strong> Apps como Skedda facilitan la reserva de salas de reuniones.</li>
          <li><strong>CRM:</strong> Un sistema de gestión de relaciones con clientes para interactuar con usuarios y leads.</li>
          <li><strong>Monitoreo de Espacios:</strong> Herramientas IoT para controlar iluminación, temperatura y aforo en tiempo real.</li>
        </ul>
        <p>Invertir en estas herramientas no solo optimiza la administración, sino que mejora la experiencia de los miembros del coworking.</p>
        <h4>Referencias</h4>
        <ul>
          <li><a href="https://www.nexudus.com">Nexudus: Gestión de coworking</a></li>
          <li><a href="https://www.officernd.com">OfficeRnD</a></li>
          <li><a href="https://www.capterra.com">Capterra: Comparación de herramientas</a></li>
        </ul>
      `,
    },
  ];

  const guestCatEntriesData = [
    {
      entryTitle: 'Consejos para aprovechar al máximo un espacio de coworking',
      entryDescription:
        'Descubre cómo optimizar tu tiempo y recursos mientras trabajas en un espacio compartido.',
      entryImage: 'spaces/slider1.jpg',
      entryRegDate: '12.06.24',
      longDescription: `
        <p>Un espacio de coworking puede ser mucho más que un lugar para trabajar si lo aprovechas al máximo. Aquí te dejamos algunos <strong>consejos clave</strong>:</p>
        <ul>
          <li><strong>Organiza tu tiempo:</strong> Establece horarios claros para evitar distracciones y maximizar la productividad.</li>
          <li><strong>Interactúa con otros:</strong> El networking es uno de los mayores beneficios del coworking. Conoce a otros profesionales y colabora en proyectos.</li>
          <li><strong>Utiliza los recursos:</strong> Aprovecha las salas de reuniones, el internet de alta velocidad y demás servicios disponibles.</li>
          <li><strong>Respeta los espacios comunes:</strong> Mantén tu lugar ordenado y respeta las áreas compartidas para un ambiente armonioso.</li>
        </ul>
        <p>Al aplicar estos consejos, no solo aumentarás tu eficiencia, sino que también disfrutarás de una experiencia enriquecedora.</p>
        <h4>Referencias</h4>
        <ul>
          <li><a href="https://www.coworkinginsights.com">Coworking Insights: Cómo aprovechar coworkings</a></li>
          <li><a href="https://www.hubspot.com">HubSpot: Productividad en espacios de trabajo</a></li>
          <li><a href="https://www.lifehacker.com">LifeHacker: Consejos para coworking</a></li>
        </ul>
      `,
    },
    {
      entryTitle: 'Etiqueta y buenas prácticas en espacios compartidos',
      entryDescription:
        'Aprende las normas de etiqueta esenciales para convivir en armonía con otros profesionales.',
      entryImage: 'spaces/slider2.jpg',
      entryRegDate: '16.06.24',
      longDescription: `
        <p>La convivencia en un <strong>espacio compartido</strong> requiere de respeto mutuo y buenas prácticas para mantener un ambiente armonioso.</p>
        <h5>Normas de etiqueta esenciales:</h5>
        <ul>
          <li><strong>Silencio moderado:</strong> Evita hablar en voz alta o realizar llamadas prolongadas en áreas comunes.</li>
          <li><strong>Mantén tu espacio limpio:</strong> Ordena tu escritorio y deja los espacios comunes en buenas condiciones.</li>
          <li><strong>Respeta los horarios:</strong> Si reservas una sala de reuniones, respeta el tiempo asignado.</li>
          <li><strong>Evita invadir espacios ajenos:</strong> Mantén tus pertenencias dentro de tu área de trabajo.</li>
        </ul>
        <p>Respetar estas normas no solo mejora tu experiencia, sino también la de los demás usuarios del coworking.</p>
        <h4>Referencias</h4>
        <ul>
          <li><a href="https://www.wework.com">WeWork: Normas de etiqueta</a></li>
          <li><a href="https://www.coworker.com">Coworker: Buenas prácticas</a></li>
          <li><a href="https://www.remote.co">Remote.co: Convivencia en coworkings</a></li>
        </ul>
      `,
    },
    {
      entryTitle: 'Ventajas de ser invitado en un coworking',
      entryDescription:
        'Explora los beneficios de trabajar en un entorno colaborativo como invitado y cómo te puede impulsar profesionalmente.',
      entryImage: 'spaces/slider3.jpg',
      entryRegDate: '20.06.24',
      longDescription: `
        <p>Ser invitado en un <strong>coworking</strong> te permite disfrutar de muchos beneficios sin compromiso a largo plazo.</p>
        <h2>Beneficios clave:</h2>
        <ul>
          <li><strong>Acceso a recursos profesionales:</strong> Internet rápido, salas de reuniones y cafeterías.</li>
          <li><strong>Networking inmediato:</strong> Oportunidades de conocer a freelancers y emprendedores.</li>
          <li><strong>Ambiente productivo:</strong> Un entorno profesional ayuda a concentrarse y evitar distracciones.</li>
          <li><strong>Flexibilidad:</strong> Puedes usar el espacio según tus necesidades sin contratos permanentes.</li>
        </ul>
        <p>Trabajar como invitado en un coworking puede ser la puerta de entrada a un entorno inspirador y colaborativo que impulse tu crecimiento profesional.</p>
        <h2>Referencias</h2>
        <ul>
          <li><a href="https://www.forbes.com">Forbes: Beneficios del coworking</a></li>
          <li><a href="https://www.sharedesk.net">ShareDesk: Ventajas para freelancers</a></li>
          <li><a href="https://www.businessnewsdaily.com">BusinessNewsDaily: Coworking trends</a></li>
        </ul>
      `,
    },
    {
      entryTitle: 'Qué llevar contigo al visitar un coworking',
      entryDescription:
        'Una guía práctica sobre lo esencial para tener una experiencia productiva en un espacio de coworking.',
      entryImage: 'spaces/spaceslider.png',
      entryRegDate: '24.06.24',
      longDescription: `
        <p>Si estás planeando visitar un <strong>espacio de coworking</strong>, llevar los elementos adecuados garantizará una experiencia productiva y sin contratiempos.</p>
        <h5>Checklist de elementos esenciales:</h5>
        <ul>
          <li><strong>Portátil y cargador:</strong> Asegúrate de tener tu equipo de trabajo listo.</li>
          <li><strong>Audífonos con cancelación de ruido:</strong> Ideales para concentrarte en un entorno compartido.</li>
          <li><strong>Botella de agua reutilizable:</strong> Mantente hidratado mientras trabajas.</li>
          <li><strong>Cuaderno y bolígrafos:</strong> A veces lo analógico es mejor para tomar notas rápidas.</li>
          <li><strong>Tarjetas de presentación:</strong> Aprovecha el networking con otros profesionales.</li>
        </ul>
        <p>Preparar tu visita con anticipación te ayudará a tener una jornada productiva y aprovechar al máximo tu tiempo en el coworking.</p>
        <h4>Referencias</h4>
        <ul>
          <li><a href="https://www.coworkingresources.org">Coworking Resources</a></li>
          <li><a href="https://www.thebalancecareers.com">The Balance Careers: Guía para coworking</a></li>
          <li><a href="https://www.flexjobs.com">FlexJobs: Checklist de coworking</a></li>
        </ul>
      `,
    },
  ];

  return (
    <FlexContainer className={'flex-column width100 blogView'}>
      <HelmetComponent
        title={lenguageData === 'EN' ? 'Blog - Popnest' : 'Blog - Popnest'}
        description={lenguageData === 'EN' ? enDescription : esDescription}
        graphTitle={lenguageData === 'EN' ? 'Blog - Popnest' : 'Blog - Popnest'}
        graphDescription={lenguageData === 'EN' ? enDescription : esDescription}
      />
      <FlexContainer className={'flex-column banner width100 items-align-left'}>
        {!isShowDetail && (
          <>
            <FlexContainer
              className={'info-box flex-column align-items-center'}
            >
              <Label className="text-40 m-text-32 bold padlr100 m-padlr20">
                <strong>Blog de Popnest</strong>
              </Label>
            </FlexContainer>
            <img
              src="spaces/spaceslider.png"
              alt="Foto 1"
              style={{ width: '100%', height: '500px' }}
              className={''}
            />
          </>
        )}
        {isShowDetail && (
          <>
            <FlexContainer
              className={'info-box flex-column align-items-center'}
            >
              <Label className="text-40 m-text-28 bold padlr100 m-padlr20">
                <strong>{detailData?.entryTitle}</strong>
              </Label>
            </FlexContainer>
            <img
              src={detailData?.entryImage}
              alt="Detalle de foto de entrada"
              style={{ width: '100%', height: '500px' }}
              className={''}
            />
          </>
        )}
      </FlexContainer>
      {!isShowDetail && (
        <FlexContainer className={'flex-column margt40 padlr80 m-padlr10'}>
          <EntriesRow
            category={'Espacios'}
            entriesData={spacesCatEntriesData}
            setDetailEntryData={setDetailEntryData}
          />
          <EntriesRow
            category={'Anfitriones'}
            entriesData={hostCatEntriesData}
            setDetailEntryData={setDetailEntryData}
          />
          <EntriesRow
            category={'Invitados'}
            entriesData={guestCatEntriesData}
            setDetailEntryData={setDetailEntryData}
          />
        </FlexContainer>
      )}
      {isShowDetail && (
        <FlexContainer className={'margt40 padlr100 m-padlr10'}>
          <FlexContainer className={'flex-column format40 align-items-end'}>
            <Label className="text-22 m-text-18 margt10 marglr10 bold">
              {detailData?.entryRegDate} -
            </Label>
          </FlexContainer>
          <FlexContainer className={'flex-column format100'}>
            <Label className="text-26 m-text-18 margb40 margt10 bold">
              <strong>{detailData?.entryTitle}</strong>
            </Label>
            <Label className="text-18 m-text-14 margb10 bold">
              {detailData?.entryDescription}
            </Label>
            <Label
              className="text-20 m-text-16 margb60"
              dangerouslySetInnerHTML={{ __html: detailData?.longDescription }}
            />
            <Label className="text-20 m-text-16 margb60">
              <YouTubeEmbed />
            </Label>
          </FlexContainer>
        </FlexContainer>
      )}
    </FlexContainer>
  );
};

export default Blog;
