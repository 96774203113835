import React, { useState, useEffect } from 'react';
import { fetchReservationById } from '@/services/reservationService';

export const useReservationSuccess = (id) => {
  const [reservation, setReservation] = useState(null);
  const [spaceDetails, setSpaceDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(true);

  useEffect(() => {
    if (!id) return;
    const getReservation = async () => {
      try {
        const data = await fetchReservationById(id);
        setReservation(data);
        const spaceDetailsFromData = {
          space_name: data[0]?.space_name,
          space_title: data[0]?.space_name,
          space_address: data[0]?.space_address,
          subtype_title: data[0]?.subtype_title,
          host_capacity: data[0]?.host_capacity,
          main_image: data[0]?.main_image,
          space_details: data[0]?.space_details,
        };
        console.log('d', spaceDetailsFromData);
        setSpaceDetails(spaceDetailsFromData);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };
    getReservation();
  }, [id]);
  return {
    setIsLoading,
    spaceDetails,
    reservation,
    isLoading,
    error,
  };
};
