import React, { useState, useEffect } from 'react';
import { useFetchPostCommentById } from '@/services/postCommentsCopyService';

export const useProductDetails = id => {
  const [isLoading, setIsLoading] = useState(true);
  const [spacesData, setSpacesData] = useState([]);

  const { data, error } = useFetchPostCommentById(id);

  useEffect(() => {
    if (data) {
      setSpacesData(data);
      setIsLoading(false);
    } else if (error) {
      console.error('Error fetching product details:', error);
      setIsLoading(false);
    }
  }, [data, error]);

  return {
    isLoading,
    spacesData,
  };
};
