import React from 'react';
import './floatingButton.scss';

const FloatingButton = (props) => {
  const { onClick, extraClass } = props;
  return (
    <button className={'floating-button ' + extraClass} onClick={onClick}>
      Mostrar mapa
    </button>
  );
};

export default FloatingButton;
