import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { setSearchFormData } from '@/redux/actions/searchFormDataActions';
import { FlexContainer } from '@/components/containers/FlexContainer/FlexContainer';
import { useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { TextField } from '@mui/material';
import { Button } from '@mui/material';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { useNavigate } from 'react-router-dom';
import Label from '@/components/form/Label/Label';
import dayjs from 'dayjs';
import Slider from 'rc-slider';
import './GeneralSearchForm.scss';

require('dayjs/locale/es');
dayjs.locale('es');

const googleApiKey = 'AIzaSyC75q_cO4SIMGhQgRJwAIpNfNQ5cXwVXnU';

const validationSchema = Yup.object({
  startTime: Yup.number()
    .required('Hora de inicio requerida')
    .nullable()
    .min(7, 'La hora de inicio no puede ser antes de las 7 AM')
    .max(22, 'La hora de inicio no puede ser después de las 10 PM'),
  endTime: Yup.number()
    .required('Hora de fin requerida')
    .nullable()
    .min(
      Yup.ref('startTime'),
      'La hora de fin no puede ser anterior a la hora de inicio',
    )
    .max(22, 'La hora de fin no puede ser después de las 10 PM'),
  guests: Yup.number()
    .required('Cantidad de invitados requerida')
    .min(1, 'Debe haber al menos un invitado')
    .max(100, 'El número máximo de invitados es 100')
    .integer('Debe ser un número entero'),
  spaceType: Yup.string().required('Debe seleccionar un tipo de espacio'),
});

const formatDateToWords = date => {
  const parsedDate = dayjs(date?.$d);
  return parsedDate.format('dddd D [de] MMMM [de] YYYY');
};

const ReservationSearchForm = props => {
  const { coordinates, selectedSuggestion } = props;
  const lenguageData = useSelector(state => state.lenguage?.lang);
  const searchFormData = useSelector(state => state.searchFormData);
  console.log('ver data desde redux:', searchFormData);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <Formik
      initialValues={{
        startDate: searchFormData?.startDate || null,
        startTime: searchFormData?.startTime || 9,
        endTime: searchFormData?.endTime || 10,
        guests: searchFormData?.guests || 1,
        spaceType: searchFormData?.spaceType || 'coworking',
      }}
      validationSchema={validationSchema}
      onSubmit={values => {
        console.log(values);
        //const searchFormData = values;
        const searchFormData = { ...values, coordinates, selectedSuggestion };
        dispatch(setSearchFormData(searchFormData));
        navigate(lenguageData === 'ES' ? '/lista-espacios' : '/spaces-list');
      }}
    >
      {({ setFieldValue, values }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Form>
            <FlexContainer column className={'justify-around'}>
              {values.startDate && (
                <FlexContainer className="flex justify-between align-baseline">
                  <label className={'text-16 m-text-12 bold'}>
                    <span>{formatDateToWords(values.startDate)}</span>
                  </label>
                  <button
                    type="button"
                    className={
                      'bgGray050 border-rad4px padlr20 padtb6 borderGray050 fontGray500 text-16'
                    }
                    onClick={() => setFieldValue('startDate', null)}
                  >
                    <i
                      className={'fa fa-arrow-left margr6'}
                      aria-hidden="true"
                    />
                    Atrás
                  </button>
                </FlexContainer>
              )}

              {!values.startDate && (
                <FlexContainer className="flex-column justify-around items-center">
                  <label className={'text-14 margb20'}>
                    Seleccione la fecha de su reserva
                  </label>
                  <Field name="startDate">
                    {({ field, form }) => (
                      <div style={{ width: '100%' }}>
                        <StaticDatePicker
                          displayStaticWrapperAs="desktop"
                          label="Fecha de reservación"
                          value={values.startDate}
                          minDate={dayjs()}
                          onChange={newValue =>
                            setFieldValue('startDate', newValue)
                          }
                          renderInput={params => (
                            <TextField
                              {...params}
                              error={Boolean(
                                form.errors.startDate && form.touched.startDate,
                              )}
                              helperText={
                                form.touched.startDate && form.errors.startDate
                              }
                              fullWidth
                            />
                          )}
                          componentsProps={{
                            actionBar: { actions: [] },
                          }}
                          sx={{
                            '& .Mui-selected': {
                              backgroundColor: '#6c75f0',
                              color: '#ffffff',
                            },
                            '& .MuiDayPicker-root': {
                              minWidth: '350px',
                            },
                            '& .MuiButtonBase-root': {
                              fontSize: '1.2rem',
                            },
                          }}
                        />
                      </div>
                    )}
                  </Field>
                </FlexContainer>
              )}

              {values.startDate && (
                <>
                  <Field name="spaceType">
                    {({ field, form }) => (
                      <div>
                        <p className={'text-12 margt20'}>
                          Selecciona tipo de espacio que deseas buscar:
                        </p>
                        <FlexContainer className={''}>
                          <FlexContainer
                            className={'align-items-center margr30'}
                          >
                            <input
                              type="checkbox"
                              name="spaceType"
                              value="coworking"
                              checked={form.values.spaceType === 'coworking'}
                              onChange={() =>
                                setFieldValue('spaceType', 'coworking')
                              }
                              className="custom-checkbox"
                            />
                            <span className="text-14 margl4">Coworking</span>
                          </FlexContainer>
                          <FlexContainer className={'align-items-center'}>
                            <input
                              type="checkbox"
                              name="spaceType"
                              value="sala de juntas"
                              checked={
                                form.values.spaceType === 'sala de juntas'
                              }
                              onChange={() =>
                                setFieldValue('spaceType', 'sala de juntas')
                              }
                              className="custom-checkbox"
                            />
                            <span className="text-14 margl4">
                              Sala de Juntas
                            </span>
                          </FlexContainer>
                        </FlexContainer>
                        {form.errors.spaceType && form.touched.spaceType && (
                          <div className="text-red-600">
                            {form.errors.spaceType}
                          </div>
                        )}
                      </div>
                    )}
                  </Field>

                  <Field name="startTime">
                    {({ field, form }) => (
                      <div>
                        <p className={'text-12 margt20'}>
                          Hora de inicio: {form.values.startTime}:00
                        </p>
                        <Slider
                          min={7}
                          max={22}
                          value={form.values.startTime}
                          onChange={value =>
                            form.setFieldValue('startTime', value)
                          }
                          trackStyle={{ backgroundColor: '#716cbf' }}
                          handleStyle={{ borderColor: '#716cbf' }}
                        />
                        {form.errors.startTime && form.touched.startTime && (
                          <div className="text-red-600">
                            {form.errors.startTime}
                          </div>
                        )}
                      </div>
                    )}
                  </Field>

                  <Field name="endTime">
                    {({ field, form }) => (
                      <div>
                        <p className={'text-12 margt20'}>
                          Hora de fin: {form.values.endTime}:00
                        </p>
                        <Slider
                          min={7}
                          max={22}
                          value={form.values.endTime}
                          onChange={value =>
                            form.setFieldValue('endTime', value)
                          }
                          trackStyle={{ backgroundColor: '#716cbf' }}
                          handleStyle={{ borderColor: '#716cbf' }}
                        />
                        {form.errors.endTime && form.touched.endTime && (
                          <div className="text-red-600">
                            {form.errors.endTime}
                          </div>
                        )}
                      </div>
                    )}
                  </Field>

                  <Field name="guests">
                    {({ field, form }) => (
                      <div>
                        <p className="text-12 margt20">Cantidad de personas:</p>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                          }}
                        >
                          <Button
                            variant="contained"
                            style={{
                              minWidth: '40px',
                              height: '40px',
                              fontSize: '16px',
                            }}
                            onClick={() =>
                              form.setFieldValue(
                                'guests',
                                Math.max(1, form.values.guests - 1),
                              )
                            }
                            disabled={form.values.guests <= 1}
                          >
                            -
                          </Button>
                          <TextField
                            value={form.values.guests}
                            inputProps={{
                              readOnly: true,
                              style: {
                                textAlign: 'center',
                                fontSize: '16px',
                                height: '40px',
                              },
                            }}
                            style={{
                              width: '50px',
                            }}
                          />
                          <Button
                            variant="contained"
                            style={{
                              minWidth: '40px',
                              height: '40px',
                              fontSize: '16px',
                            }}
                            onClick={() =>
                              form.setFieldValue(
                                'guests',
                                Math.min(100, form.values.guests + 1),
                              )
                            }
                            disabled={form.values.guests >= 100}
                          >
                            +
                          </Button>
                        </div>
                      </div>
                    )}
                  </Field>
                </>
              )}
            </FlexContainer>

            {values.startDate && (
              <FlexContainer className={'flex-column'}>
                <button
                  type="submit"
                  className={
                    'bgPurple4 bold border-rad8px borderGray050 fontWhite grayShadow16 margt20 padlr40 padtb12 text-14'
                  }
                >
                  Buscar
                </button>
              </FlexContainer>
            )}
          </Form>
        </LocalizationProvider>
      )}
    </Formik>
  );
};

const GoogleMapComponent = props => {
  const { coordinates, isMobile, spacesData } = props;
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: googleApiKey,
  });

  const [map, setMap] = React.useState(null);

  const center = {
    lat: coordinates?.lat,
    lng: coordinates?.lng,
  };

  const containerStyle = {
    width: '100%',
    height: isMobile ? '400px' : '100%',
  };

  const mapStyles = [
    {
      featureType: 'all',
      elementType: 'geometry',
      stylers: [{ saturation: -30 }],
    },
    {
      featureType: 'landscape',
      elementType: 'geometry',
      stylers: [{ color: '#f5f3e5' }],
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [{ color: '#e0e0e0' }],
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [{ color: '#d9e3f0' }],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [{ color: '#e5e9d3' }],
    },
    {
      featureType: 'poi',
      elementType: 'labels.icon',
      stylers: [{ color: '#6c75f0' }],
    },
    {
      featureType: 'all',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#6c75f0' }],
    },
    {
      featureType: 'administrative.locality',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#808080' }],
    },
    {
      featureType: 'administrative.province',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#808080' }],
    },
  ];

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  /*
  const customIcon = {
    fillColor: '#FF5733',
  };
  */

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={13}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={{
        styles: mapStyles,
      }}
    >
      {spacesData &&
        spacesData
          .map(space => ({
            lat: parseFloat(space.space_latitude.trim()),
            lng: parseFloat(space.space_logitude.trim()),
          }))
          .filter(space => !isNaN(space.lat) && !isNaN(space.lng))
          .map((space, index) => (
            <Marker
              key={index}
              position={{ lat: space.lat, lng: space.lng }}
              //icon={customIcon}
              onClick={() =>
                console.log(`Clicked marker at ${space.lat}, ${space.lng}`)
              }
            />
          ))}
    </GoogleMap>
  ) : (
    <></>
  );
};

const LocationSuggestions = props => {
  const { suggestions, setLocationToSearch } = props;
  return (
    <FlexContainer className={'flex-column width100 margt20 gap-10'}>
      {suggestions?.map(suggestion => (
        <FlexContainer
          className={
            'fontGray400 grayShadow16 padlr10 padtb20 text-12 border-rad10px pointer'
          }
          key={suggestion.place_id}
          onClick={() => setLocationToSearch(suggestion)}
        >
          <i className={'fa fa-map-marker-alt margr10 fontPurple4'} />
          {suggestion.description}
          <FlexContainer className={'nextStepButton margl10'}>
            <i className={'fa fa-arrow-right fontPurple4 text-14'} />
          </FlexContainer>
        </FlexContainer>
      ))}
    </FlexContainer>
  );
};

const ReservationDetails = props => {
  const { selectedSuggestion, spacesData } = props;
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
  const [showMap, setShowMap] = useState(true);

  useEffect(() => {
    if (selectedSuggestion?.place_id && window.google) {
      const service = new window.google.maps.places.PlacesService(
        document.createElement('div'),
      );
      service.getDetails(
        { placeId: selectedSuggestion.place_id },
        (place, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            setCoordinates(place.geometry.location.toJSON());
          } else {
            console.error('Error fetching place details:', status);
          }
        },
      );
    }
  }, [selectedSuggestion]);

  if (!(coordinates.lat && coordinates.lng)) {
    return <p>Cargando coordenadas...</p>;
  } else {
    return (
      <>
        <FlexContainer className={'flex-column width100 fontGray400 d-hide'}>
          <FlexContainer className={'flex-column'}>
            <button
              className={
                'bgWhite bold border-rad8px borderGray050 fontGray500 grayShadow16 margtb20 padlr40 padtb12 text-10 d-hide'
              }
              onClick={() => setShowMap(!showMap)}
            >
              Ver mapa
            </button>
          </FlexContainer>
          {showMap && (
            <GoogleMapComponent
              coordinates={coordinates}
              spacesData={spacesData}
              isMobile
            />
          )}
        </FlexContainer>
        <FlexContainer className={'flex-column width100'}>
          <Label
            className={
              'fontGray300 text-20 m-text-16 text-left margt20 m-margt10'
            }
          >
            Espacios en <strong>{selectedSuggestion?.description}</strong>
          </Label>
        </FlexContainer>
        <FlexContainer className={'m-flex-column width100 margt20 gap-20'}>
          <FlexContainer className={'flex-column width100 fontGray400'}>
            <ReservationSearchForm
              coordinates={coordinates}
              selectedSuggestion={selectedSuggestion}
            />
          </FlexContainer>
          <FlexContainer
            className={'flex-column width100 fontGray400 t-hide m-hide'}
          >
            <GoogleMapComponent
              coordinates={coordinates}
              spacesData={spacesData}
            />
          </FlexContainer>
        </FlexContainer>
      </>
    );
  }
};

const GeneralSearchForm = props => {
  const {
    inputValue,
    className,
    suggestions,
    spacesData,
    externalSelectedSuggestion,
  } = props;
  const [selectedSuggestion, setSelectedSuggestion] = useState(
    externalSelectedSuggestion || null,
  );

  const setLocationToSearch = suggestion => {
    setSelectedSuggestion(suggestion);
  };

  const containerClass =
    className + ' generalSearchForm flex-column border-rad16px margt4';
  return (
    <FlexContainer className={containerClass}>
      {!selectedSuggestion && (
        <>
          <Label
            className={
              'fontGray300 text-20 m-text-16 text-left margt20 m-margt10'
            }
          >
            Resultados de la búsqueda para:{' '}
            <span className={'bold fontGray300'}>{inputValue}...</span>
          </Label>
          <LocationSuggestions
            suggestions={suggestions}
            selectedSuggestion={selectedSuggestion}
            setLocationToSearch={setLocationToSearch}
          />
        </>
      )}
      {selectedSuggestion && (
        <ReservationDetails
          selectedSuggestion={selectedSuggestion}
          spacesData={spacesData}
        />
      )}
    </FlexContainer>
  );
};

export default GeneralSearchForm;
