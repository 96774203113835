import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Home } from '@/components/pages/Home';
import { About } from '@/components/pages/About';
import { Blog } from '@/components/pages/Blog';
import { ProductPreList } from '@/components/pages/ProductPreList';
import { ProductDetails } from '@/components/pages/ProductDetails';
import { NotFound } from '@/components/pages/NotFound';
import { Checkout } from '@/components/pages/Checkout';
import { ReservationSuccess } from '@/components/pages/ReservationSuccess';
import { TermsAndConditions } from '@/components/pages/TermsAndConditions';
import { TermsAndConditionsGuest } from '@/components/pages/TermsAndConditionsGuest';
import { TermsAndConditionsPromo } from '@/components/pages/TermsAndConditionsPromo';
import { PrivacyConditions } from '@/components/pages/PrivacyConditions';
import { PromoLanding } from '@/components/pages/PromoLanding';
import { ProductDetailsLandingPage } from '@/components/pages/ProductDetailsLandingPage';
import CreacionSpace from '@/components/pages/Home/CreacionSpace';
import CreacionUser from '@/components/pages/Home/CreacionUser';

const AppRoutes = () => {
  const location = useLocation();
  return (
    <Routes>
      <Route path="/" element={<Home key={location.key} />} />
      <Route path="/spaces" element={<About />} />
      <Route path="/espacios" element={<About />} />
      {/*Añadido*/}

      <Route path="/blog" element={<Blog />} />
      <Route path="/blog-popnest" element={<Blog />} />
      {/*Añadido*/}

      <Route path="/your-spaces" element={<About />} />
      {/*Modificado*/}
      <Route path="/tu-espacio" element={<About />} />
      {/*Añadido*/}

      <Route path="/promo-landing" element={<PromoLanding />} />
      <Route path="/promo-aterrizaje" element={<PromoLanding />} />
      {/*Añadido*/}

      <Route path="/lista-espacios" element={<ProductPreList />} />
      <Route path="/spaces-list" element={<ProductPreList />} />
      {/*Añadido*/}

      <Route path="/confirmar-reservacion" element={<ProductDetails />} />
      <Route path="/confirmar-reservacion/:customText" element={<ProductDetails />} />
      <Route path="/confirm-reservation" element={<ProductDetails />} />
      <Route path="/confirm-reservation/:customText" element={<ProductDetails />} />
      {/*Añadido*/}

      <Route path="/reserva-confirmada" element={<ReservationSuccess />} />
      <Route path="/reservation-confirmed" element={<ReservationSuccess />} />
      {/*Añadido*/}

      <Route path="/pago" element={<Checkout />} />
      <Route path="/pay" element={<Checkout />} />
      {/*Añadido*/}

      <Route path="/terms-conditions-host" element={<TermsAndConditions />} />
      {/*Modificado*/}
      <Route path="/terminos-condiciones-anfitrion" element={<TermsAndConditions />} />
      <Route path="/terms-conditions-guest" element={<TermsAndConditionsGuest />} />
      {/*Modificado*/}
      <Route path="/terminos-condiciones-invitado" element={<TermsAndConditionsGuest />} />

      {/*Añadido*/}
      <Route path="/terminos-condiciones-promocion" element={<TermsAndConditionsPromo />} />
      {/*Modificado*/}
      <Route path="/terms-conditions-promo" element={<TermsAndConditionsPromo />} />

      {/*Añadido*/}
      <Route path="/privacy-conditions" element={<PrivacyConditions />} />
      {/*Modificado*/}
      <Route path="/condiciones-privacidad" element={<PrivacyConditions />} />
      {/*Añadido*/}

      <Route path="*" element={<NotFound />} />

      <Route path="/creation-user" element={<CreacionUser />} />
      <Route path="/creacion-usuario" element={<CreacionUser />} />

      <Route path="/creation-space" element={<CreacionSpace />} />
      <Route path="/creacion-espacio" element={<CreacionSpace />} />

      <Route
        path="/paseDeCoworkingPolanco"
        element={<ProductDetailsLandingPage language={'ES'} space={7} />}
      />
      <Route
        path="/dayPassCoworkingPolanco"
        element={<ProductDetailsLandingPage language={'EN'} space={7} />}
      />
      <Route
        path="/paseDeCoworkingCondesa"
        element={<ProductDetailsLandingPage language={'ES'} space={55} />}
      />
      <Route
        path="/dayPassCoworkingCondesa"
        element={<ProductDetailsLandingPage language={'EN'} space={55} />}
      />
      <Route
        path="/salaDeJuntasPolanco"
        element={<ProductDetailsLandingPage language={'ES'} space={10} />}
      />
      <Route
        path="/meetingRoomPolanco"
        element={<ProductDetailsLandingPage language={'EN'} space={10} />}
      />
    </Routes>
  );
};

export default AppRoutes;
