import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  IconButton,
  Grid,
  Box,
  Divider,
  Typography,
  Alert,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import { Editor } from '@tinymce/tinymce-react';
import {
  updateSpaceSubtypeRelationship,
  updateSpaceMainImageSubtypeRelationship,
} from '../../services/spacesSubtypeRelationshipService';

import { updateSpaceMainImage, updateSpace } from '../../services/spacesService';

const DetailedSpaceInfo = ({ spaceInfo, onSave, closeModal }) => {
  const [formData, setFormData] = useState({
    space_id: '',
    space_name: '',
    space_address: '',
    space_title: '',
    main_image: [],
    space_state: '',
    space_details_general: '',
    host_id: '',
    house_number: '',
    space_latitude: '',
    space_logitude: '',
    street_reference: '',
    zip_code: '',
    is_reserved: false,
    gmaps_url: '',
    space_name_en: '',
    space_title_en: '',
    space_details_general_en: '',

    id_relationship: '',
    subtype_id: '',
    space_details: '',
    host_capacity: '',
    pet_friendly: false,
    coffe_water: false,
    hourly_rate: '',
    rate_4_hours: '',
    rate_8_hours: '',
    rate_24_hours: '',
    order: '',
    state_id: '',
    parking: false,
    parking_details: '',
    staff: false,
    cleaning_service: false,
    printing_service: false,
    common_areas: false,
    convention_hall: false,
    snacks: false,
    phone_service: false,
    space_details_en: '',
    parking_details_en: '',
    main_image_subtype: [],
    url_image: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    if (spaceInfo) {
      setFormData({
        space_id: spaceInfo.space_id || 1,

        space_name: spaceInfo.space_name || '',
        space_address: spaceInfo.space_address || '',
        space_title: spaceInfo.space_title || '',
        main_image: spaceInfo.main_image
          ? JSON.parse(spaceInfo.main_image) // Convierte el string en array
          : [],
        space_state: spaceInfo.space_state,
        space_details_general: spaceInfo.space_details_general || '',
        host_id: Number(spaceInfo.host_id) || '',
        house_number: Number(spaceInfo.house_number) || '',
        space_latitude: spaceInfo.space_latitude || '',
        space_logitude: spaceInfo.space_logitude || '',
        street_reference: spaceInfo.street_reference || '',
        zip_code: Number(spaceInfo.zip_code) || '',
        is_reserved: spaceInfo.is_reserved === '1' ? 1 : 0,
        gmaps_url: spaceInfo.gmaps_url || '',
        space_name_en: spaceInfo.space_name_en || '',
        space_title_en: spaceInfo.space_title_en || '',
        space_details_general_en: spaceInfo.space_details_general_en || '',

        id_relationship: spaceInfo.id_relationship || '',
        subtype_id: spaceInfo.subtype_id || 8,
        space_details: spaceInfo.space_details || '',
        host_capacity: Number(spaceInfo.host_capacity) || 1,
        pet_friendly: spaceInfo.pet_friendly === '1' ? 1 : 0,
        coffe_water: spaceInfo.coffe_water === '1' ? 1 : 0,
        hourly_rate: Number(spaceInfo.hourly_rate) || '1',
        rate_4_hours: Number(spaceInfo.rate_4_hours) || '1',
        rate_8_hours: Number(spaceInfo.rate_8_hours) || '1',
        rate_24_hours: Number(spaceInfo.rate_24_hours) || '1',
        state_id: spaceInfo.state_id || '',
        parking: spaceInfo.parking === '1' ? 1 : 0,
        parking_details: spaceInfo.parking_details || '',
        staff: spaceInfo.staff === '1' ? 1 : 0,
        cleaning_service: spaceInfo.cleaning_service === '1' ? 1 : 0,
        printing_service: spaceInfo.printing_service === '1' ? 1 : 0,
        common_areas: spaceInfo.common_areas === '1' ? 1 : 0,
        convention_hall: spaceInfo.convention_hall === '1' ? 1 : 0,
        snacks: spaceInfo.snacks === '1' ? 1 : 0,
        phone_service: spaceInfo.phone_service === '1' ? 1 : 0,
        space_details_en: spaceInfo.space_details_en || '',
        parking_details_en: spaceInfo.parking_details_en || '',
        order: Number(spaceInfo.order) || 1,
        main_image_subtype: [],
        url_image: Array.isArray(spaceInfo.url_image)
          ? spaceInfo.url_image
          : JSON.parse(spaceInfo.url_image || '[]'),
      });
    }
  }, [spaceInfo]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData((prev) => ({
      ...prev,
      [name]:
        type === 'checkbox'
          ? checked
            ? 1
            : 0
          : [
              'host_capacity',
              'hourly_rate',
              'rate_4_hours',
              'rate_8_hours',
              'rate_24_hours',
              'state_id',
            ].includes(name)
          ? Number(value) || 0
          : value,
    }));
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setFormData((prev) => ({
      ...prev,
      main_image_subtype: [...prev.main_image_subtype, ...files],
    }));
  };

  const handleMainImageChange = (e) => {
    const file = e.target.files[0]; // Solo una imagen principal
    if (file) {
      setFormData((prev) => ({
        ...prev,
        main_image: [file], // Guardar como array con un solo elemento
      }));
    }
  };

  const updateImageUrlsInDB = async (newUrls) => {
    try {
      setIsLoading(true);

      const updatedSubtypeData = {
        id_relationship: formData.id_relationship,
        url_image: JSON.stringify(newUrls),
      };

      const updatedSpaceData = {
        space_id: formData.space_id,
        main_image: JSON.stringify(newUrls),
      };

      // **Actualizar en ambas APIs**
      const [spaceResponse, subtypeResponse] = await Promise.all([
        updateSpace(updatedSpaceData),
        updateSpaceSubtypeRelationship({
          id: formData.id_relationship,
          relationshipData: updatedSubtypeData,
        }),
      ]);

      if (
        spaceResponse?.message?.includes('updated successfully') &&
        subtypeResponse?.message?.includes('updated successfully')
      ) {
        setSuccessMessage('Su espacio fue actualizado correctamente');
        setFormData((prev) => ({ ...prev, url_image: newUrls }));
      } else {
        console.error('Error al actualizar imágenes:', spaceResponse, subtypeResponse);
      }
    } catch (error) {
      console.error('Error en la actualización de imágenes:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveImage = async (index) => {
    const newUrls = formData.url_image.filter((_, i) => i !== index);
    setFormData((prev) => ({ ...prev, url_image: newUrls }));
    await updateImageUrlsInDB(newUrls);
  };

  const handleSave = async () => {
    setIsLoading(true);

    try {
      if (!formData.space_id || !formData.subtype_id || !formData.id_relationship) {
        console.error('Error: Falta algún campo obligatorio.');
        setIsLoading(false);
        return;
      }

      // **División de datos**
      const spacedata = {
        space_name: formData.space_name,
        space_address: formData.space_address,
        space_title: formData.space_title,
        main_image: formData.main_image,
        space_state: formData.space_state,
        space_details: formData.space_details_general,
        host_id: formData.host_id,
        house_number: formData.house_number,
        space_latitude: formData.space_latitude,
        space_logitude: formData.space_logitude,
        street_reference: formData.street_reference,
        zip_code: formData.zip_code,
        is_reserved: formData.is_reserved,
        gmaps_url: formData.gmaps_url,
        space_name_en: formData.space_name_en,
        space_title_en: formData.space_title_en,
        space_details_en: formData.space_details_general_en,
      };

      const spaceSubtypeRelationshipData = {
        id_relationship: formData.id_relationship,
        space_id: formData.space_id,
        subtype_id: formData.subtype_id,
        space_details: formData.space_details,
        host_capacity: formData.host_capacity,
        pet_friendly: formData.pet_friendly,
        coffe_water: formData.coffe_water,
        hourly_rate: formData.hourly_rate,
        rate_4_hours: formData.rate_4_hours,
        rate_8_hours: formData.rate_8_hours,
        rate_24_hours: formData.rate_24_hours,
        state_id: formData.state_id,
        parking: formData.parking,
        parking_details: formData.parking_details,
        staff: formData.staff,
        cleaning_service: formData.cleaning_service,
        printing_service: formData.printing_service,
        common_areas: formData.common_areas,
        convention_hall: formData.convention_hall,
        snacks: formData.snacks,
        phone_service: formData.phone_service,
        space_details_en: formData.space_details_en,
        parking_details_en: formData.parking_details_en,
        order: formData.order,
        main_image_subtype: formData.main_image_subtype,
        url_image: JSON.stringify(formData.url_image),
      };

      // **Actualización en paralelo**
      const [spaceResponse, subtypeResponse] = await Promise.all([
        updateSpace({ id: formData.space_id, spaceData: spacedata }),
        updateSpaceSubtypeRelationship({
          id: formData.id_relationship,
          relationshipData: spaceSubtypeRelationshipData,
        }),
      ]);

      // **Validación de respuestas**
      if (
        spaceResponse?.message?.includes('updated successfully') &&
        subtypeResponse?.message?.includes('updated successfully')
      ) {
        console.log('Actualización exitosa');

        // **Subir imagen principal si hay una nueva**
        if (formData.main_image.length > 0 && formData.main_image[0] instanceof File) {
          console.log('Subiendo imagen principal:', formData.main_image);
          await updateSpaceMainImage(spaceInfo.space_id, formData.main_image);
        }

        // **Subir imágenes de subtipo si hay nuevas**
        if (formData.main_image_subtype.length > 0) {
          console.log('Subiendo nuevas imágenes:', formData.main_image_subtype);
          await updateSpaceMainImageSubtypeRelationship(
            formData.id_relationship,
            formData.main_image_subtype,
          );
        }

        if (typeof onSave === 'function') {
          onSave(formData);
        }

        setSuccessMessage('Su espacio fue actualizado correctamente');
      } else {
        console.error('Error en la actualización:', spaceResponse, subtypeResponse);
      }
    } catch (error) {
      console.error('Error en la actualización:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={!!spaceInfo} onClose={closeModal} maxWidth="md" fullWidth>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {'Modificar '}
        <IconButton onClick={closeModal} sx={{ color: 'grey.500' }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ mb: 3 }}>
            <Typography variant="h6">Información del Espacio</Typography>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Space Name"
              name="space_name"
              value={formData.space_name}
              onChange={handleChange}
            />
          </Grid>
          {/* Dirección */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Space Address"
              name="space_address"
              value={formData.space_address}
              onChange={handleChange}
            />
          </Grid>
          {/* Título */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Space Title"
              name="space_title"
              value={formData.space_title}
              onChange={handleChange}
            />
          </Grid>
          {/* Estado del Espacio (Dropdown) */}
          <Grid item xs={12}>
            <TextField
              select
              fullWidth
              label="Space State"
              name="space_state"
              value={formData.space_state}
              SelectProps={{ native: true }}
            >
              <option value={1}>Available</option>
              <option value={2}>Unavailable</option>
              <option value={3}>Deleted</option>
            </TextField>
          </Grid>
          {/* Detalles del Espacio (Textarea) */}
          <Grid item xs={12}>
            <Grid item xs={12} sx={{ mb: 2, mt: 2 }}>
              <Typography variant="h8">Space Details</Typography>
              <Divider />
            </Grid>
            <Editor
              apiKey="7qgbz8mytlvs2ilwkv3944y4ihr7ckmi04d4fvn73fbpxqfn"
              init={{
                plugins: [
                  /*
                  'anchor',
                  'autolink',
                  'charmap',
                  'codesample',
                  'emoticons',
                  'image',
                  'link',
                  'lists',
                  'media',
                  'searchreplace',
                  'table',
                  'visualblocks',
                  'wordcount',
                  'checklist',
                  'mediaembed',
                  'casechange',
                  'export',
                  'formatpainter',
                  'pageembed',
                  'a11ychecker',
                  'permanentpen',
                  'powerpaste',
                  'advtable',
                  'advcode',
                  'editimage',
                  'advtemplate',
                  'mentions',
                  'tinycomments',
                  'tableofcontents',
                  'footnotes',
                  'mergetags',
                  'autocorrect',
                  'typography',
                  'inlinecss',
                  'markdown',
                  'importword',
                  'exportword',
                  'exportpdf',
                  */
                ], // Eliminamos 'tinymcespellchecker'
                toolbar:
                  'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                tinycomments_mode: 'embedded',
                tinycomments_author: 'Author name',
                menubar: true,
                readonly: false,
                branding: false, // Oculta el logo de TinyMCE
                promotion: false, // Desactiva promociones de TinyMCE
                setup: function (editor) {
                  editor.on('init', function () {
                    editor.getContainer().querySelector('.tox-statusbar').style.display = 'none'; // Oculta la barra de estado
                  });
                },
              }}
              value={formData.space_details_general || ''}
              onEditorChange={(content) => {
                setFormData((prev) => ({
                  ...prev,
                  space_details_general: content,
                }));
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Grid item xs={12} sx={{ mb: 2, mt: 2 }}>
              <Typography variant="h8">Space Details (EN)</Typography>
              <Divider />
            </Grid>
            <Editor
              apiKey="7qgbz8mytlvs2ilwkv3944y4ihr7ckmi04d4fvn73fbpxqfn"
              init={{
                plugins: [
                  /*
                  'anchor',
                  'autolink',
                  'charmap',
                  'codesample',
                  'emoticons',
                  'image',
                  'link',
                  'lists',
                  'media',
                  'searchreplace',
                  'table',
                  'visualblocks',
                  'wordcount',
                  'checklist',
                  'mediaembed',
                  'casechange',
                  'export',
                  'formatpainter',
                  'pageembed',
                  'a11ychecker',
                  'permanentpen',
                  'powerpaste',
                  'advtable',
                  'advcode',
                  'editimage',
                  'advtemplate',
                  'mentions',
                  'tinycomments',
                  'tableofcontents',
                  'footnotes',
                  'mergetags',
                  'autocorrect',
                  'typography',
                  'inlinecss',
                  'markdown',
                  'importword',
                  'exportword',
                  'exportpdf',
                  */
                ], // Eliminamos 'tinymcespellchecker'
                toolbar:
                  'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                tinycomments_mode: 'embedded',
                tinycomments_author: 'Author name',
                menubar: true,
                readonly: false,
                branding: false, // Oculta el logo de TinyMCE
                promotion: false, // Desactiva promociones de TinyMCE
                setup: function (editor) {
                  editor.on('init', function () {
                    editor.getContainer().querySelector('.tox-statusbar').style.display = 'none'; // Oculta la barra de estado
                  });
                },
              }}
              value={formData.space_details_general_en || ''}
              onEditorChange={(content) => {
                setFormData((prev) => ({
                  ...prev,
                  space_details_general_en: content,
                }));
              }}
            />
          </Grid>

          {/* ID del Host */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Host ID"
              name="host_id"
              type="number"
              value={formData.host_id}
              onChange={handleChange}
            />
          </Grid>

          {/* Número de Casa */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="House Number"
              name="house_number"
              type="number"
              value={formData.house_number}
              onChange={handleChange}
            />
          </Grid>
          {/* Latitud */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Latitude"
              name="space_latitude"
              value={formData.space_latitude}
              onChange={handleChange}
            />
          </Grid>
          {/* Longitud */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Longitude"
              name="space_logitude"
              value={formData.space_logitude}
              onChange={handleChange}
            />
          </Grid>
          {/* Referencia de Calle */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Street Reference"
              name="street_reference"
              value={formData.street_reference}
              onChange={handleChange}
            />
          </Grid>
          {/* Código Postal */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Zip Code"
              name="zip_code"
              type="number"
              value={formData.zip_code}
              onChange={handleChange}
            />
          </Grid>
          {/* Reservado (Dropdown) */}
          <Grid item xs={12}>
            <TextField
              select
              fullWidth
              label="Reservado"
              name="is_reserved"
              value={formData.is_reserved}
              onChange={handleChange}
              SelectProps={{ native: true }}
            >
              <option value={0}>No Reservado</option>
              <option value={1}>Reservado</option>
            </TextField>
          </Grid>
          {/* URL de Google Maps */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Google Maps URL"
              name="gmaps_url"
              value={formData.gmaps_url}
              onChange={handleChange}
            />
          </Grid>
          {/* Nombre en Inglés */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Space Name (EN)"
              name="space_name_en"
              value={formData.space_name_en}
              onChange={handleChange}
            />
          </Grid>
          {/* Título en Inglés */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Space Title (EN)"
              name="space_title_en"
              value={formData.space_title_en}
              onChange={handleChange}
            />
          </Grid>
          {/* Detalles en Inglés */}

          <Grid item xs={12}>
            {/* Subir Imagen Principal */}
            <Button variant="contained" component="label" color="primary">
              Cargar Imagen Principal
              <input type="file" hidden onChange={handleMainImageChange} accept="image/*" />
            </Button>

            {/* Vista previa de la imagen principal actual */}
            {formData.main_image.length > 0 && (
              <Box sx={{ display: 'flex', gap: 1, marginTop: 2 }}>
                <Box sx={{ position: 'relative' }}>
                  <img
                    src={
                      formData.main_image[0] instanceof File
                        ? URL.createObjectURL(formData.main_image[0])
                        : formData.main_image[0]
                    }
                    alt="main"
                    width={100}
                    height={100}
                    style={{ borderRadius: 8, objectFit: 'cover' }}
                  />
                  <IconButton
                    size="small"
                    onClick={() => setFormData((prev) => ({ ...prev, main_image: [] }))}
                    sx={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      background: 'rgba(0,0,0,0.5)',
                      color: '#fff',
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sx={{ mb: 2, mt: 7 }}>
            <Typography variant="h6">Información de Relación Subtipo del Espacio</Typography>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Grid item xs={12} sx={{ mb: 2 }}>
              <Typography variant="h8">Space Type Details </Typography>
              <Divider />
            </Grid>
            <Editor
              apiKey="7qgbz8mytlvs2ilwkv3944y4ihr7ckmi04d4fvn73fbpxqfn"
              init={{
                plugins: [
                  /*
                  'anchor',
                  'autolink',
                  'charmap',
                  'codesample',
                  'emoticons',
                  'image',
                  'link',
                  'lists',
                  'media',
                  'searchreplace',
                  'table',
                  'visualblocks',
                  'wordcount',
                  'checklist',
                  'mediaembed',
                  'casechange',
                  'export',
                  'formatpainter',
                  'pageembed',
                  'a11ychecker',
                  'permanentpen',
                  'powerpaste',
                  'advtable',
                  'advcode',
                  'editimage',
                  'advtemplate',
                  'mentions',
                  'tinycomments',
                  'tableofcontents',
                  'footnotes',
                  'mergetags',
                  'autocorrect',
                  'typography',
                  'inlinecss',
                  'markdown',
                  'importword',
                  'exportword',
                  'exportpdf',
                  */
                ], // Eliminamos 'tinymcespellchecker'
                toolbar:
                  'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                tinycomments_mode: 'embedded',
                tinycomments_author: 'Author name',
                menubar: true,
                readonly: false,
                branding: false, // Oculta el logo de TinyMCE
                promotion: false, // Desactiva promociones de TinyMCE
                setup: function (editor) {
                  editor.on('init', function () {
                    editor.getContainer().querySelector('.tox-statusbar').style.display = 'none'; // Oculta la barra de estado
                  });
                },
              }}
              value={formData.space_details || ''}
              onEditorChange={(content) => {
                setFormData((prev) => ({
                  ...prev,
                  space_details: content,
                }));
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Grid item xs={12} sx={{ mb: 2 }}>
              <Typography variant="h8">Space Type Details (EN)</Typography>
              <Divider />
            </Grid>
            <Editor
              apiKey="7qgbz8mytlvs2ilwkv3944y4ihr7ckmi04d4fvn73fbpxqfn"
              init={{
                plugins: [
                  /*
                  'anchor',
                  'autolink',
                  'charmap',
                  'codesample',
                  'emoticons',
                  'image',
                  'link',
                  'lists',
                  'media',
                  'searchreplace',
                  'table',
                  'visualblocks',
                  'wordcount',
                  'checklist',
                  'mediaembed',
                  'casechange',
                  'export',
                  'formatpainter',
                  'pageembed',
                  'a11ychecker',
                  'permanentpen',
                  'powerpaste',
                  'advtable',
                  'advcode',
                  'editimage',
                  'advtemplate',
                  'mentions',
                  'tinycomments',
                  'tableofcontents',
                  'footnotes',
                  'mergetags',
                  'autocorrect',
                  'typography',
                  'inlinecss',
                  'markdown',
                  'importword',
                  'exportword',
                  'exportpdf',
                  */
                ], // Eliminamos 'tinymcespellchecker'
                toolbar:
                  'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                tinycomments_mode: 'embedded',
                tinycomments_author: 'Author name',
                menubar: true,
                readonly: false,
                branding: false, // Oculta el logo de TinyMCE
                promotion: false, // Desactiva promociones de TinyMCE
                setup: function (editor) {
                  editor.on('init', function () {
                    editor.getContainer().querySelector('.tox-statusbar').style.display = 'none'; // Oculta la barra de estado
                  });
                },
              }}
              value={formData.space_details_en || ''}
              onEditorChange={(content) => {
                setFormData((prev) => ({
                  ...prev,
                  space_details_en: content,
                }));
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              type="number"
              label="Host Capacity"
              name="host_capacity"
              value={formData.host_capacity}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="number"
              label="Hourly Rate"
              name="hourly_rate"
              value={Number(formData.hourly_rate)}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="number"
              label="4 Hours Rate"
              name="rate_4_hours"
              value={Number(formData.rate_4_hours)}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="number"
              label="8 Hours Rate"
              name="rate_8_hours"
              value={Number(formData.rate_8_hours)}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="number"
              label="24 rate"
              name="rate_24_hours"
              value={Number(formData.rate_24_hours)}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Order"
              name="order"
              type="number"
              value={formData.order}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12}>
            <Grid item xs={12} sx={{ mb: 2, mt: 2 }}>
              <Typography variant="h8">Parking Details </Typography>
              <Divider />
            </Grid>
            <Editor
              apiKey="7qgbz8mytlvs2ilwkv3944y4ihr7ckmi04d4fvn73fbpxqfn"
              init={{
                plugins: [
                  /*
                  'anchor',
                  'autolink',
                  'charmap',
                  'codesample',
                  'emoticons',
                  'image',
                  'link',
                  'lists',
                  'media',
                  'searchreplace',
                  'table',
                  'visualblocks',
                  'wordcount',
                  'checklist',
                  'mediaembed',
                  'casechange',
                  'export',
                  'formatpainter',
                  'pageembed',
                  'a11ychecker',
                  'permanentpen',
                  'powerpaste',
                  'advtable',
                  'advcode',
                  'editimage',
                  'advtemplate',
                  'mentions',
                  'tinycomments',
                  'tableofcontents',
                  'footnotes',
                  'mergetags',
                  'autocorrect',
                  'typography',
                  'inlinecss',
                  'markdown',
                  'importword',
                  'exportword',
                  'exportpdf',
                  */
                ], // Eliminamos 'tinymcespellchecker'
                toolbar:
                  'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                tinycomments_mode: 'embedded',
                tinycomments_author: 'Author name',
                menubar: true,
                readonly: false,
                branding: false, // Oculta el logo de TinyMCE
                promotion: false, // Desactiva promociones de TinyMCE
                setup: function (editor) {
                  editor.on('init', function () {
                    editor.getContainer().querySelector('.tox-statusbar').style.display = 'none'; // Oculta la barra de estado
                  });
                },
              }}
              value={formData.parking_details || ''}
              onEditorChange={(content) => {
                setFormData((prev) => ({
                  ...prev,
                  parking_details: content,
                }));
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Grid item xs={12} sx={{ mb: 2, mt: 2 }}>
              <Typography variant="h8">Parking Details (EN)</Typography>
              <Divider />
            </Grid>
            <Editor
              apiKey="7qgbz8mytlvs2ilwkv3944y4ihr7ckmi04d4fvn73fbpxqfn"
              init={{
                plugins: [
                  /*
                  'anchor',
                  'autolink',
                  'charmap',
                  'codesample',
                  'emoticons',
                  'image',
                  'link',
                  'lists',
                  'media',
                  'searchreplace',
                  'table',
                  'visualblocks',
                  'wordcount',
                  'checklist',
                  'mediaembed',
                  'casechange',
                  'export',
                  'formatpainter',
                  'pageembed',
                  'a11ychecker',
                  'permanentpen',
                  'powerpaste',
                  'advtable',
                  'advcode',
                  'editimage',
                  'advtemplate',
                  'mentions',
                  'tinycomments',
                  'tableofcontents',
                  'footnotes',
                  'mergetags',
                  'autocorrect',
                  'typography',
                  'inlinecss',
                  'markdown',
                  'importword',
                  'exportword',
                  'exportpdf',
                  */
                ],
                toolbar:
                  'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                tinycomments_mode: 'embedded',
                tinycomments_author: 'Author name',
                menubar: true,
                readonly: false,
                branding: false, // Oculta el logo de TinyMCE
                promotion: false, // Desactiva promociones de TinyMCE
                setup: function (editor) {
                  editor.on('init', function () {
                    editor.getContainer().querySelector('.tox-statusbar').style.display = 'none'; // Oculta la barra de estado
                  });
                },
              }}
              value={formData.parking_details_en || ''}
              onEditorChange={(content) => {
                setFormData((prev) => ({
                  ...prev,
                  parking_details_en: content,
                }));
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Button variant="contained" component="label" color="primary">
              Cargar Imágenes Relación
              <input type="file" hidden multiple onChange={handleFileChange} accept="image/*" />
            </Button>

            {/* Vista previa de imágenes ACTUALES */}
            <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', marginTop: 2 }}>
              {formData.url_image.map((url, idx) => (
                <Box key={idx} sx={{ position: 'relative' }}>
                  <img
                    src={url}
                    alt={`actual-${idx}`}
                    width={80}
                    height={80}
                    style={{ borderRadius: 8, objectFit: 'cover' }}
                  />
                  <IconButton
                    size="small"
                    onClick={() => handleRemoveImage(idx)}
                    sx={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      background: 'rgba(0,0,0,0.5)',
                      color: '#fff',
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Box>
              ))}
            </Box>

            {/* Vista previa de NUEVAS imágenes */}
            <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', marginTop: 2 }}>
              {formData.main_image_subtype.map((file, idx) => (
                <img
                  key={idx}
                  src={URL.createObjectURL(file)}
                  alt={`preview-${idx}`}
                  width={80}
                  height={80}
                  style={{ borderRadius: 8, objectFit: 'cover' }}
                />
              ))}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TextField
              select
              fullWidth
              label="Subtype"
              name="subtype_id"
              value={formData.subtype_id}
              onChange={handleChange}
              SelectProps={{ native: true }}
            >
              <option value={4}>Terrazas</option>
              <option value={7}>Subtipo AB</option>
              <option value={5}>Oficina Virtual</option>
              <option value={6}>Oficina</option>
              <option value={2}>Sala de Juntas</option>
              <option value={1}>Coworking</option>
              <option value={9}>Sala</option>
              <option value={8}>Estudio</option>
              <option value={2}>Sala</option>
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              select
              fullWidth
              label="State"
              name="state_id"
              value={formData.state_id}
              onChange={handleChange}
              SelectProps={{ native: true }}
            >
              <option value={1}>Available</option>
              <option value={2}>Unavailable</option>
              <option value={3}>Deleted</option>
            </TextField>
          </Grid>
          {[
            { label: 'Estacionamiento', name: 'parking' },
            { label: 'Staff', name: 'staff' },
            { label: 'Limpieza', name: 'cleaning_service' },
            { label: 'Impresión', name: 'printing_service' },
            { label: 'Áreas Comunes', name: 'common_areas' },
            { label: 'Salón de Convenciones', name: 'convention_hall' },
            { label: 'Snacks', name: 'snacks' },
            { label: 'Servicio Telefónico', name: 'phone_service' },
            { label: 'Pet Friendly', name: 'pet_friendly' },
            { label: 'Café/Agua', name: 'coffe_water' },
          ].map(({ label, name }, index) => (
            <Grid item xs={6} key={index}>
              <FormControlLabel
                control={<Checkbox checked={formData[name]} onChange={handleChange} name={name} />}
                label={label}
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      {successMessage && (
        <Box sx={{ marginTop: '20px', textAlign: 'center' }}>
          <Alert severity={'success'}>
            {successMessage}
          </Alert>
        </Box>
      )}

      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleSave} disabled={isLoading}>
          {isLoading ? 'Guardando...' : 'Guardar Cambios'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DetailedSpaceInfo;
