export const englishSpeakingCountries = {
  US: 'United States',
  GB: 'United Kingdom',
  CA: 'Canada',
  AU: 'Australia',
  NZ: 'New Zealand',
  IE: 'Ireland',
  ZA: 'South Africa',
  NG: 'Nigeria',
  KE: 'Kenya',
  GH: 'Ghana',
  IN: 'India',
  PH: 'Philippines',
  PK: 'Pakistan',
  SG: 'Singapore',
  MY: 'Malaysia',
  BZ: 'Belize',
  JM: 'Jamaica',
  TT: 'Trinidad and Tobago',
  BB: 'Barbados',
  BS: 'Bahamas',
  ZW: 'Zimbabwe',
  BW: 'Botswana',
  NA: 'Namibia',
  FJ: 'Fiji',
  PG: 'Papua New Guinea',
  SL: 'Sierra Leone',
  GM: 'Gambia',
  LR: 'Liberia',
  MT: 'Malta',
  LC: 'Saint Lucia',
  VC: 'Saint Vincent and the Grenadines',
  GD: 'Grenada',
  GY: 'Guyana',
};

export const isEnglishSpeakingCountry = (data) => {
  if (!data) return false;
  return englishSpeakingCountries[data?.country] || englishSpeakingCountries[data?.countryCode]
    ? true
    : false;
};
