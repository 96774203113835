import React, { useState } from 'react';
import { TextField, MenuItem, Select } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useSelector } from 'react-redux';
import { format, addDays } from 'date-fns';
import { FlexContainer } from '@/components/containers/FlexContainer/FlexContainer';
import { Formik, Form, Field } from 'formik';
import { countryOptions, calculateHoursDifference } from '@/helpers/dateHelpers';
import { validationSchema } from './Validations/ValidationReservationSchema';
import dayjs from 'dayjs';
import Label from '@/components/form/Label/Label';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

require('dayjs/locale/es');
dayjs.locale('es');

const formatDateToWords = (date) => {
  try {
    const parsedDate = dayjs(date);
    return parsedDate.isValid()
      ? parsedDate.format('dddd D [de] MMMM [de] YYYY')
      : 'Por seleccionar';
  } catch {
    return 'Por seleccionar';
  }
};

const formatDateToWordsEnglish = (date) => {
  try {
    const parsedDate = dayjs(date);
    return parsedDate.isValid()
      ? parsedDate.locale('en').format('dddd, MMMM D, YYYY')
      : 'To be selected';
  } catch {
    return 'To be selected';
  }
};

const GuestDataForm = (props) => {
  const { onSubmitFunction, spaceDetail, lenguage } = props;
  const [showEditFormData, setShowEditFormData] = useState(false);
  const searchFormData = useSelector((state) => state.searchFormData);
  const spaceTypeFormData = searchFormData?.spaceType;
  const tomorrow = format(addDays(new Date(), 1), 'yyyy-MM-dd');

  const hoursToSelect = Array.from({ length: 27 }, (_, i) => {
    const hour = Math.floor(i / 2) + 6;
    const minutes = i % 2 === 0 ? '00' : '30';
    return `${String(hour).padStart(2, '0')}:${minutes}`;
  });

  console.log('ver los datos guardados', searchFormData);
  return (
    <Formik
      initialValues={{
        startDate: searchFormData?.startDate ? searchFormData?.startDate : tomorrow,
        startTime: searchFormData?.startTime ? searchFormData?.startTime : '09:00',
        endTime: searchFormData?.endTime ? searchFormData?.endTime : '10:00',
        name: '',
        email: '',
        phone: '',
        countryCode: lenguage === 'EN' ? '+1' : '+52',
        guestQuantyty: searchFormData?.guests ? searchFormData?.guests : 1,
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        console.log(values);
      }}
    >
      {({ setFieldValue, values, isValid, dirty, errors, touched }) => {
        const hours = calculateHoursDifference(values.startTime, values.endTime);
        const pricexTime =
          spaceTypeFormData === 'coworking' ? spaceDetail?.rate_24_hours : spaceDetail?.hourly_rate;
        const totalPrice = () => {
          if (spaceTypeFormData === 'coworking') {
            console.log('Ver valores: ', values);
            return pricexTime * (values.guestQuantyty || 1);
          } else if (spaceTypeFormData === 'sala') {
            console.log('Ver valores: ', values);
            return pricexTime * (hours > 0 ? hours : 0);
          } else {
            console.log('Ver valores: ', values);
            return pricexTime * (hours > 0 ? hours : 0);
          }
        };

        const dayLabel = lenguage === 'EN' ? ' day' : ' día';
        const hourLabel = lenguage === 'EN' ? ' hour' : ' hora';
        const timeLabel = spaceTypeFormData === 'coworking' ? dayLabel : hourLabel;
        const peopleLabelEn = values.guestQuantyty === 1 ? ' Person' : ' People';
        const peopleLabelEs = values.guestQuantyty === 1 ? ' Personas' : ' Persona';
        const guestLabel =
          lenguage === 'EN'
            ? 'People: ' + values.guestQuantyty + peopleLabelEn
            : 'Personas: ' + values.guestQuantyty + peopleLabelEs;
        const dateLabel =
          lenguage === 'EN'
            ? 'Date: ' + formatDateToWordsEnglish(values?.startDate)
            : 'Fecha: ' + formatDateToWords(values?.startDate);
        const startTime =
          lenguage === 'EN'
            ? 'Start time: ' + values?.startTime + ' hours'
            : 'Hora de inicio: ' + values?.startTime + ' horas';
        const endTime =
          lenguage === 'EN'
            ? 'End Time: ' + values?.endTime + ' hours'
            : 'Hora de Fin: ' + values?.endTime + ' horas';
        console.log('Errores:', errors);
        console.log('Campos tocados:', touched);

        const imagesArray = (() => {
          try {
            const parsed = JSON.parse(spaceDetail?.main_image);
            return Array.isArray(parsed) && parsed.length > 0
              ? parsed
              : [spaceDetail?.main_image].filter(Boolean);
          } catch {
            return [spaceDetail?.main_image].filter(Boolean);
          }
        })();

        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Form>
              <FlexContainer column className={'gap-20'}>
                {!showEditFormData && (
                  <>
                    <img src={imagesArray[0]} alt={''} className={'width100 d-hide'} />
                    <label className={'text-18 m-text-20 Subtitle-3'}>
                      {' '}
                      {lenguage === 'EN' ? 'Reservation details' : 'Datos de la reserva'}
                    </label>
                    <FlexContainer className={'justify-between'}>
                      <label className={'text-16 m-text-14 '}>{guestLabel} </label>
                      <button
                        type="button"
                        onClick={() => setShowEditFormData(true)}
                        className={'bgWhite fontGray250 borderNone padlr10 padtb4 text-12'}
                      >
                        {lenguage === 'ES' ? 'Editar' : 'Edit'} <i className="fa fa-pencil" />
                      </button>
                    </FlexContainer>
                    <FlexContainer className={'justify-between'}>
                      <label className={'text-16 m-text-14 '}>{dateLabel} </label>
                      <button
                        type="button"
                        onClick={() => setShowEditFormData(true)}
                        className={'bgWhite fontGray250 borderNone padlr10 padtb4 text-12'}
                      >
                        {lenguage === 'ES' ? 'Editar' : 'Edit'} <i className="fa fa-pencil" />
                      </button>
                    </FlexContainer>
                    <FlexContainer className={'justify-between'}>
                      <label className={'text-16 m-text-14 '}>{startTime} </label>
                      <button
                        type="button"
                        onClick={() => setShowEditFormData(true)}
                        className={'bgWhite fontGray250 borderNone padlr10 padtb4 text-12'}
                      >
                        {lenguage === 'ES' ? 'Editar' : 'Edit'} <i className="fa fa-pencil" />
                      </button>
                    </FlexContainer>
                    <FlexContainer className={'justify-between'}>
                      <label className={'text-16 m-text-14 '}>{endTime} </label>
                      <button
                        type="button"
                        onClick={() => setShowEditFormData(true)}
                        className={'bgWhite fontGray250 borderNone padlr10 padtb4 text-12'}
                      >
                        {lenguage === 'ES' ? 'Editar' : 'Edit'} <i className="fa fa-pencil" />
                      </button>
                    </FlexContainer>
                  </>
                )}
                {showEditFormData && (
                  <FlexContainer className={'flex-column gap-20'}>
                    <Field name="startDate">
                      {({ field, form }) => (
                        <DatePicker
                          label="Fecha de inicio de reservación"
                          value={values.startDate ? dayjs(values.startDate) : null}
                          minDate={dayjs()}
                          onChange={(newValue) => {
                            setFieldValue(
                              'startDate',
                              newValue ? dayjs(newValue).format('YYYY-MM-DD') : '',
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={Boolean(form.errors.startDate && form.touched.startDate)}
                              helperText={form.touched.startDate && form.errors.startDate}
                            />
                          )}
                        />
                      )}
                    </Field>
                    <Field name="guestQuantyty">
                      {({ field, form }) => (
                        <TextField
                          {...field}
                          label="Número de Personas"
                          variant="outlined"
                          type="number"
                          onChange={(e) => setFieldValue('guestQuantyty', e.target.value)}
                          error={Boolean(form.errors.guestQuantyty && form.touched.guestQuantyty)}
                          helperText={form.touched.guestQuantyty && form.errors.guestQuantyty}
                        />
                      )}
                    </Field>
                    <Field name="startTime">
                      {({ field, form }) => (
                        <div>
                          <TextField
                            select
                            fullWidth
                            label={lenguage === 'ES' ? 'Hora de entrada' : 'Check-in Time'}
                            variant="outlined"
                            value={form.values.startTime}
                            onChange={(e) => {
                              setFieldValue('startTime', e.target.value);
                              if (form.values.endTime && e.target.value >= form.values.endTime) {
                                setFieldValue('endTime', '');
                              }
                            }}
                            error={touched.startTime && Boolean(errors.startTime)}
                            helperText={touched.startTime && errors.startTime}
                          >
                            {hoursToSelect.map((hour) => (
                              <MenuItem key={hour} value={hour}>
                                {hour}
                              </MenuItem>
                            ))}
                          </TextField>
                          {form.errors.startTime && form.touched.startTime && (
                            <div className="text-red-600">{form.errors.startTime}</div>
                          )}
                        </div>
                      )}
                    </Field>
                    <Field name="endTime">
                      {({ field, form }) => (
                        <div>
                          <TextField
                            select
                            fullWidth
                            label={lenguage === 'ES' ? 'Hora de Salida' : 'Check-out Time'}
                            variant="outlined"
                            value={form.values.endTime}
                            onChange={(e) => setFieldValue('endTime', e.target.value)}
                            error={touched.endTime && Boolean(errors.endTime)}
                            helperText={touched.endTime && errors.endTime}
                          >
                            {hoursToSelect
                              .filter((hour) => {
                                if (!form.values.startTime) return true;
                                const [startHour, startMinutes] = form.values.startTime
                                  .split(':')
                                  .map(Number);
                                const [endHour, endMinutes] = hour.split(':').map(Number);
                                return (
                                  endHour * 60 + endMinutes >= startHour * 60 + startMinutes + 60
                                );
                              })
                              .map((hour) => (
                                <MenuItem key={hour} value={hour}>
                                  {hour}
                                </MenuItem>
                              ))}
                          </TextField>
                          {form.errors.endTime && form.touched.endTime && (
                            <div className="text-red-600">{form.errors.endTime}</div>
                          )}
                        </div>
                      )}
                    </Field>
                    <button
                      type="button"
                      onClick={() => setShowEditFormData(false)}
                      className={
                        'bgWhite fontGray250 width100 bold border-rad8px borderGray050 grayShadow16 margtb10 padlr40 padtb20 text-12'
                      }
                    >
                      {lenguage === 'ES' ? 'Terminar de editar' : 'Finish editing'}{' '}
                      <i className="fa fa-pencil" />
                    </button>
                  </FlexContainer>
                )}
                <label className={'text-18 m-text-20 Subtitle-3 margt10'}>
                  {lenguage === 'ES'
                    ? 'Datos del titular de la reserva'
                    : "Reservation Holder's Data"}
                </label>
                <Field name="name">
                  {({ field, form }) => (
                    <TextField
                      {...field}
                      label={lenguage === 'EN' ? 'Full Name' : 'Nombre Completo'}
                      variant="outlined"
                      error={Boolean(form.errors.name && form.touched.name)}
                      helperText={form.touched.name && form.errors.name}
                    />
                  )}
                </Field>
                <Field name="email">
                  {({ field, form }) => (
                    <TextField
                      {...field}
                      label="Email"
                      variant="outlined"
                      error={Boolean(form.errors.email && form.touched.email)}
                      helperText={form.touched.email && form.errors.email}
                    />
                  )}
                </Field>
                <Field name="countryCode">
                  {({ field }) => (
                    <Select
                      {...field}
                      label={lenguage === 'EN' ? 'Country code' : 'Código de país'}
                      variant="outlined"
                      onChange={(e) => setFieldValue('countryCode', e.target.value)}
                    >
                      {countryOptions.map((option) => (
                        <MenuItem key={option.code} value={option.code}>
                          {option.code} ({option.country})
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </Field>
                <Field name="phone">
                  {({ field, form }) => (
                    <TextField
                      {...field}
                      label={lenguage === 'EN' ? 'Phone' : 'Teléfono'}
                      variant="outlined"
                      error={Boolean(form.errors.phone && form.touched.phone)}
                      helperText={form.touched.phone && form.errors.phone}
                    />
                  )}
                </Field>
                {!isValid && dirty && (
                  <Label type="Subtitle-1" className={'fontRed100 margb10 text-10 text-center'}>
                    {lenguage === 'ES'
                      ? 'Por favor, revisa los campos. Algunos contienen errores o están incompletos.'
                      : 'Please check the fields. Some contain errors or are incomplete.'}
                  </Label>
                )}
                <label className={'text-18 m-text-20 Subtitle-3 margt10'}>
                  {lenguage === 'ES' ? 'Información del precio' : 'Price Information'}
                </label>
                <FlexContainer className={'justify-between'}>
                  <label className={'text-16 m-text-14 '}>
                    {' '}
                    {lenguage === 'ES' ? 'Precio por:' : 'Price per'} {timeLabel}:
                  </label>
                  <label className={'text-16 m-text-14 '}>
                    <strong>${pricexTime} </strong>
                  </label>
                </FlexContainer>
                {spaceTypeFormData !== 'coworking' && (
                  <FlexContainer className={'justify-between'}>
                    <label className={'text-16 m-text-14 '}>
                      {' '}
                      {lenguage === 'ES' ? 'Tiempo:' : 'Time'}
                    </label>
                    {lenguage === 'EN' && (
                      <label className={'text-16 m-text-14 '}>
                        <strong>
                          {hours}
                          {hours === 1 ? ' hour' : ' hours'}{' '}
                        </strong>
                      </label>
                    )}
                    {lenguage === 'ES' && (
                      <label className={'text-16 m-text-14 '}>
                        <strong>
                          {hours}
                          {hours === 1 ? ' hora' : ' houras'}{' '}
                        </strong>
                      </label>
                    )}
                  </FlexContainer>
                )}
                {spaceTypeFormData === 'coworking' && (
                  <FlexContainer className={'justify-between'}>
                    <label className={'text-16 m-text-14 '}>
                      {lenguage === 'ES' ? 'Cantidad de personas:' : 'Number of People:'}
                    </label>

                    <label className={'text-16 m-text-14 '}>
                      {values.guestQuantyty > 1 && (
                        <strong>
                          {values.guestQuantyty}
                          {lenguage === 'ES' ? ' personas' : ' seats'}{' '}
                        </strong>
                      )}
                      {values.guestQuantyty == 1 && (
                        <strong>
                          {'x'}
                          {values.guestQuantyty}
                          {lenguage === 'ES' ? ' persona' : ' seat'}{' '}
                        </strong>
                      )}
                    </label>
                  </FlexContainer>
                )}
                {spaceTypeFormData === 'coworking' && (
                  <FlexContainer className={'text-12'}>
                    {lenguage === 'ES'
                      ? 'El precio de renta de un pase de coworking no se calculará en función a la cantidad de horas seleccionadas, la reserva puede hacerse efectiva a lo largo del día seleccionado.'
                      : 'The rental price of a coworking pass will not be calculated based on the number of selected hours; the reservation can be used at any time throughout the selected day.'}
                  </FlexContainer>
                )}
                {spaceTypeFormData !== 'coworking' && (
                  <FlexContainer className={'text-12'}>
                    {lenguage === 'ES'
                      ? 'El precio de renta de una sala de juntas o terraza se calculará en función a la cantidad de horas seleccionadas.'
                      : 'The rental price is calculated based on the number of hours selected.'}
                  </FlexContainer>
                )}

                <FlexContainer
                  className={'justify-between borderGray100 justify-between padlr10 padtb10'}
                >
                  <label className={'text-16 m-text-14'}>
                    <strong>Total</strong>
                  </label>
                  <label>
                    <strong className={'text-20 m-text-18'}>${totalPrice().toFixed(2)}</strong>
                  </label>
                </FlexContainer>
              </FlexContainer>

              <button
                onClick={() => onSubmitFunction(values)}
                type="submit"
                className={
                  'bgBlue400 width100 border-rad24px borderGray050 fontWhite grayShadow16 margtb30 padlr40 padtb10 text-18'
                }
                disabled={!isValid || !dirty}
              >
                <strong>{lenguage === 'ES' ? 'Pagar' : 'Pay'} </strong>
              </button>
            </Form>
          </LocalizationProvider>
        );
      }}
    </Formik>
  );
};

const ReservationForm = (props) => {
  const { className, onSubmitFunction, spaceDetail } = props;
  const lenguage = useSelector((state) => state.lenguage?.lang);
  const containerClass = className + ' flex-column border-rad16px margt4 margb20';
  return (
    <FlexContainer className={containerClass}>
      <FlexContainer className={'m-flex-column width100 gap-20'}>
        <FlexContainer className={'flex-column width100'}>
          <Label type="width100 text-18 m-text-20 Subtitle-3">
            {lenguage === 'ES' ? '¡Casi todo listo!' : 'Almost ready!'}
          </Label>
          <Label type="width100 padtb20 text-16">
            {lenguage === 'ES'
              ? 'Verifica la información de tu reserva y completa los datos.'
              : 'Verify your booking information and complete the details.'}
          </Label>
          <GuestDataForm
            onSubmitFunction={onSubmitFunction}
            spaceDetail={spaceDetail}
            lenguage={lenguage}
          />
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};

export default ReservationForm;
