import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FlexContainer } from '@/components/containers/FlexContainer/FlexContainer';
import SpacesList from '@/components/modules/SpacesList/SpacesList';
import SearchAccordion from '@/components/general/SearchAccordion/SearchAccordion';
import HelmetComponent from '@/components/general/Helmet/HelmetComponent';
import './AboutView.scss';

const About = (props) => {
  const { spacesDataFromBackend } = props;
  const [coworkingSpacesList, setCoworkingSpacesList] = useState(
    spacesDataFromBackend?.filter((space) => space.subtype_title === 'Coworking').slice(0, 4),
  );
  const [meetingRoomSpacesList, setMeetingRoomSpacesList] = useState(
    spacesDataFromBackend?.filter((space) => space.subtype_title === 'Sala de Juntas').slice(0, 4),
  );
  const [isShowButtonSeeAllCoworkings, setIsShowButtonSeeAllCoworkings] = useState(true);
  const [isShowButtonSeeAllMeetingRooms, setIsShowButtonSeeAllMeetingRooms] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const languageData = useSelector((state) => state.lenguage?.lang);

  const esDescription = 'Esta es la lista de espacios disponibles en Popnest.';
  const enDescription = 'This is the list of available spaces on Popnest.';

  const seeAllCoworkings = () => {
    setCoworkingSpacesList(
      spacesDataFromBackend?.filter((space) => space.subtype_title === 'Coworking'),
    );
    setIsShowButtonSeeAllCoworkings(false);
  };

  const seeMeetingRooms = () => {
    setMeetingRoomSpacesList(
      spacesDataFromBackend?.filter((space) => space.subtype_title === 'Sala de Juntas'),
    );
    setIsShowButtonSeeAllMeetingRooms(false);
  };

  return (
    <FlexContainer className={'flex-column width100'}>
      <HelmetComponent
        title={languageData === 'EN' ? 'Spaces - Popnest' : 'Espacios - Popnest'}
        description={languageData === 'EN' ? enDescription : esDescription}
        graphTitle={languageData === 'EN' ? 'Spaces - Popnest' : 'Espacios - Popnest'}
        graphDescription={languageData === 'EN' ? enDescription : esDescription}
      />
      {/*<SearchAccordion className={'margt70 width100'} /> */}
      <FlexContainer className={'aboutView flex-column margt70'}>
        <SpacesList
          spacesData={coworkingSpacesList}
          customTitle={'Coworkings'}
          buttonTitle={languageData === 'EN' ? 'See All' : 'Ver más espacios'}
          buttonMethod={() => seeAllCoworkings()}
          isShowButton={isShowButtonSeeAllCoworkings}
        />
        {spacesDataFromBackend
          ?.filter((space) => space.subtype_title === 'Sala de Juntas')
          .slice(0, 4) && (
          <SpacesList
            spacesData={meetingRoomSpacesList}
            customTitle={'Sala de Juntas'}
            buttonTitle={languageData === 'EN' ? 'See All' : 'Ver más espacios'}
            buttonMethod={() => seeMeetingRooms()}
            isShowButton={isShowButtonSeeAllMeetingRooms}
          />
        )}
      </FlexContainer>
    </FlexContainer>
  );
};

export default About;
