import React, { useState, useEffect } from 'react';
import { FlexContainer } from '@/components/containers/FlexContainer/FlexContainer';
import Label from '@/components/form/Label/Label';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { loadStripe } from '@stripe/stripe-js';
import { PaymentElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Add, Remove } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import HelmetComponent from '@/components/general/Helmet/HelmetComponent';
import * as Yup from 'yup';
import './ProductDetailsLandingPage.scss';

const MapSection = (props) => {
  const { language, jsonSpace } = props;
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: googleApiKey,
  });
  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const center = {
    lat: jsonSpace?.coordinates?.lat,
    lng: jsonSpace?.coordinates?.lng,
  };

  const containerStyle = {
    width: '100%',
    height: '300px',
  };

  const mapStyles = [
    {
      featureType: 'all',
      elementType: 'geometry',
      stylers: [{ saturation: -30 }],
    },
    {
      featureType: 'landscape',
      elementType: 'geometry',
      stylers: [{ color: '#f5f3e5' }],
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [{ color: '#e0e0e0' }],
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [{ color: '#d9e3f0' }],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [{ color: '#e5e9d3' }],
    },
    {
      featureType: 'poi',
      elementType: 'labels.icon',
      stylers: [{ color: '#6c75f0' }],
    },
    {
      featureType: 'all',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#6c75f0' }],
    },
    {
      featureType: 'administrative.locality',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#808080' }],
    },
    {
      featureType: 'administrative.province',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#808080' }],
    },
  ];

  return isLoaded ? (
    <FlexContainer className={'flex-column margtb30 padlr80 m-padlr10 gap-20'}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={13}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={{
          styles: mapStyles,
        }}
      >
        <Marker
          key={4}
          position={{ lat: 19.4289183, lng: -99.2003243 }}
          //icon={customIcon}
          onClick={() => console.log(`Clicked marker at `)}
        />
      </GoogleMap>
      <Label
        className={'m-text-left text-center text-20 m-text-16 margtb10 padt20 padlr8 borderTp'}
      >
        <strong>{language === 'EN' ? 'Price' : 'Precio'}</strong>
      </Label>
      {jsonSpace.spaceCode === 10 && (
        <Label className={'m-text-left text-center text-20 m-text-16 padlr8'}>
          {' '}
          {language === 'EN'
            ? '$' + jsonSpace?.price + ' for 1 meeting room for 14 people'
            : '$' + jsonSpace?.price + ' por 1 sala de juntas para 14 personas'}
        </Label>
      )}
      {jsonSpace.spaceCode !== 10 && (
        <Label className={'m-text-left text-center text-20 m-text-16 padlr8'}>
          {' '}
          {language === 'EN'
            ? '$' + jsonSpace?.price + ' for 1 coworking pass for 1 person'
            : '$' + jsonSpace?.price + ' por 1 pase de coworking para 1 persona'}
        </Label>
      )}
      {jsonSpace.spaceCode === 10 && (
        <Label className={'m-text-left text-center text-20 m-text-16 padlr8 borderTp padt20'}>
          {language === 'EN'
            ? 'The meeting room pass is valid for use within the next 90 days. Cancel within the next 7 days for a 50% refund.'
            : 'El pase a la sala de juntas es válido para uso en los próximos 90 días. Cancela en los próximos 7 días para un reembolso del 50%.'}
        </Label>
      )}
      {jsonSpace.spaceCode !== 10 && (
        <Label className={'m-text-left text-center text-20 m-text-16 padlr8 borderTp padt20'}>
          {language === 'EN'
            ? 'The coworking pass is valid for use within the next 90 days. Cancel within the next 7 days for a 50% refund.'
            : 'El pase de coworking es válido para uso en los próximos 90 días. Cancela en los próximos 7 días para un reembolso del 50%.'}
        </Label>
      )}
    </FlexContainer>
  ) : (
    <></>
  );
};

//////////////////////////////
const apiUrl = process.env.REACT_APP_API_URL;

const stripePromise = loadStripe(
  'pk_live_51MuKSIA0gqRxLoT3YHraiqMUanidK11hZZB8vptnBPNDPQUwEWNfKx8yMDbONCyPA5BHHjGryNJYEp2BZzX1m67k00ZbJdQDlT',
);

const CheckoutForm = (props) => {
  const { spacePrice, formData, spaceToBuy, language } = props;
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoadingCheckout, setIsLoadingCheckout] = useState(false);

  const handleSubmit = async (event) => {
    setIsLoadingCheckout(true);
    event.preventDefault();
    if (elements == null) {
      return;
    }

    const { error: submitError } = await elements.submit();
    if (submitError) {
      setErrorMessage(submitError.message);
      return;
    }

    const amount = Math.round(parseFloat(spacePrice) * 100);
    //const currency = 'usd';
    const currency = 'mxn';

    const reservation = {
      host_id: 1,
      guest_id: 2,
      space_subtype_relationship: spaceToBuy,
      start_date: '2024-12-01',
      finish_date: '2024-12-05',
      initial_price: spacePrice,
      final_price: spacePrice,
      id_state: 1,
      payment_method: 1,
      method_status: 'p',
      method_details: 'Credit Card',
      isgift: 1,
      message: '',
      gifter: '',
      beneficiary: formData?.name + ' ' + formData?.lastname,
      gifter_mail: '',
      beneficiary_mail: formData?.email,
      gifter_phone: '',
      beneficiary_phone: formData?.phone,
    };

    const res = await fetch(`${apiUrl}/POST/checkout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        amount,
        currency,
        reservation: reservation,
      }),
    });

    const { client_secret, createdReservation } = await res.json();
    console.log('ver secret', client_secret);
    console.log('ver reservacion', createdReservation);

    const languageString = language === 'EN' ? '&lang=EN' : '';

    const { error } = await stripe.confirmPayment({
      elements,
      clientSecret: client_secret,
      confirmParams: {
        /*
        return_url:
          'http://localhost:3000/reserva-confirmada?transaction_id=' +
          createdReservation?.transaction_id,
          */
        //return_url: 'https://popnest.org/reservationSuccess',
        return_url:
          'https://popnest.org/reserva-confirmada?transaction_id=' +
          createdReservation?.transaction_id +
          languageString,
      },
    });

    if (error) {
      setErrorMessage(error.message);
      setIsLoadingCheckout(false);
    } else {
      console.log('Pago exitoso');
      setIsLoadingCheckout(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      {!isLoadingCheckout && (
        <button
          type="submit"
          className={
            'bgBlue400 width100 border-rad100px borderGray050 fontWhite grayShadow16 margtb20 padlr40 padtb20 text-20'
          }
          disabled={!stripe || !elements}
        >
          <strong> {language === 'EN' ? 'Pay' : 'Pagar aquí'}</strong>
        </button>
      )}
      {isLoadingCheckout && <Label className={'margtb10'}>Cargando...</Label>}
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
};

const SpaceDetailForm = (props) => {
  const { spaceName, spacePrice, setShowCheckout, setFormData, language, jsonSpace } = props;
  const validationSchema = Yup.object({
    email: Yup.string()
      .email(language === 'EN' ? 'Invalid email' : 'Correo inválido')
      .required(language === 'EN' ? 'Email is required' : 'El correo es obligatorio'),

    name: Yup.string().required(
      language === 'EN' ? 'Name is required' : 'El nombre es obligatorio',
    ),

    lastname: Yup.string().required(
      language === 'EN' ? 'Last name is required' : 'El apellido es obligatorio',
    ),

    phone: Yup.string().required(
      language === 'EN'
        ? 'Recipient phone number is required'
        : 'El teléfono del destinatario es obligatorio',
    ),

    hoursq: Yup.string().required(
      language === 'EN'
        ? 'Recipient hours quantity is required'
        : 'El campo de cantidad de horas es obligatorio',
    ),
  });

  // Estado inicial del formulario
  const initialValues = {
    email: '',
    name: '',
    lastname: '',
    phone: '',
    hoursq: 1,
  };

  const onSubmit = (values) => {
    console.log('Datos enviados:', values);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
    const spaceName =
      jsonSpace?.spaceCode === 7 || jsonSpace?.spaceCode === 10 ? 'polanco' : 'condesa';
    const urlToForm =
      language === 'EN' ? '/payment-form-' + spaceName : '/formulario-pago-' + spaceName;
    window.history.replaceState(null, '', urlToForm);
    setShowCheckout(true);
    setFormData(values);
  };

  const bannerUrl =
    jsonSpace?.spaceCode === 7 || jsonSpace?.spaceCode === 10
      ? 'url(./images/LandingFondo.png)'
      : 'url(./images/B-LandingFondo.png)';
  return (
    <FlexContainer
      className={'m-flex-column width100 border-rad16px bgWhite justify-center margt70'}
    >
      <FlexContainer className={'flex-column height100 padlr10 margtb20 text-center'}>
        <FlexContainer className={'width100 margtb20 gap-20'}>
          <FlexContainer
            className={'format20 m-format100 border-rad24px'}
            style={{
              backgroundImage: bannerUrl,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              height: '100px',
              //display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          />
          <FlexContainer className={'width100 flex-column justify-center text-left'}>
            {jsonSpace?.spaceCode !== 10 && (
              <Label className={'text-18'}>
                <strong> Coworking {spaceName}</strong>
              </Label>
            )}
            {jsonSpace?.spaceCode === 10 && (
              <Label className={'text-18'}>
                <strong>
                  {' '}
                  {language === 'EN' ? 'Meeting Room' : 'Sala de Juntas'} {spaceName}
                </strong>
              </Label>
            )}
            {jsonSpace?.spaceCode !== 10 && (
              <Label className={'text-18 margt8'}>
                {language === 'EN' ? 'Coworking Pass' : 'Pase de coworking'}
              </Label>
            )}
            {jsonSpace?.spaceCode === 10 && (
              <Label className={'text-18 margt8'}>
                {language === 'EN' ? 'Meeting Room' : 'Sala de Juntas'}
              </Label>
            )}
          </FlexContainer>
        </FlexContainer>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form>
              <FlexContainer className={'m-flex-column width100 gap-20'}>
                <FlexContainer column className={'gap-20 format40 m-format100'}>
                  <Field name="name">
                    {({ field, form }) => (
                      <TextField
                        {...field}
                        label={language === 'EN' ? 'Name' : 'Nombre'}
                        variant="outlined"
                        size="small"
                        onChange={(e) => setFieldValue('name', e.target.value)}
                        error={Boolean(form.errors.name && form.touched.name)}
                        helperText={form.touched.name && form.errors.name}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '60px',
                          },
                        }}
                      />
                    )}
                  </Field>
                  <Field name="lastname">
                    {({ field, form }) => (
                      <TextField
                        {...field}
                        label={language === 'EN' ? 'Last Name' : 'Apellido'}
                        variant="outlined"
                        size="small"
                        onChange={(e) => setFieldValue('lastname', e.target.value)}
                        error={Boolean(form.errors.lastname && form.touched.lastname)}
                        helperText={form.touched.lastname && form.errors.lastname}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '60px',
                          },
                        }}
                      />
                    )}
                  </Field>
                  <Field name="email">
                    {({ field, form }) => (
                      <TextField
                        {...field}
                        label="Email"
                        variant="outlined"
                        size="small"
                        onChange={(e) => setFieldValue('email', e.target.value)}
                        error={Boolean(form.errors.email && form.touched.email)}
                        helperText={form.touched.email && form.errors.email}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '60px',
                          },
                        }}
                      />
                    )}
                  </Field>
                  <Field name="phone">
                    {({ field, form }) => (
                      <TextField
                        {...field}
                        label={language === 'EN' ? 'Phone' : 'Teléfono'}
                        variant="outlined"
                        size="small"
                        onChange={(e) => setFieldValue('phone', e.target.value)}
                        error={Boolean(form.errors.phone && form.touched.phone)}
                        helperText={form.touched.phone && form.errors.phone}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '60px',
                          },
                        }}
                      />
                    )}
                  </Field>
                  {jsonSpace?.spaceCode === 10 && (
                    <Field name="hoursq">
                      {({ field, form }) => (
                        <TextField
                          {...field}
                          label={language === 'EN' ? 'Hours Quantity' : 'Cantidad de horas'}
                          variant="outlined"
                          size="small"
                          type="number"
                          onChange={(e) => form.setFieldValue('hoursq', e.target.value)}
                          error={Boolean(form.errors.hoursq && form.touched.hoursq)}
                          helperText={form.touched.hoursq && form.errors.hoursq}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              borderRadius: '60px',
                            },
                            '& .MuiInputBase-input': {
                              textAlign: 'center',
                            },
                          }}
                          InputProps={{
                            readOnly: true,
                            startAdornment: (
                              <InputAdornment position="start">
                                <IconButton
                                  onClick={() =>
                                    form.setFieldValue(
                                      'hoursq',
                                      Math.max(1, (field.value || 1) - 1),
                                    )
                                  }
                                  size="small"
                                >
                                  <Remove />
                                </IconButton>
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() =>
                                    form.setFieldValue(
                                      'hoursq',
                                      Math.min(8, (field.value || 1) + 1),
                                    )
                                  }
                                  size="small"
                                  disabled={field.value >= 8}
                                >
                                  <Add />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    </Field>
                  )}
                </FlexContainer>
                <FlexContainer column className={'gap-20 width100'}>
                  {jsonSpace?.spaceCode === 10 && (
                    <Label className={'text-left text-20 m-text-16 margb10 padlr8'}>
                      <strong>{language === 'EN' ? 'Meeting Room' : 'Sala de Juntas'}</strong>
                    </Label>
                  )}
                  {jsonSpace?.spaceCode !== 10 && (
                    <Label className={'text-left text-20 m-text-16 margb10 padlr8'}>
                      <strong>{language === 'EN' ? 'Coworking Pass' : 'Pase de Coworking'}</strong>
                    </Label>
                  )}
                  <Label className={'text-left text-20 m-text-16 padlr8'}>
                    {language === 'EN'
                      ? 'Date - To be defined (The team will contact you to make your reservation)'
                      : 'Fecha - Por Definir (El equipo te contactará para realizar tu reservación)'}
                  </Label>
                  <Label className={'text-left text-20 m-text-16 padlr8'}>
                    {language === 'EN' ? 'Hours: 9-7pm' : 'Horario: 9-7pm'}
                  </Label>
                  <Label className={'text-left text-20 m-text-16 padlr8 margb20'}>
                    {language === 'EN'
                      ? 'Location: ' + jsonSpace?.location
                      : ' Ubicación: ' + jsonSpace?.location}
                  </Label>
                </FlexContainer>
              </FlexContainer>
              <MapSection language={language} jsonSpace={jsonSpace} />
              <FlexContainer className={'gap-20 parlr20 width100 justify-center margt10'}>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={
                    'bgBlue400 border-rad100px borderGray050 fontWhite grayShadow16 margtb20 padlr120 m-padlr60 padtb20 text-20 m-text-16'
                  }
                >
                  <strong> {language === 'EN' ? 'Confirm and Pay' : 'Confirmar y Pagar'}</strong>
                </button>
              </FlexContainer>
            </Form>
          )}
        </Formik>
      </FlexContainer>
    </FlexContainer>
  );
};

const CheckoutFormComponent = (props) => {
  const { spaceName, spacePrice, formData, spaceToBuy, language, jsonSpace } = props;
  const options = {
    mode: 'payment',
    amount: Math.round(spacePrice * 100),
    //currency: 'usd',
    currency: 'mxn',
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  };
  return (
    <FlexContainer className={'m-flex-column border-rad16px bgWhite margt70 padlr100 m-padlr10'}>
      <FlexContainer className={'flex-column height100 format100 m-format95 padlr10 margtb20'}>
        <Label className="width100 padtb10 text-26 m-padlr0">
          {jsonSpace.spaceCode === 10 && (
            <strong>
              {' '}
              {language === 'EN' ? 'Meeting Room' : 'Sala de juntas'} - {spaceName}
            </strong>
          )}
          {jsonSpace.spaceCode !== 10 && (
            <strong>
              {' '}
              {language === 'EN' ? 'Coworking Pass' : 'Pase de coworking'} - {spaceName}
            </strong>
          )}
        </Label>
        <Elements stripe={stripePromise} options={options}>
          <FlexContainer className="padtb10 bgGray025 margt6" />
          <Label className={'margt10 text-22 m-text-20 margb20'}>
            {language === 'EN' ? 'Total price to pay: ' : 'Precio total a pagar: '}
            <strong>${(spacePrice * formData?.hoursq).toFixed(2)} MXN</strong>
          </Label>
          <CheckoutForm
            spacePrice={spacePrice * formData?.hoursq}
            formData={formData}
            spaceToBuy={spaceToBuy}
            language={language}
          />
        </Elements>
      </FlexContainer>
      <FlexContainer className={'height100 format100 m-format90 padlr10'}>
        <FlexContainer className={'flex-column width100'}>
          <FlexContainer className="width100 margt60 m-margt0 padtb10 text-20 m-padlr0">
            <strong>
              {' '}
              {language === 'EN' ? 'Reservation details:' : 'Datos de la reservación:'}
            </strong>
          </FlexContainer>
          <FlexContainer className="width100 margt10 padtb10 text-20 m-padlr0 justify-between">
            <Label>
              <strong>{language === 'EN' ? 'Quantity:' : 'Cantidad:'}</strong>
            </Label>
            {jsonSpace.spaceCode !== 10 && (
              <Label>
                <strong>{language === 'EN' ? '1 coworking pass' : '1 pase de coworking'}</strong>
              </Label>
            )}
            {jsonSpace.spaceCode === 10 && (
              <Label>
                <strong>
                  {language === 'EN'
                    ? '1 meeting room for 14 people for 1 hour'
                    : '1 sala de juntas para 14 personas por 1 hora'}
                </strong>
              </Label>
            )}
          </FlexContainer>
          <FlexContainer className="width100 margt10 padtb10 text-20 m-padlr0 justify-between">
            <Label>
              <strong>Subtotal:</strong>
            </Label>
            <Label>
              <strong>${(spacePrice * formData?.hoursq).toFixed(2)} MXN </strong>
            </Label>
          </FlexContainer>
          <FlexContainer className="width100 margt10 margb40 padtb10 text-20 m-padlr0 justify-between">
            <Label>
              <strong>Total:</strong>
            </Label>
            <Label>
              <strong>${(spacePrice * formData?.hoursq).toFixed(2)} MXN </strong>
            </Label>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};
/////////////////////////////

const googleApiKey = 'AIzaSyC75q_cO4SIMGhQgRJwAIpNfNQ5cXwVXnU';

const ReservationButton = (props) => {
  const { extraClass, setShowForm, language } = props;
  return (
    <button
      onClick={() => setShowForm()}
      className={
        'fontWhite bgNeutral400 border-rad18px padlr20 padtb10 borderNone margb20 text-20 ' +
        extraClass
      }
    >
      <strong>{language === 'EN' ? 'Reserve' : 'Reservar'}</strong>
    </button>
  );
};

const ProductDetailsLandingPageBanner = (props) => {
  const { mobile, setShowForm, language, jsonSpace } = props;
  const extraClass = mobile ? 'd-hide' : 'm-hide t-hide';
  const customHeight = mobile ? '40vh' : '80vh';
  return (
    <FlexContainer
      className={'margt70 m-margt70 ' + extraClass}
      style={{
        backgroundImage:
          jsonSpace?.spaceCode === 7 || jsonSpace?.spaceCode === 10
            ? `url(./images/LandingFondo.png)`
            : `url(./images/B-LandingFondo.png)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: customHeight,
        //display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <FlexContainer className={'flex-column width100 justify-end margtb30 gap-40'}>
        <Label className={'fontWhite margtb20 padlr8 text-60 m-text-40 text-right'}>
          {language === 'EN' ? jsonSpace?.title?.EN : jsonSpace?.title?.ES}
        </Label>
        <FlexContainer className={'width100 justify-end padb10'}>
          <ReservationButton
            extraClass={'m-marglr10'}
            setShowForm={setShowForm}
            language={language}
          />
        </FlexContainer>
      </FlexContainer>
      <FlexContainer className={'width100 justify-center margtb30 m-hide'}></FlexContainer>
    </FlexContainer>
  );
};

const IndividuaSpec = (props) => {
  const { title, description, borderBottom } = props;
  const extraClass = borderBottom ? ' borderBottom' : '';
  return (
    <FlexContainer className={'flex-column gap-10 padtb20' + extraClass}>
      <Label className={'text-14'}>
        <strong>{title}</strong>
      </Label>
      <Label className={'text-18'}>
        <strong>{description}</strong>
      </Label>
    </FlexContainer>
  );
};

const ProductSpcecsDesktop = (props) => {
  const { language, jsonSpace } = props;
  const arraySpecs = jsonSpace?.specs;
  return (
    <FlexContainer className={'format60 bgYellow025 margtb60 gap-10 m-hide t-hide'}>
      <FlexContainer className={'flex-column width100 bgYellow025 borderRight1 padlr20'}>
        <IndividuaSpec
          title={language === 'EN' ? arraySpecs[6]?.title?.EN : arraySpecs[6]?.title?.ES}
          description={
            language === 'EN' ? arraySpecs[6]?.description?.EN : arraySpecs[6]?.description?.ES
          }
          borderBottom
        />

        <IndividuaSpec
          title={language === 'EN' ? arraySpecs[1]?.title?.EN : arraySpecs[1]?.title?.ES}
          description={
            language === 'EN' ? arraySpecs[1]?.description?.EN : arraySpecs[1]?.description?.ES
          }
          borderBottom
        />
        <IndividuaSpec
          title={language === 'EN' ? arraySpecs[2]?.title?.EN : arraySpecs[2]?.title?.ES}
          description={
            language === 'EN' ? arraySpecs[2]?.description?.EN : arraySpecs[2]?.description?.ES
          }
        />
      </FlexContainer>
      <FlexContainer className={'flex-column width100 bgYellow025 borderRight1 padlr20'}>
        <IndividuaSpec
          title={language === 'EN' ? arraySpecs[3]?.title?.EN : arraySpecs[3]?.title?.ES}
          description={
            language === 'EN' ? arraySpecs[3]?.description?.EN : arraySpecs[3]?.description?.ES
          }
          borderBottom
        />
        <IndividuaSpec
          title={language === 'EN' ? arraySpecs[4]?.title?.EN : arraySpecs[4]?.title?.ES}
          description={
            language === 'EN' ? arraySpecs[4]?.description?.EN : arraySpecs[4]?.description?.ES
          }
          borderBottom
        />
        <IndividuaSpec
          title={language === 'EN' ? arraySpecs[5]?.title?.EN : arraySpecs[5]?.title?.ES}
          description={
            language === 'EN' ? arraySpecs[5]?.description?.EN : arraySpecs[5]?.description?.ES
          }
        />
      </FlexContainer>
      <FlexContainer className={'flex-column width100 bgYellow025 padlr20'}>
        <IndividuaSpec
          title={language === 'EN' ? arraySpecs[0]?.title?.EN : arraySpecs[0]?.title?.ES}
          description={
            language === 'EN' ? arraySpecs[0]?.description?.EN : arraySpecs[0]?.description?.ES
          }
          borderBottom
        />
        <IndividuaSpec
          title={language === 'EN' ? arraySpecs[7]?.title?.EN : arraySpecs[7]?.title?.ES}
          description={
            language === 'EN' ? arraySpecs[7]?.description?.EN : arraySpecs[7]?.description?.ES
          }
          borderBottom
        />
        <IndividuaSpec
          title={language === 'EN' ? arraySpecs[8]?.title?.EN : arraySpecs[8]?.title?.ES}
          description={
            language === 'EN' ? arraySpecs[8]?.description?.EN : arraySpecs[8]?.description?.ES
          }
        />
      </FlexContainer>
    </FlexContainer>
  );
};

const ProductSpcecsMobile = (props) => {
  const { language, jsonSpace } = props;
  const arraySpecs = jsonSpace?.specs;
  return (
    <FlexContainer className={'width100 bgYellow025 margtb20 d-hide'}>
      <FlexContainer className={'flex-column width100 bgYellow025 padlr20 borderRight1'}>
        <IndividuaSpec
          title={language === 'EN' ? arraySpecs[6]?.title?.EN : arraySpecs[6]?.title?.ES}
          description={
            language === 'EN' ? arraySpecs[6]?.description?.EN : arraySpecs[6]?.description?.ES
          }
          borderBottom
        />
        <IndividuaSpec
          title={language === 'EN' ? arraySpecs[2]?.title?.EN : arraySpecs[2]?.title?.ES}
          description={
            language === 'EN' ? arraySpecs[2]?.description?.EN : arraySpecs[2]?.description?.ES
          }
          borderBottom
        />
        <IndividuaSpec
          title={language === 'EN' ? arraySpecs[7]?.title?.EN : arraySpecs[7]?.title?.ES}
          description={
            language === 'EN' ? arraySpecs[7]?.description?.EN : arraySpecs[7]?.description?.ES
          }
          borderBottom
        />
        <IndividuaSpec
          title={language === 'EN' ? arraySpecs[8]?.title?.EN : arraySpecs[8]?.title?.ES}
          description={
            language === 'EN' ? arraySpecs[8]?.description?.EN : arraySpecs[8]?.description?.ES
          }
        />
      </FlexContainer>
      <FlexContainer className={'flex-column width100 bgYellow025 padlr20'}>
        <IndividuaSpec
          title={language === 'EN' ? arraySpecs[3]?.title?.EN : arraySpecs[3]?.title?.ES}
          description={
            language === 'EN' ? arraySpecs[3]?.description?.EN : arraySpecs[3]?.description?.ES
          }
          borderBottom
        />
        <IndividuaSpec
          title={language === 'EN' ? arraySpecs[4]?.title?.EN : arraySpecs[4]?.title?.ES}
          description={
            language === 'EN' ? arraySpecs[4]?.description?.EN : arraySpecs[4]?.description?.ES
          }
          borderBottom
        />
        <IndividuaSpec
          title={language === 'EN' ? arraySpecs[5]?.title?.EN : arraySpecs[5]?.title?.ES}
          description={
            language === 'EN' ? arraySpecs[5]?.description?.EN : arraySpecs[5]?.description?.ES
          }
          borderBottom
        />
      </FlexContainer>
    </FlexContainer>
  );
};

const ProductsDescriptionMobile = (props) => {
  const { language, jsonSpace } = props;
  return (
    <FlexContainer
      className={'flex-column width100 bgYellow025 margt20 justify-center gap-10 d-hide'}
    >
      {' '}
      <Label className={'m-text-18 margt8 padlr8'}>
        <strong>
          {language === 'EN' ? jsonSpace?.description?.EN : jsonSpace?.description?.ES}
        </strong>
      </Label>
    </FlexContainer>
  );
};

const ProductsDescriptionDesktop = (props) => {
  const { language, jsonSpace } = props;
  return (
    <FlexContainer
      className={'flex-column format40 bgYellow025 margtb60 justify-center gap-30 m-hide t-hide'}
    >
      {' '}
      <Label className={'m-text-14 margt8 padlr8'}>
        <strong>
          {language === 'EN' ? jsonSpace?.description?.EN : jsonSpace?.description?.ES}
        </strong>
      </Label>
    </FlexContainer>
  );
};

const ProductDetailsLandingPageDescription = (props) => {
  const { setShowForm, language, jsonSpace } = props;
  return (
    <FlexContainer className={'flex-column width100 margtb40 bgYellow025'}>
      <FlexContainer className={'m-flex-column bgYellow025 padlr40 m-padlr20 margb10'}>
        <ProductsDescriptionMobile language={language} jsonSpace={jsonSpace} />
        <ProductSpcecsDesktop language={language} jsonSpace={jsonSpace} />
        <ProductSpcecsMobile language={language} jsonSpace={jsonSpace} />
        <ProductsDescriptionDesktop language={language} jsonSpace={jsonSpace} />
      </FlexContainer>
      <FlexContainer className={'width100 justify-center bgYellow025 padb10'}>
        <ReservationButton setShowForm={setShowForm} language={language} jsonSpace={jsonSpace} />
      </FlexContainer>
    </FlexContainer>
  );
};

const GalleryItem = (props) => {
  const { imgUrl, height } = props;
  return (
    <FlexContainer
      className={'flex-column width100 gap-30'}
      style={{
        backgroundImage: imgUrl,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: height,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    />
  );
};

const ProductDetailsLandingPageGallery = (props) => {
  const { setShowForm, language, jsonSpace } = props;
  return (
    <FlexContainer className={'flex-column width100 margtb10 gap-20'}>
      <FlexContainer className={'m-flex-column padlr80 m-padlr10 gap-20'}>
        <FlexContainer className={'flex-column width100 gap-30'}>
          <GalleryItem imgUrl={'url(' + jsonSpace?.mainImage + ')'} height={'40vh'} />
          <FlexContainer className={'width100 gap-30'}>
            <GalleryItem imgUrl={'url(' + jsonSpace?.secondaryImageA + ')'} height={'20vh'} />
            <GalleryItem imgUrl={'url(' + jsonSpace?.secondaryImageB + ')'} height={'20vh'} />
          </FlexContainer>
        </FlexContainer>
        <GalleryItem imgUrl={'url(' + jsonSpace?.sideImage + ')'} height={'65vh'} />
      </FlexContainer>
      <FlexContainer className={'width100 justify-center margtb10'}>
        <ReservationButton setShowForm={setShowForm} language={language} />
      </FlexContainer>
      {jsonSpace?.spaceCode !== 10 && (
        <Label className={'text-center text-20 m-text-18 margt8 padlr8'}>
          <strong>
            {language === 'EN'
              ? '$' + jsonSpace?.price + ' MXN per person | Capacity 10 -14 seats'
              : '$' + jsonSpace?.price + ' MXN por persona | Capacidad 10 - 14 lugares'}
          </strong>
        </Label>
      )}
      {jsonSpace?.spaceCode === 10 && (
        <Label className={'text-center text-20 m-text-18 margt8 padlr8'}>
          <strong>
            {language === 'EN'
              ? '$' + jsonSpace?.price + ' MXN per hour | Capacity 10 -14 seats'
              : '$' + jsonSpace?.price + ' MXN por hora |  Capacidad 10 - 14 lugares'}
          </strong>
        </Label>
      )}

      <Label className={'text-center m-text-14 margt8 padlr8'}>
        <strong>
          {language === 'EN'
            ? 'Valid for 90 days from the reservation date | Coffee break | Indoor and Outdoor Spaces'
            : 'Uso válido 90 días a apartir de la reservación | Coffee break | Espacios Interiores y Exteriores'}
        </strong>
      </Label>
    </FlexContainer>
  );
};

const ProductDetailsLandingPageSecondGallery = (props) => {
  const { jsonSpace } = props;
  return (
    <FlexContainer className={'m-flex-column margtb30 padlr80 m-padlr10 gap-20'}>
      <GalleryItem
        imgUrl={
          jsonSpace?.spaceCode === 7 ? 'url(./images/casa4.png)' : 'url(./images/B-casa4.png)'
        }
        height={'50vh'}
      />
      <GalleryItem
        imgUrl={
          jsonSpace?.spaceCode === 7 ? 'url(./images/casa5.png)' : 'url(./images/B-casa5.png)'
        }
        height={'50vh'}
      />
      <GalleryItem
        imgUrl={
          jsonSpace?.spaceCode === 7 ? 'url(./images/casa6.png)' : 'url(./images/B-casa6.png)'
        }
        height={'50vh'}
      />
    </FlexContainer>
  );
};

const ProductDetailsLandingPage = (props) => {
  const { spacesDataFromBackend, language, jsonSpace } = props;
  const [showForm, setShowForm] = useState(false);
  const [showCheckout, setShowCheckout] = useState(false);
  const [spaceToBuy, setSpaceToBuy] = useState(false);
  const [formData, setFormData] = useState({});
  const lenguageData = useSelector((state) => state.lenguage?.lang);
  const esDescription =
    'Descubre el espacio de coworking ideal para ti en Popnest. Reserva tu pase diario y accede a un entorno productivo con todas las comodidades que necesitas. ¡Trabaja de manera flexible y sin compromisos!';
  const enDescription =
    'Discover the perfect coworking space for you at Popnest. Book your day pass and enjoy a productive environment with all the amenities you need. Work flexibly with no commitments!';

  const setShowDataForm = () => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
    setSpaceToBuy(jsonSpace?.spaceCode);
    const spaceName =
      jsonSpace?.spaceCode === 7 || jsonSpace?.spaceCode === 10 ? 'polanco' : 'condesa';
    const urlToForm =
      language === 'EN' ? '/complete-your-data-' + spaceName : '/complete-sus-datos-' + spaceName;
    window.history.replaceState(null, '', urlToForm);
    setShowForm(true);
  };

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }, []);

  return (
    <FlexContainer column>
      {!showForm && (
        <>
          <HelmetComponent
            title={
              lenguageData === 'EN'
                ? 'Product Details Landing Page - Popnest'
                : 'Página de destino con detalles del producto - Popnest'
            }
            description={lenguageData === 'EN' ? enDescription : esDescription}
            graphTitle={
              lenguageData === 'EN'
                ? 'Product Details Landing Page - Popnest'
                : 'Página de destino con detalles del producto - Popnest'
            }
            graphDescription={lenguageData === 'EN' ? enDescription : esDescription}
          />
          <ProductDetailsLandingPageBanner
            setShowForm={setShowDataForm}
            language={language}
            jsonSpace={jsonSpace}
          />
          <ProductDetailsLandingPageBanner
            mobile
            setShowForm={setShowDataForm}
            jsonSpace={jsonSpace}
            language={language}
          />
          <ProductDetailsLandingPageDescription
            setShowForm={setShowDataForm}
            language={language}
            jsonSpace={jsonSpace}
          />
          <ProductDetailsLandingPageGallery
            setShowForm={setShowDataForm}
            language={language}
            jsonSpace={jsonSpace}
          />
          <ProductDetailsLandingPageSecondGallery
            setShowForm={setShowDataForm}
            language={language}
            jsonSpace={jsonSpace}
          />
        </>
      )}
      {showForm && !showCheckout && (
        <SpaceDetailForm
          language={language}
          jsonSpace={jsonSpace}
          setShowCheckout={setShowCheckout}
          spaceName={
            jsonSpace?.spaceCode === 7 || jsonSpace?.spaceCode === 10 ? 'Polanco' : 'Condesa'
          }
          spacePrice={jsonSpace?.price}
          noPromoSpacePrice={jsonSpace?.price}
          setFormData={setFormData}
        />
      )}
      {showCheckout && (
        <CheckoutFormComponent
          language={language}
          jsonSpace={jsonSpace}
          spaceName={
            jsonSpace?.spaceCode === 7 || jsonSpace?.spaceCode === 10 ? 'Polanco' : 'Condesa'
          }
          spacePrice={jsonSpace?.price}
          noPromoSpacePrice={jsonSpace?.price}
          formData={formData}
          spaceToBuy={spaceToBuy}
        />
      )}
    </FlexContainer>
  );
};

export default ProductDetailsLandingPage;
