import React, { useState, useEffect } from 'react';
import SpacesList from '@/components/modules/SpacesList/SpacesList';
import OurClients from '@/components/modules/OurClients/OurClients';
import BlogModule from '@/components/modules/BlogModule/BlogModule';
import HowItWorks from '@/components/modules/HowItWorks/HowItWorks';
import NewHomeSlider from '../components/NewHomeSlider/NewHomeSlider';
import HomeFilters from '../components/HomeFilters/HomeFilters';
import CustomerExperience from '../components/CustomerExperience/CustomerExperience';
import { FlexContainer } from '@/components/containers/FlexContainer/FlexContainer';
import { useSelector } from 'react-redux';
import NewGeneralSearchForm from '@/components/general/Forms/NewGeneralSearchForm/NewGeneralSearchForm';
import ReactPixel from 'react-facebook-pixel';
import HelmetComponent from '@/components/general/Helmet/HelmetComponent';

const HomeView = (props) => {
  const { spacesDataFromBackend } = props;
  const lenguageData = useSelector((state) => state.lenguage?.lang);
  const [isVisibleNewForm, setIsVisibleNewForm] = useState(false);
  const [searchType, setSearchType] = useState('');

  const showNewForm = (type) => {
    window.scrollTo(0, 0);
    setIsVisibleNewForm(true);
    ReactPixel.trackCustom('SelectSearchType', { clave: type });
    window.history.replaceState(null, '', '/elige-tu-experiencia');
    setSearchType(type);
  };

  const hideNewForm = () => {
    window.scrollTo(0, 0);
    setIsVisibleNewForm(false);
    setSearchType('');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fistSliderLabel =
    lenguageData === 'ES' ? 'RENTA UNA SALA DE JUNTAS' : 'BOOK A MEETING ROOM';

  const secondSliderLabel =
    lenguageData === 'ES' ? 'RENTA UN ESPACIO DE TRABAJO' : 'BOOK A WORKSPACE';

  const firstSequence = [
    fistSliderLabel,
    1000,
    fistSliderLabel,
    1000,
    fistSliderLabel,
    1000,
    fistSliderLabel,
    1000,
  ];

  const secondSequence = [
    secondSliderLabel,
    1000,
    secondSliderLabel,
    1000,
    secondSliderLabel,
    1000,
    secondSliderLabel,
    1000,
  ];

  const firstImagesArray = ['spaces/slider35.png', 'spaces/juntas.jpg'];
  const secondImagesArray = ['spaces/cowork.jpg', 'spaces/slider34.png'];

  const esDescription =
    'Somos un equipo de apasionados por la innovación y el buen vivir. Ofrecemos espacios de trabajo únicos con privacidad, buen diseño y sin ruido.';
  const enDescription =
    'We are a team of people passionate about innovation and good living. We offer unique work spaces with privacy, good design and no noise.';

  return (
    <div className="App">
      <HelmetComponent
        title={lenguageData === 'EN' ? 'Home - Popnest' : 'Inicio - Popnest'}
        description={lenguageData === 'EN' ? enDescription : esDescription}
        graphTitle={lenguageData === 'EN' ? 'Home - Popnest' : 'Inicio - Popnest'}
        graphDescription={lenguageData === 'EN' ? enDescription : esDescription}
      />
      <FlexContainer className={isVisibleNewForm ? 'bgWhite m-flex-column' : 'm-flex-column'}>
        {isVisibleNewForm && (
          <FlexContainer
            className={'flex-column margt100 m-margt120 format100 m-formatAuto padlr40 m-padlr0'}
          >
            <button
              type="button"
              className={
                'pointer bgWhite border-rad4px padlr20 marglr10 padtb6 borderGray050 fontGray500 text-16 m-margt40'
              }
              onClick={() => hideNewForm()}
            >
              <i className={'fa fa-arrow-left margr6'} aria-hidden="true" />
              {lenguageData === 'ES' ? 'Atrás' : 'Back'}
            </button>
            <NewGeneralSearchForm searchType={searchType} />
          </FlexContainer>
        )}
        {!isVisibleNewForm && (
          <>
            <NewHomeSlider
              spacesData={spacesDataFromBackend}
              personalizedContainerClass={'format50 m-format100 m-margt70'}
              personalizedPlaceholder={
                lenguageData === 'ES' ? 'Buscar una sala de juntas' : 'Search for a Meeting Room'
              }
              sequence={firstSequence}
              imagesArray={firstImagesArray}
              showNewForm={() => showNewForm('Sala')}
            />
            <NewHomeSlider
              spacesData={spacesDataFromBackend}
              personalizedContainerClass={'format50 m-format100'}
              personalizedPlaceholder={
                lenguageData === 'ES' ? 'Buscar un coworking' : 'Search for a Coworking Space'
              }
              sequence={secondSequence}
              imagesArray={secondImagesArray}
              showNewForm={() => showNewForm('Coworking')}
            />
          </>
        )}
      </FlexContainer>
      {/*{!isVisibleNewForm && <HomeFilters />}*/}

      <SpacesList spacesData={spacesDataFromBackend} key={1} />
      <OurClients
        customTitle={lenguageData === 'ES' ? 'Ellos Confían en Nosotros' : 'They Trust Us'}
      />
      <BlogModule customTitle={lenguageData === 'ES' ? 'Blog de Popnest' : 'Popnest Blog'} />
      <HowItWorks
        customTitle={
          lenguageData === 'ES' ? '¿Cómo funciona nuestro servicio?' : 'How Does Our Service Work?'
        }
        buttonTitle={
          lenguageData === 'ES'
            ? 'Revisa Nuestras Preguntas Frecuentes'
            : 'Check Our Frequently Asked Questions'
        }
        isShowButton
      />
      <CustomerExperience />
    </div>
  );
};

export default HomeView;
