import React, { useState, useEffect } from 'react';
import { FlexContainer } from '@/components/containers/FlexContainer/FlexContainer';
import { PageContainer } from '@/components/containers/PageContainer/PageContainer';
import { useSelector } from 'react-redux';
import HelmetComponent from '@/components/general/Helmet/HelmetComponent';
import Label from '@/components/form/Label/Label';
import './PrivacyConditions.scss';

const PrivacyConditions = (props) => {
  const { spacesData } = props;
  const lenguageData = useSelector((state) => state.lenguage.lang);
  const esDescription =
    'Tu información está protegida. En Popnest, garantizamos seguridad y transparencia en el manejo de tus datos.';
  const enDescription =
    'Your information is protected. At Popnest, we ensure security and transparency in handling your data.';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <PageContainer className={'flex-column margtb80 text-justify'}>
      <HelmetComponent
        title={
          lenguageData === 'EN'
            ? 'Privacy and Conditions - Popnest'
            : 'Privacidad y Condiciones - Popnest'
        }
        description={lenguageData === 'EN' ? enDescription : esDescription}
        graphTitle={
          lenguageData === 'EN'
            ? 'Privacy and Conditions - Popnest'
            : 'Privacidad y Condiciones - Popnest'
        }
        graphDescription={lenguageData === 'EN' ? enDescription : esDescription}
      />
      <Label className={'text-12 m-text-10'}>
        <h1 className={'Subtitle-1 text-22'}>Política de Privacidad</h1>
        <h2 className={'Subtitle-2 margt40'}>AVISO DE PRIVACIDAD INTEGRAL-USUARIOS</h2>
        <p>
          FINOVIX S.A.P.I. DE C.V. en adelante “POPNEST”, en representación de POPNEST, con
          domicilio en Álvaro Obregón, CDMX con sitio web,{' '}
          <a href="https://www.popnest.org/">https://www.popnest.org/</a>, es la responsable en el
          tratamiento de sus datos personales. En este aviso podrá encontrar el tratamiento que
          damos a sus datos personales para el otorgamiento de un préstamo en línea a través de
          nuestro SITIO WEB; información que estará segura y permanecerá confidencial, así como los
          procedimientos para ejercer sus derechos de acceso, rectificación, cancelación y oposición
          a sus datos personales, en términos de la Ley Federal de Protección de Datos Personales en
          Posesión de los Particulares (LFPDPPP) y su Reglamento.
        </p>

        <h3 className={'Subtitle-2 margt40'}>1. RESPONSABLE</h3>
        <p>
          La persona responsable del manejo y la administración de los datos personales es POPNEST,
          la cual dará el tratamiento conforme a las finalidades señaladas en este aviso de
          privacidad. Puedes contactar al responsable al correo electrónico{' '}
          <a href="mailto:contacto@popnest.co">contacto@popnest.co</a> o a través de nuestro sitio
          web.
        </p>

        <h3 className={'Subtitle-2 margt40'}>2. DEFINICIONES</h3>
        <ul>
          <li>
            <strong>Datos Personales:</strong> Cualquier información concerniente a una persona
            física identificada o identificable.
          </li>
          <li>
            <strong>Datos Personales Sensibles:</strong> Aquellos datos personales que afectan a la
            esfera íntima del titular, o cuya utilización indebida pueda dar origen a discriminación
            o un riesgo grave.
          </li>
          <li>
            <strong>Derechos ARCO:</strong> Derecho del titular de los datos personales a solicitar
            al responsable el acceso, rectificación, cancelación u oposición respecto de dichos
            datos, conforme a la LFPDPPP y su Reglamento.
          </li>
          <li>
            <strong>Disociación:</strong> Procedimiento mediante el cual los datos personales no
            pueden asociarse al titular.
          </li>
          <li>
            <strong>SITIO WEB:</strong> Se refiere exclusivamente a{' '}
            <a href="https://www.popnest.org/">https://www.popnest.org/</a>.
          </li>
        </ul>

        <h3 className={'Subtitle-2 margt40'}>3. DATOS PERSONALES RECABADOS</h3>
        <p>
          Los datos personales proporcionados por POPNEST que serán sometidos al tratamiento
          correspondiente son los siguientes:
        </p>
        <h4 className={'Subtitle-2 margt40'}>3.1. Datos de identificación</h4>
        <ul>
          <li>Nombre completo</li>
          <li>Domicilio de residencia</li>
          <li>Clave de elector o número de identificación de elector</li>
          <li>Edad</li>
          <li>Género</li>
          <li>Firma autógrafa</li>
          <li>Firma electrónica</li>
          <li>RFC</li>
          <li>Datos fiscales</li>
        </ul>

        <h4 className={'Subtitle-2 margt40'}>3.2. Datos de contacto</h4>
        <ul>
          <li>Correo electrónico</li>
          <li>Domicilio de residencia</li>
          <li>Número celular principal</li>
          <li>Número adicional fijo o celular</li>
        </ul>

        <h4 className={'Subtitle-2 margt40'}>
          3.3. Registros informáticos relacionados con actividades y comportamiento en internet
        </h4>
        <ul>
          <li>Horario de navegación</li>
          <li>Periodo de tiempo de navegación en nuestro sitio web</li>
          <li>Secciones consultadas y páginas web visitadas antes de ingresar a nuestro Sitio</li>
        </ul>

        <h3 className={'Subtitle-2 margt40'}>
          4. FINALIDAD DEL TRATAMIENTO DE LOS DATOS PROPORCIONADOS
        </h3>
        <p>
          Los datos personales que recabamos serán utilizados para brindar nuestros servicios y
          cumplir con los términos del servicio. Las finalidades son:
        </p>
        <h4 className={'Subtitle-2 margt40'}>Finalidades Primarias</h4>
        <ul>
          <li>Adquisición de productos y servicios ofrecidos por POPNEST a través del SITIO WEB</li>
          <li>Verificar, confirmar y validar la identidad del titular</li>
          <li>Administrar, operar y dar seguimiento a los servicios contratados</li>
          <li>Formalizar la relación contractual entre POPNEST y el titular</li>
        </ul>

        <h4 className={'Subtitle-2 margt40'}>Finalidades Secundarias</h4>
        <ul>
          <li>
            Ofrecimiento de productos y servicios análogos y varios a los solicitados previamente
          </li>
          <li>
            Fines comerciales y publicitarios relacionados con los productos y servicios de POPNEST
          </li>
        </ul>

        <h3 className={'Subtitle-2 margt40'}>5. OBTENCIÓN Y TRATAMIENTO DE DATOS PERSONALES</h3>
        <p>
          POPNEST podrá obtener información sobre EL TITULAR a través de medios permitidos por la
          LFPDPPP, incluyendo formularios en línea en nuestro SITIO WEB, correos electrónicos o
          medios de comunicación a distancia.
        </p>

        <h3 className={'Subtitle-2 margt40'}>6. REVOCACIÓN DE CONSENTIMIENTO</h3>
        <p>
          En todo momento, se podrá revocar el consentimiento para el tratamiento de sus datos
          personales a través de la Sección 9 del presente Aviso de Privacidad.
        </p>

        <h3 className={'Subtitle-2 margt40'}>7. EJERCICIO DE DERECHOS ARCO</h3>
        <p>
          EL TITULAR tiene derecho a acceder, rectificar, cancelar u oponerse al tratamiento de sus
          datos personales según los derechos ARCO.
        </p>

        <h3 className={'Subtitle-2 margt40'}>
          8. PROCEDIMIENTO PARA LIMITACIÓN DEL USO O TRANSFERENCIA DE DATOS PERSONALES
        </h3>
        <p>
          Para ejercer el derecho de limitación o restricción de uso de datos personales, el Titular
          deberá enviar un escrito firmado digitalizado al correo electrónico{' '}
          <a href="mailto:contacto@popnest.co">contacto@popnest.co</a> con la información detallada
          en esta sección.
        </p>

        <h3 className={'Subtitle-2 margt40'}>9. MODIFICACIONES AL AVISO DE PRIVACIDAD</h3>
        <p>
          POPNEST se reserva el derecho de efectuar en cualquier momento modificaciones al presente
          aviso de privacidad. Las modificaciones estarán disponibles en nuestra página de internet.
        </p>

        <h3 className={'Subtitle-2 margt40'}>
          10. QUEJAS Y DENUNCIAS POR TRATAMIENTO INDEBIDO DE SUS DATOS PERSONALES
        </h3>
        <p>
          Si considera que su derecho de protección de datos ha sido lesionado, puede comunicarlo a
          través de <a href="mailto:contacto@popnest.co">contacto@popnest.co</a>.
        </p>

        <h3 className={'Subtitle-2 margt40'}>
          11. COOKIES, WEB BEACONS, TECNOLOGÍAS SIMILARES Y REDES SOCIALES
        </h3>
        <p>
          POPNEST utiliza cookies, web beacons y tecnologías similares. El usuario tiene la opción
          de bloquear estos elementos desde su navegador.
        </p>

        <h3 className={'Subtitle-2 margt40'}>12. ACEPTACIÓN Y ENTENDIMIENTO DE AVISO</h3>
        <p>
          Este aviso está sujeto a los términos y condiciones establecidos en el SITIO WEB de
          POPNEST.
        </p>
      </Label>
    </PageContainer>
  );
};

export default PrivacyConditions;
