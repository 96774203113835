import React, { useEffect } from 'react';
import { FlexContainer } from '@/components/containers/FlexContainer/FlexContainer';
import { PageContainer } from '@/components/containers/PageContainer/PageContainer';
import NewSpaceCardShortVersion from '@/components/reusable/NewSpaceCardShortVersion/NewSpaceCardShortVersion';
import Label from '@/components/form/Label/Label';
import { useSelector } from 'react-redux';
import HelmetComponent from '@/components/general/Helmet/HelmetComponent';
import dayjs from 'dayjs';
import ReactPixel from 'react-facebook-pixel';
import './ReservationSuccess.scss';

require('dayjs/locale/es');
dayjs.locale('es');

const SpaceDetailInfo = props => {
  const { spaceDetail } = props;
  return (
    <FlexContainer className={'flex-column format100'}>
      <NewSpaceCardShortVersion
        spaceInfo={spaceDetail}
        isInlineStyle
        imgUrl={spaceDetail?.main_image}
        spaceTitle={spaceDetail?.space_title}
        spaceDescription={spaceDetail?.space_details}
        price={spaceDetail?.price}
        disableButton
        wifi
        coffee
        parking
        isWhiteBg
      />
    </FlexContainer>
  );
};

const PaymentSection = props => {
  const { spaceDetail, formValues, reservation } = props;
  console.log('Datos pasados a reservation a paymentsection :', reservation);
  const data = reservation[0];

  const date = dayjs(formValues?.startDate?.$d);
  const mainGuestLabel = 'Invitado responsable: Sofia';
  const guestLabel = 'Personas: ' + formValues?.guestQuantyty + ' personas';
  const finalPrice = data.final_price;
  const paymentMethod = data.method_name;
  const paymentDetails = data.method_details;
  const spaceName = data.space_name;
  const startDate = dayjs(data.start_date).format('dddd D [de] MMMM [de] YYYY');
  const finishDate = dayjs(data.finish_date).format(
    'dddd D [de] MMMM [de] YYYY',
  );

  return (
    <FlexContainer
      className={'flex-column text-align-justify justify-around m-padlr10'}
    >
      <FlexContainer className="flex-column bgBlue400 fontWhite formatAuto padlr20 padtb20 text-14 width100">
        <label className={'Subtitle-3 fontWhite margb10 text-16'}>
          IMPORTANTE:{' '}
        </label>
        <label className={'fontGray050'}>
          Para crear tu acceso al lugar, un miembro de Popnest te contactará por
          WhatsApp en breve.
        </label>
      </FlexContainer>
      <label className={'text-18 m-text-20 Subtitle-3 margt40 margb10'}>
        Datos de tu reserva
      </label>
      <label className={'text-14 m-text-12 margt10'}>Lugar: {spaceName}</label>
      <label className={'text-14 m-text-12 margt10'}>
        Fecha: {startDate}-{finishDate}
      </label>
      <label className={'text-14 m-text-12 margt10'}>{guestLabel}</label>
      <label className={'text-14 m-text-12 margt10'}>{mainGuestLabel}</label>
      <label className={'text-18 m-text-20 Subtitle-3 margtb10'}>
        Detalle del pago
      </label>
      <label className={'text-14 m-text-12 margt10'}>
        Método de pago: {paymentMethod} ({paymentDetails})
      </label>
      <label className={'text-14 m-text-12 margt10'}>
        Subtotal: ${finalPrice}
      </label>

      <FlexContainer
        className={
          'justify-between borderGray100 justify-between padlr10 padtb10 margt10'
        }
      >
        <label className={'text-16 m-text-14'}>
          <strong>Total</strong>
        </label>
        <label>
          <strong className={'text-20 m-text-18'}>${finalPrice}</strong>
        </label>
      </FlexContainer>
      <label className={'text-18 m-text-20 Subtitle-3 margtb10'}>
        Como llegar
      </label>
      <label className={'text-18 m-text-20 Subtitle-3 margtb10'}>
        Como acceder
      </label>
      <label className={'text-14 m-text-12 '}>
        El lugar es en <strong>{spaceName} </strong> podrás ubicarte en los
        espacios disponibles, usa el de tu preferencia{' '}
        <strong>
          Nuestro staff de Popnest te brindará el código de acceso por Whatsapp{' '}
        </strong>
      </label>
      <button
        className={
          'bgBlue400 width100 border-rad24px borderGray050 fontWhite grayShadow16 margtb30 padlr40 padtb10 text-18'
        }
      >
        <strong>Compartir comprobante de pago</strong>
      </button>
    </FlexContainer>
  );
};

const GiftSection = props => {
  const { spaceDetail, formValues, reservation, languageFromPath } = props;
  console.log('Datos pasados a reservation a paymentsection :', reservation);
  const data = reservation[0];

  const date = dayjs(formValues?.startDate?.$d);

  const mainGuestLabel =
    languageFromPath === 'EN'
      ? 'Reservation To: ' + data?.beneficiary
      : 'Reservación para: ' + data?.beneficiary;
  const guestLabel =
    languageFromPath === 'EN' ? 'From: ' + data?.gifter : 'De: ' + data?.gifter;
  const dateLabel =
    languageFromPath === 'EN'
      ? 'Booking date: To be selected'
      : 'Fecha de reserva: Por seleccionar';
  const startTime =
    languageFromPath === 'EN'
      ? 'Start time: To be selected'
      : 'Hora de inicio: Por seleccionar';
  const endTime =
    languageFromPath === 'EN'
      ? 'End Time: To be selected'
      : 'Hora de Fin: Por seleccionar';

  const finalPrice = data.final_price;
  const initialPrice = data.initial_price;
  const paymentMethod = data.method_name;
  const paymentDetails = data.method_details;

  const transactionId = data.transaction_id;
  const state = data.state_name;
  const spaceName = data.space_name;
  const message =
    'Mensaje de ' +
    data?.gifter +
    ' para ' +
    data?.beneficiary +
    ': ' +
    data.message;

  return (
    <FlexContainer
      className={'flex-column text-align-justify justify-around m-padlr10'}
    >
      <Label className="width100 text-16">
        {languageFromPath === 'EN'
          ? 'Dear user: We are excited that you have reserved a space in '
          : 'Estimado/a usuario/a: Estamos emocionados de que hayas reservado un espacio en '}
        {spaceDetail?.space_title}{' '}
        {languageFromPath === 'EN'
          ? ' for your next session '
          : ' para tu proxima sesión de '}{' '}
        {spaceDetail?.subtype_title}.
      </Label>
      <FlexContainer className="padtb10 bgGray025 m-margtb10 margtb10" />
      <label className={'text-14 m-text-12 bold'}>
        {' '}
        {languageFromPath === 'EN'
          ? 'Reservation Details: '
          : 'Datos de la reserva: '}
      </label>
      <label className={'text-14 m-text-12 '}>{mainGuestLabel}</label>
      <FlexContainer className="padtb10 bgGray025 m-margtb10 margtb10" />
      <label className={'text-14 m-text-12 '}>{dateLabel}</label>
      <label className={'text-14 m-text-12 '}>{startTime}</label>
      <label className={'text-14 m-text-12 '}>{endTime}</label>
      <label className={'text-14 m-text-12 '}>
        {languageFromPath === 'EN'
          ? 'Reservation Details'
          : 'Detalles de la reserva:'}
      </label>
      <label className={'text-14 m-text-12 '}>
        {languageFromPath === 'EN' ? 'Payment Method' : 'Método de pago: '}{' '}
        {paymentMethod} ({paymentDetails})
      </label>
      <label className={'text-14 m-text-12 '}>
        {languageFromPath === 'EN' ? 'Transaction ID: ' : 'ID de transacción: '}{' '}
        {transactionId}
      </label>
      <label className={'text-14 m-text-12 '}>
        {languageFromPath === 'EN' ? 'Initial Price: ' : 'Precio Inicial: '} $
        {initialPrice}
      </label>
      <label className={'text-14 m-text-12 '}>
        {languageFromPath === 'EN' ? 'Final Price: ' : 'Precio Final: '} $
        {finalPrice}
      </label>
      <label className={'text-14 m-text-12 '}>
        {languageFromPath === 'EN' ? 'Status: ' : 'Estado: '} {state}
      </label>

      <FlexContainer className="padtb10 bgGray025 m-margtb10 margtb10" />

      <label className={'text-14 m-text-12 '}>
        {languageFromPath === 'EN'
          ? 'Space Details: '
          : 'Detalles del espacio: '}
      </label>
      <label className={'text-14 m-text-12 '}>
        {languageFromPath === 'EN' ? 'Space: ' : 'Espacio: '} {spaceName}
      </label>
      <label className={'text-14 m-text-12 '}>
        {languageFromPath === 'EN' ? 'Subtype: ' : 'Subtipo: '} Coworking
      </label>
    </FlexContainer>
  );
};

const ReservationSuccess = props => {
  const { spaceDetail, formValues, reservation, languageFromPath } = props;
  const lenguageData = useSelector(state => state.lenguage.lang);
  const esDescription =
    '¡Reserva confirmada! Gracias por elegir Popnest. Te hemos enviado los detalles de tu reserva a tu correo electrónico. Nos vemos en tu espacio de coworking. ¡Que tengas un gran día de productividad!';
  const enDescription =
    'Reservation Confirmed! Thank you for choosing Popnest. We ve sent your booking details to your email. See you at your coworking space. Have a great and productive day!';

  const reservationJson = {
    startDate: {
      $L: 'en',
      $d: '2024-11-20T05:00:00.000Z',
      $y: 2024,
      $M: 10,
      $D: 20,
      $W: 3,
      $H: 0,
      $m: 0,
      $s: 0,
      $ms: 0,
      $x: {},
      $isDayjsObject: true,
    },
    startTime: 10,
    endTime: 14,
    name: 'Mario Enrique Vargas Pino',
    email: 'sistemas@popnest.org',
    phone: '922907079',
    countryCode: '+52',
    guestQuantyty: 1,
  };
  const spaceData = spaceDetail;
  const formData = formValues ? formValues : reservationJson;

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactPixel.trackCustom('ReservationSuccess', {
      reservationData: reservation,
    });
  }, []);
  if (reservation) {
    return (
      <PageContainer className={''}>
        <HelmetComponent
          title={
            lenguageData === 'EN'
              ? 'Reservation - Popnest'
              : 'Reservación - Popnest'
          }
          description={lenguageData === 'EN' ? enDescription : esDescription}
          graphTitle={
            lenguageData === 'EN'
              ? 'Reservation - Popnest'
              : 'Reservación - Popnest'
          }
          graphDescription={
            lenguageData === 'EN' ? enDescription : esDescription
          }
        />
        <FlexContainer className={'flex-column margt72'}>
          <Label type="Subtitle-3 margt50 padlr20 m-padlr10 m-margt20 fontGray250 text-center text-26 m-text-20">
            {languageFromPath === 'EN'
              ? 'RESERVATION MADE SUCCESSFULLY!'
              : '¡RESERVA REALIZADA CON EXITO!'}
          </Label>
          <FlexContainer className={'m-flex-column margt20 margb120 gap-20'}>
            {reservation[0]?.isgift != 1 && (
              <>
                <SpaceDetailInfo
                  languageFromPath={languageFromPath}
                  spaceDetail={spaceData}
                  formValues={formData}
                />
                <PaymentSection
                  languageFromPath={languageFromPath}
                  spaceDetail={spaceData}
                  formValues={formData}
                  reservation={reservation}
                />
              </>
            )}
            {reservation[0]?.isgift == 1 && (
              <>
                <GiftSection
                  languageFromPath={languageFromPath}
                  spaceDetail={spaceData}
                  formValues={formData}
                  reservation={reservation}
                />
              </>
            )}
          </FlexContainer>
        </FlexContainer>
      </PageContainer>
    );
  } else {
    return <></>;
  }
};

export default ReservationSuccess;
