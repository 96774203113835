import React, { useState, useEffect } from 'react';
import { FlexContainer } from '@/components/containers/FlexContainer/FlexContainer';
import Label from '@/components/form/Label/Label';
import { loadStripe } from '@stripe/stripe-js';
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { Link } from 'react-router-dom';
import { TextField, MenuItem, Select } from '@mui/material';
import { useSelector } from 'react-redux';
import HelmetComponent from '@/components/general/Helmet/HelmetComponent';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import './PromoLandingView.scss';

const apiUrl = process.env.REACT_APP_API_URL;
/*
const stripePromise = loadStripe('pk_test_6pRNASCoBOKtIshFeQd4XMUh');
*/
const stripePromise = loadStripe(
  'pk_live_51MuKSIA0gqRxLoT3YHraiqMUanidK11hZZB8vptnBPNDPQUwEWNfKx8yMDbONCyPA5BHHjGryNJYEp2BZzX1m67k00ZbJdQDlT',
);

const CheckoutForm = props => {
  const { spacePrice, formData, spaceToBuy } = props;
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoadingCheckout, setIsLoadingCheckout] = useState(false);

  const handleSubmit = async event => {
    setIsLoadingCheckout(true);
    event.preventDefault();
    if (elements == null) {
      return;
    }

    const { error: submitError } = await elements.submit();
    if (submitError) {
      setErrorMessage(submitError.message);
      return;
    }

    const amount = Math.round(parseFloat(spacePrice) * 100);
    //const currency = 'usd';
    const currency = 'mxn';

    const reservation = {
      host_id: 1,
      guest_id: 2,
      space_subtype_relationship: spaceToBuy,
      start_date: '2024-12-01',
      finish_date: '2024-12-05',
      initial_price: 380,
      final_price: 380,
      id_state: 1,
      payment_method: 1,
      method_status: 'p',
      method_details: 'Credit Card',
      isgift: 1,
      message: formData?.recipientMessage,
      gifter: formData?.recipientName,
      beneficiary: formData?.name,
      gifter_mail: formData?.email,
      beneficiary_mail: formData?.recipientEmail,
      gifter_phone: formData?.phone,
      beneficiary_phone: formData?.recipientPhone,
    };

    const res = await fetch(`${apiUrl}/POST/checkout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        amount,
        currency,
        reservation: reservation,
      }),
    });

    const { client_secret, createdReservation } = await res.json();
    console.log('ver secret', client_secret);
    console.log('ver reservacion', createdReservation);

    const { error } = await stripe.confirmPayment({
      elements,
      clientSecret: client_secret,
      confirmParams: {
        /*
        return_url:
          'http://localhost:3000/reserva-confirmada?transaction_id=' +
          createdReservation?.transaction_id,
          */
        //return_url: 'https://popnest.org/reservationSuccess',
        return_url:
          'https://popnest.org/reserva-confirmada?transaction_id=' +
          createdReservation?.transaction_id,
      },
    });

    if (error) {
      setErrorMessage(error.message);
      setIsLoadingCheckout(false);
    } else {
      console.log('Pago exitoso');
      setIsLoadingCheckout(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      {!isLoadingCheckout && (
        <button
          type="submit"
          className={
            'bgBlue400 width100 bold border-rad8px borderGray050 fontWhite grayShadow16 margtb20 padlr40 padtb20 text-18'
          }
          disabled={!stripe || !elements}
        >
          Pagar aquí
        </button>
      )}
      {isLoadingCheckout && <Label className={'margtb10'}>Cargando...</Label>}
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
};

const ImageComponent = props => {
  const { imageOne, imageTwo } = props;
  return (
    <>
      <img
        src={imageOne}
        alt={''}
        className={'width100 borderTopRadius16 height240px'}
      />
      <img src={imageTwo} alt={''} className={'width100 m-hide height240px'} />
    </>
  );
};

const IndividualSpace = props => {
  const {
    setShowForm,
    spaceName,
    imageOne,
    imageTwo,
    generalUbi,
    setSpaceToBuy,
    spaceId,
  } = props;

  const goToForm = id => {
    setSpaceToBuy(id);
    setShowForm(true);
  };

  return (
    <FlexContainer
      className={'flex-column width100 border-rad16px bgWhite borderBlack'}
    >
      <ImageComponent imageOne={imageOne} imageTwo={imageTwo} />
      <FlexContainer className={'flex-column height100 padlr10 margtb20'}>
        <Label className="width100 padtb10 text-20 padlr20 m-padlr0 bold">
          <strong>Pase de coworking</strong>
        </Label>
        <Label className="padtb10 text-14 padlr20 m-padlr0">
          <strong>Ubicación: {generalUbi}</strong>
        </Label>
        <Label className="padtb10 text-14 padlr20 m-padlr0">
          <strong>Dirección: {spaceName}</strong>
        </Label>
        <Label className="width100 padtb10 text-20 padlr20 m-padlr0">
          <strong>8 horas</strong>
        </Label>
        <Label className="width100 padt10 text-20 padlr20 m-padlr0 fontPurple4">
          <strong>
            $380 MXN <strike className="fontGray250">$450 MXN</strike>
          </strong>
        </Label>
        <Label className="width100 padb10 text-14 padlr20 m-padlr0">
          <strong>Por persona</strong>
        </Label>
        <Label className="width100 text-16">
          <ul>
            <li>Escritorios / Espacios de trabajo</li>
            <li>Conexión para cargar equipo</li>
            <li>Internet de alta velocidad</li>
            <li>Áreas comunes</li>
            <li>Coffee break</li>
            <li>
              Espacios con:
              <ul>
                <li>Áreas interiores</li>
                <li>Áreas al aire libre</li>
              </ul>
            </li>
            <li>Información detallada de la reservación y accesos</li>
            <li>
              Servicio de concierge Popnest para comunicación de comienzo a fin,
              el día de la reservación
            </li>
          </ul>
        </Label>
        <button
          type="submit"
          className={
            'bgBlue400 bold border-rad8px borderGray050 fontWhite grayShadow16 margt20 padlr40 padtb12 text-14'
          }
          onClick={() => goToForm(spaceId)}
        >
          Comprar
        </button>
      </FlexContainer>
    </FlexContainer>
  );
};

const SpaceDetailForm = props => {
  const { spaceName, spacePrice, setShowCheckout, setFormData } = props;
  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Correo inválido')
      .required('El correo es obligatorio'),
    name: Yup.string().required('El nombre es obligatorio'),
    phone: Yup.string().required('El teléfono del destinatario es obligatorio'),
    recipientEmail: Yup.string()
      .email('Correo inválido')
      .required('El correo del destinatario es obligatorio'),
    recipientPhone: Yup.string().required(
      'El teléfono del destinatario es obligatorio',
    ),
    recipientName: Yup.string().required(
      'El nombre del destinatario es obligatorio',
    ),
    recipientMessage: Yup.string().required('El mensaje es obligatorio'),
  });

  // Estado inicial del formulario
  const initialValues = {
    email: '',
    name: '',
    phone: '',
    recipientEmail: '',
    recipientPhone: '',
    recipientName: '',
    recipientMessage: '',
  };

  // Manejo del envío del formulario
  const onSubmit = values => {
    console.log('Datos enviados:', values);
    window.scrollTo(0, 0);
    setShowCheckout(true);
    setFormData(values);
  };
  return (
    <FlexContainer
      className={'m-flex-column width100 border-rad16px bgWhite justify-center'}
    >
      <FlexContainer
        className={'flex-column height100 padlr10 margtb20 text-center'}
      >
        <h3 className={'padb20 text-26 bold'}>
          ¿A quién le enviaremos este regalo?
        </h3>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form>
              <FlexContainer className={'m-flex-column width100 gap-20'}>
                <FlexContainer column className={'gap-20 width100'}>
                  <Label className="width100 padtb10 text-20 bold">
                    Tus datos
                  </Label>
                  <Field name="name">
                    {({ field, form }) => (
                      <TextField
                        {...field}
                        label="Nombre"
                        variant="outlined"
                        onChange={e => setFieldValue('name', e.target.value)}
                        error={Boolean(form.errors.name && form.touched.name)}
                        helperText={form.touched.name && form.errors.name}
                      />
                    )}
                  </Field>
                  <Field name="email">
                    {({ field, form }) => (
                      <TextField
                        {...field}
                        label="Correo"
                        variant="outlined"
                        onChange={e => setFieldValue('email', e.target.value)}
                        error={Boolean(form.errors.email && form.touched.email)}
                        helperText={form.touched.email && form.errors.email}
                      />
                    )}
                  </Field>
                  <Field name="phone">
                    {({ field, form }) => (
                      <TextField
                        {...field}
                        label="Teléfono"
                        variant="outlined"
                        onChange={e => setFieldValue('phone', e.target.value)}
                        error={Boolean(form.errors.phone && form.touched.phone)}
                        helperText={form.touched.phone && form.errors.phone}
                      />
                    )}
                  </Field>
                </FlexContainer>
                <FlexContainer column className={'gap-20 width100'}>
                  <Label className="width100 padtb10 text-20 bold">
                    Datos del destinatario
                  </Label>
                  <Field name="recipientName">
                    {({ field, form }) => (
                      <TextField
                        {...field}
                        label="Nombre del destinatario"
                        variant="outlined"
                        onChange={e =>
                          setFieldValue('recipientName', e.target.value)
                        }
                        error={Boolean(
                          form.errors.recipientName &&
                            form.touched.recipientName,
                        )}
                        helperText={
                          form.touched.recipientName &&
                          form.errors.recipientName
                        }
                      />
                    )}
                  </Field>
                  <Field name="recipientEmail">
                    {({ field, form }) => (
                      <TextField
                        {...field}
                        label="Correo del destinatario"
                        variant="outlined"
                        onChange={e =>
                          setFieldValue('recipientEmail', e.target.value)
                        }
                        error={Boolean(
                          form.errors.recipientEmail &&
                            form.touched.recipientEmail,
                        )}
                        helperText={
                          form.touched.recipientEmail &&
                          form.errors.recipientEmail
                        }
                      />
                    )}
                  </Field>
                  <Field name="recipientPhone">
                    {({ field, form }) => (
                      <TextField
                        {...field}
                        label="Teléfono del destinatario"
                        variant="outlined"
                        onChange={e =>
                          setFieldValue('recipientPhone', e.target.value)
                        }
                        error={Boolean(
                          form.errors.recipientPhone &&
                            form.touched.recipientPhone,
                        )}
                        helperText={
                          form.touched.recipientPhone &&
                          form.errors.recipientPhone
                        }
                      />
                    )}
                  </Field>
                </FlexContainer>
              </FlexContainer>
              <FlexContainer
                className={'gap-20 parlr20 width100 justify-center margt10'}
              >
                <Field name="recipientMessage">
                  {({ field, form }) => (
                    <TextField
                      {...field}
                      label="Mensaje para el destinatario"
                      variant="outlined"
                      multiline
                      rows={4}
                      onChange={e =>
                        form.setFieldValue('recipientMessage', e.target.value)
                      }
                      error={Boolean(
                        form.errors.recipientMessage &&
                          form.touched.recipientMessage,
                      )}
                      helperText={
                        form.touched.recipientMessage &&
                        form.errors.recipientMessage
                      }
                      fullWidth
                    />
                  )}
                </Field>
              </FlexContainer>
              <FlexContainer
                className={'gap-20 parlr20 width100 justify-center margt10'}
              >
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={
                    'bgBlue400 width100 bold border-rad8px borderGray050 fontWhite grayShadow16 margtb20 padlr40 padtb20 text-18'
                  }
                >
                  Enviar
                </button>
              </FlexContainer>
            </Form>
          )}
        </Formik>
      </FlexContainer>
    </FlexContainer>
  );
};

const CheckoutFormComponent = props => {
  const { spaceName, spacePrice, noPromoSpacePrice, formData, spaceToBuy } =
    props;
  const options = {
    mode: 'payment',
    amount: Math.round(spacePrice * 100),
    //currency: 'usd',
    currency: 'mxn',
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  };
  return (
    <FlexContainer className={'m-flex-column width100 border-rad16px bgWhite'}>
      <FlexContainer
        className={
          'flex-column height100 format100 m-format95 padlr10 margtb20'
        }
      >
        <Elements stripe={stripePromise} options={options}>
          <FlexContainer className="padtb10 bgGray025 margt6" />
          <Label className={'margt10 text-16 m-text-12'}>
            Precio total a pagar:{' '}
            <span className={'bold'}>${spacePrice.toFixed(2)} MXN</span>
          </Label>
          <CheckoutForm
            spacePrice={spacePrice}
            formData={formData}
            spaceToBuy={spaceToBuy}
          />
        </Elements>
      </FlexContainer>
      <FlexContainer
        className={'height100 format100 m-format90 padlr10 margtb20'}
      >
        <FlexContainer className={'flex-column'}>
          <Label className="width100 padtb10 text-26 m-padlr0">
            <strong>Pase de coworking - {spaceName}</strong>
          </Label>
          <Label className="width100 margt40 padtb10 text-20 m-padlr0">
            <strong>Certificado de regalo:</strong>
          </Label>
          <Label className="width100 padtb10 text-20 m-padlr0">
            <strong>Cantidad 1: </strong>
          </Label>
          <Label className="width100 margt40 padtb10 text-20 m-padlr0">
            <strong>Subtotal:</strong>
          </Label>
          <Label className="width100 padtb10 text-20 m-padlr0">
            <strong>Envio:</strong>
          </Label>
          <Label className="width100 margt40 padtb10 text-20 m-padlr0">
            <strong>Total:</strong>
          </Label>
        </FlexContainer>
        <FlexContainer className={'flex-column'}>
          <Label className="width100 margt94 padtb10 text-20 m-padlr0">
            <strong>Pase de coworking(1 día)</strong>
          </Label>
          <Label className="width100 padtb10 text-20 m-padlr0">
            $380.00 MXN
          </Label>
          <Label className="width100 margt40 padtb10 text-20 m-padlr0">
            $380.00 MXN
          </Label>
          <Label className="width100 padtb10 text-20 m-padlr0">$0.00 MXN</Label>
          <Label className="width100 margt40 padtb10 text-20 m-padlr0">
            $380.00 MXN
          </Label>
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};

const PromoLanding = props => {
  const { spacesDataFromBackend } = props;
  const [detailData, setDetailData] = useState({});
  const [showForm, setShowForm] = useState(false);
  const [showCheckout, setShowCheckout] = useState(false);
  const [spaceToBuy, setSpaceToBuy] = useState(false);
  const [formData, setFormData] = useState({});
  const lenguageData = useSelector(state => state.lenguage.lang);
  const esDescription =
    '¡Trabaja donde quieras, cuando quieras! Obtén acceso exclusivo a los mejores espacios de coworking con Popnest. Flexibilidad, comodidad y productividad en un solo lugar. ¡Reserva ahora y aprovecha nuestras ofertas especiales!';
  const enDescription =
    'Work anywhere, anytime! Get exclusive access to the best coworking spaces with Popnest. Flexibility, comfort, and productivity in one place. Book now and take advantage of our special offers!';

  useEffect(() => {
    window.scrollTo(0, 0);
    // Agregar el script del Meta Pixel al cargar la página
    const script = document.createElement('script');
    script.innerHTML = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '2105730089859728');
      fbq('track', 'PageView');
    `;
    document.head.appendChild(script);

    const noscript = document.createElement('noscript');
    noscript.innerHTML = `
      <img height="1" width="1" style="display:none"
      src="https://www.facebook.com/tr?id=2105730089859728&ev=PageView&noscript=1" />
    `;
    document.body.appendChild(noscript);

    return () => {
      document.head.removeChild(script);
      document.body.removeChild(noscript);
    };
  }, []);

  const setShowDataForm = value => {
    window.scrollTo(0, 0);
    setShowForm(value);
  };

  return (
    <FlexContainer
      className={
        'flex-column bgBlue400 padtb180 m-padtb100 padlr120 m-padlr20 justify-center'
      }
    >
      <HelmetComponent
        title={
          lenguageData === 'EN'
            ? 'Promo Landing - Popnest'
            : 'Aterrizaje promocional - Popnest'
        }
        description={lenguageData === 'EN' ? enDescription : esDescription}
        graphTitle={
          lenguageData === 'EN'
            ? 'Promo Landing - Popnest'
            : 'Aterrizaje promocional - Popnest'
        }
        graphDescription={lenguageData === 'EN' ? enDescription : esDescription}
      />
      <Label className="width100 margb40 text-20 text-center justify-center">
        <h1 className={'bold fontWhite'}>Promoción de Navidad</h1>
        {!showForm && (
          <h3 className={'bold fontWhite'}>
            Selecciona una de las siguientes opciones:
          </h3>
        )}
      </Label>
      <FlexContainer className={'m-flex-column bgBlue400  justify-center'}>
        {!showForm && (
          <FlexContainer
            className={'m-flex-column format60 m-format100 gap-40'}
          >
            <IndividualSpace
              spaceId={10}
              setShowForm={setShowDataForm}
              setSpaceToBuy={setSpaceToBuy}
              spaceName={
                'Anatole France 51, Polanco III Secc, Miguel Hidalgo, 11540, CDMX'
              }
              spacePrice={380}
              noPromoSpacePrice={450}
              imageOne={
                'https://storage.googleapis.com/popnest-static-files/spaces/-nZvT3bpo86FHa6LSCZEMYgjiJp-VE3cNbiLswwnObk.jfif'
              }
              imageTwo={
                'https://storage.googleapis.com/popnest-static-files/spaces/cONqWrwDMOq2MKkNazBe--z6nk5DNac3qiFadvNRQYw.jfif'
              }
              generalUbi={'Polanco'}
            />
            <IndividualSpace
              spaceId={59}
              setShowForm={setShowDataForm}
              setSpaceToBuy={setSpaceToBuy}
              spaceName={
                'Ignacio Ramírez, Cuauhtémoc, 06030 Ciudad de México, CDMX'
              }
              spacePrice={380}
              noPromoSpacePrice={450}
              imageOne={
                'https://storage.googleapis.com/popnest-static-files/spaces/BeSNMjcA304k4LFo4Bj9Ch0RsO7JfsQVSUkB5Bq0_1c.jfif'
              }
              imageTwo={
                'https://storage.googleapis.com/popnest-static-files/spaces/CP00zb1gc6bGS0kOdkudEGEMZLi97Ar1nXKedgcD0LM.jfif'
              }
              generalUbi={'Reforma'}
            />
          </FlexContainer>
        )}
        {showForm && !showCheckout && (
          <SpaceDetailForm
            setShowCheckout={setShowCheckout}
            spaceName={'Polanco'}
            spacePrice={380}
            noPromoSpacePrice={450}
            setFormData={setFormData}
          />
        )}
        {showCheckout && (
          <CheckoutFormComponent
            spaceName={'Polanco'}
            spacePrice={380}
            noPromoSpacePrice={450}
            formData={formData}
            spaceToBuy={spaceToBuy}
          />
        )}
      </FlexContainer>
      <Label className="width100 margt60 text-20 text-center justify-center">
        <p className={'bold fontWhite text-12'}>
          Al acceder a la promoción aceptas los siguientes&nbsp;
          <Link
            to={
              lenguageData === 'ES'
                ? '/terminos-condiciones-promocion'
                : '/terms-conditions-promo'
            }
            className="link-class"
          >
            términos y condiciones
          </Link>
          .
        </p>
      </Label>
    </FlexContainer>
  );
};

export default PromoLanding;
