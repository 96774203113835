import React, { useEffect } from 'react';
import { useProductDetailsLandingPage } from './hooks/ProductDetailsLandingPage.hook';
import ProductDetailsLandingPageView from './views/ProductDetailsLandingPageView';
import LoadingComponent from '@/components/general/Loading/LoadingComponent';

const ProductDetailsLandingPageController = (props) => {
  const { language, space } = props;
  let jsonSpace = {};
  if (space === 7) {
    jsonSpace = {
      price: 450,
      coordinates: {
        lat: 19.4289183,
        lng: -99.2003243,
      },
      location:
        'Anatole France 51, Polanco, Polanco III Secc, Miguel Hidalgo, 11540 Ciudad de México, CDMX, México',
      spaceCode: 7,
      title: {
        ES: 'Day Pass Polanco Coworking',
        EN: 'Day Pass Polanco Coworking',
      },
      description: {
        ES: 'Ubicado en Polanquito, este coworking combina elegancia con el encanto de su entorno. Tiene un diseño moderno y lleno de luz natural. Diseñado para profesionales que buscan productividad y estilo, este espacio ofrece una experiencia inspiradora.',
        EN: 'Located in Polanquito, this coworking space combines elegance with the charm of its surroundings. It features a modern design filled with natural light. Designed for professionals seeking productivity and style, this space offers an inspiring experience.',
      },
      specs: [
        {
          title: { ES: 'Coffee Lab', EN: 'Coffee Lab' },
          description: {
            ES: 'Disfruta de nuestra mezcla de granos',
            EN: 'Enjoy our blend of beans.',
          },
        },
        {
          title: { ES: 'Arte', EN: 'Art' },
          description: {
            ES: 'Exhibición permanente de MAD Gallery',
            EN: 'Permanent exhibition of MAD Gallery',
          },
        },
        {
          title: { ES: 'Terraza', EN: 'Terrace' },
          description: {
            ES: 'Para consumir alimentos',
            EN: 'For food consumption',
          },
        },
        {
          title: { ES: 'Talks', EN: 'Talks' },
          description: {
            ES: 'Conferencias sobre Business Innovation',
            EN: 'Conferences on Business Innovation',
          },
        },
        {
          title: { ES: 'Networking', EN: 'Networking' },
          description: {
            ES: 'Eventos de alto impacto cada mes',
            EN: 'High-impact events every month',
          },
        },
        {
          title: { ES: 'Servicio Boutique', EN: 'Boutique Service' },
          description: {
            ES: 'Excelencia en atención al cliente',
            EN: 'Excellence in customer service',
          },
        },
        {
          title: { ES: 'Ubicación', EN: 'Location' },
          description: {
            ES: 'Anatole France 51, Polanco, CDMX',
            EN: 'Anatole France 51, Polanco, CDMX',
          },
        },
        {
          title: { ES: 'Salas de Junta', EN: 'Meeting Rooms' },
          description: {
            ES: '5 espacios para reuniones privadas',
            EN: '5 spaces for private meetings',
          },
        },
        {
          title: { ES: 'Estacionamiento', EN: 'Parking' },
          description: {
            ES: 'Valet Parking y convenio con pensiones',
            EN: 'Valet Parking and agreements with parking lots',
          },
        },
      ],
      mainImage: './images/carousel6.png',
      secondaryImageA: './images/carousel5.png',
      secondaryImageB: './images/carousel4.png',
      sideImage: './images/carousel3.png',
    };
  } else if (space === 55) {
    jsonSpace = {
      price: 450,
      coordinates: {
        lat: 19.4120562,
        lng: -99.1807024,
      },
      location: 'Zamora 187, Colonia Condesa, Cuauhtémoc, 06140 Ciudad de México, CDMX, México',
      spaceCode: 55,
      title: {
        ES: 'Day Pass Condesa Coworking',
        EN: 'Day Pass Condesa Coworking',
      },
      description: {
        ES: 'Ubicado en Condesa. Es mucho más que un coworking, es un ecosistema diseñado para que empresarios, profesionales y startups encuentren un entorno que fomenta el crecimiento y el desarrollo de ideas disruptivas.',
        EN: 'Located in Condesa, this is much more than a coworking space; it is an ecosystem designed for entrepreneurs, professionals, and startups to find an environment that fosters growth and the development of disruptive ideas.',
      },
      specs: [
        {
          title: { ES: 'Tecnología', EN: 'Technology' },
          description: {
            ES: 'Impresora 3D, Cabina de grabación',
            EN: '3D printer, Recording booth',
          },
        },
        {
          title: { ES: 'Bienestar', EN: 'Wellness' },
          description: {
            ES: 'Pet friendly, Área de juegos y relax, Sala de silencio',
            EN: 'Pet friendly, Play and relaxation area, Silent room',
          },
        },
        {
          title: { ES: 'Terraza', EN: 'Terrace' },
          description: {
            ES: 'Para consumir alimentos',
            EN: 'For food consumption',
          },
        },
        {
          title: { ES: 'Academy', EN: 'Academy' },
          description: {
            ES: 'Contenido y recursos educativos',
            EN: 'Educational content and resources',
          },
        },
        {
          title: { ES: 'Networking', EN: 'Networking' },
          description: {
            ES: 'Eventos de alto impacto cada mes',
            EN: 'High-impact events every month',
          },
        },
        {
          title: { ES: 'Servicio Boutique', EN: 'Boutique Service' },
          description: {
            ES: 'Excelencia en la atención al cliente',
            EN: 'Excellence in customer service',
          },
        },
        {
          title: { ES: 'Ubicación', EN: 'Location' },
          description: {
            ES: 'Zamora 187, Colonia Condesa',
            EN: 'Zamora 187, Colonia Condesa',
          },
        },
        {
          title: { ES: 'Salas de Junta', EN: 'Meeting Rooms' },
          description: {
            ES: 'Espacios para reuniones privadas',
            EN: 'Spaces for private meetings',
          },
        },
        {
          title: { ES: 'Eventos', EN: 'Events' },
          description: {
            ES: 'Opciones de catering, organización de eventos',
            EN: 'Catering options, event organization',
          },
        },
      ],
      mainImage: './images/B-carousel6.png',
      secondaryImageA: './images/B-carousel5.png',
      secondaryImageB: './images/B-carousel4.png',
      sideImage: './images/B-carousel3.png',
    };
  } else if (space === 10) {
    jsonSpace = {
      price: 1600,
      coordinates: {
        lat: 19.4289183,
        lng: -99.2003243,
      },
      location:
        'Anatole France 51, Polanco, Polanco III Secc, Miguel Hidalgo, 11540 Ciudad de México, CDMX, México',
      spaceCode: 10,
      title: {
        ES: 'Sala de juntas Polanco',
        EN: 'Meeting Room Polanco',
      },
      description: {
        ES: 'Ubicado en Polanquito, esta sala de juntas combina elegancia con el encanto de su entorno. Tiene un diseño moderno y lleno de luz natural. Diseñado para profesionales que buscan productividad y estilo, este espacio ofrece una experiencia inspiradora.',
        EN: 'Located in Polanquito, this meeting room combines elegance with the charm of its surroundings. It features a modern design filled with natural light. Designed for professionals seeking productivity and style, this space offers an inspiring experience.',
      },
      specs: [
        {
          title: { ES: 'Coffee Lab', EN: 'Coffee Lab' },
          description: {
            ES: 'Disfruta de nuestra mezcla de granos',
            EN: 'Enjoy our blend of beans.',
          },
        },
        {
          title: { ES: 'Arte', EN: 'Art' },
          description: {
            ES: 'Exhibición permanente de MAD Gallery',
            EN: 'Permanent exhibition of MAD Gallery',
          },
        },
        {
          title: { ES: 'Terraza', EN: 'Terrace' },
          description: {
            ES: 'Para consumir alimentos',
            EN: 'For food consumption',
          },
        },
        {
          title: { ES: 'Talks', EN: 'Talks' },
          description: {
            ES: 'Conferencias sobre Business Innovation',
            EN: 'Conferences on Business Innovation',
          },
        },
        {
          title: { ES: 'Networking', EN: 'Networking' },
          description: {
            ES: 'Eventos de alto impacto cada mes',
            EN: 'High-impact events every month',
          },
        },
        {
          title: { ES: 'Servicio Boutique', EN: 'Boutique Service' },
          description: {
            ES: 'Excelencia en atención al cliente',
            EN: 'Excellence in customer service',
          },
        },
        {
          title: { ES: 'Ubicación', EN: 'Location' },
          description: {
            ES: 'Anatole France 51, Polanco, CDMX',
            EN: 'Anatole France 51, Polanco, CDMX',
          },
        },
        {
          title: { ES: 'Salas de Junta', EN: 'Meeting Rooms' },
          description: {
            ES: '5 espacios para reuniones privadas',
            EN: '5 spaces for private meetings',
          },
        },
        {
          title: { ES: 'Estacionamiento', EN: 'Parking' },
          description: {
            ES: 'Valet Parking y convenio con pensiones',
            EN: 'Valet Parking and agreements with parking lots',
          },
        },
      ],
      mainImage: './images/C-carousel6.png',
      secondaryImageA: './images/C-carousel5.png',
      secondaryImageB: './images/C-carousel4.png',
      sideImage: './images/C-carousel3.png',
    };
  }
  const { isLoading, spacesDataFromBackend } = useProductDetailsLandingPage();
  if (isLoading) {
    return <LoadingComponent loading={isLoading} />;
  } else {
    return (
      <ProductDetailsLandingPageView
        spacesDataFromBackend={spacesDataFromBackend}
        language={language}
        jsonSpace={jsonSpace}
        space={space}
      />
    );
  }
};

export default ProductDetailsLandingPageController;
