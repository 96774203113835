import { useQuery, useMutation } from '@tanstack/react-query';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchAllPostCommentsCopy = async () => {
  const response = await fetch(`${apiUrl}/GET/post_comments_copy`);
  if (!response.ok) {
    throw new Error('Error fetching post comments');
  }
  return response.json();
};

export const fetchPostCommentCopyById = async id => {
  const response = await fetch(`${apiUrl}/GET/post_comments_copy/${id}`);
  if (!response.ok) {
    throw new Error('Error fetching post comment by ID');
  }
  return response.json();
};

export const createPostCommentCopy = async commentDataCopy => {
  const response = await fetch(`${apiUrl}/POST/post_comments_copy`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(commentDataCopy),
  });

  if (!response.ok) {
    throw new Error('Error creating post comment');
  }
  return response.json();
};

export const updatePostCommentCopy = async ({ id, commentDataCopy }) => {
  const response = await fetch(`${apiUrl}/PUT/post_comments_copy/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(commentDataCopy),
  });
  if (!response.ok) {
    throw new Error('Error updating post comment');
  }
  return response.json();
};

export const deletePostCommentCopy = async id => {
  const response = await fetch(
    `${apiUrl}/PUT/delete_post_comments_copy/${id}`,
    {
      method: 'PUT',
    },
  );

  if (!response.ok) {
    throw new Error('Error deleting post comment');
  }
  return response.json();
};

export const deletePostCommentsCopySeveral = async commentIds => {
  const response = await fetch(
    `${apiUrl}/PUT/delete_post_comments_copy_several`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ commentIds }),
    },
  );

  if (!response.ok) {
    throw new Error('Error deleting multiple post comments');
  }
  return response.json();
};

export const enabledPostCommentsCopySeveral = async commentIds => {
  const response = await fetch(
    `${apiUrl}/PUT/enabled_post_comments_copy_several`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ commentIds }),
    },
  );

  if (!response.ok) {
    throw new Error('Error enabling multiple post comments');
  }
  return response.json();
};

export const disabledPostCommentsCopySeveral = async commentIds => {
  const response = await fetch(`${apiUrl}/PUT/disabled_post_comments_several`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ commentIds }),
  });

  if (!response.ok) {
    throw new Error('Error disabling multiple post comments');
  }
  return response.json();
};

export const useFetchAllPostCommentsCopy = () => {
  return useQuery({
    queryKey: ['postCommentsCopy'],
    queryFn: fetchAllPostCommentsCopy,
  });
};

export const useFetchPostCommentById = id => {
  return useQuery({
    queryKey: ['postCommentCopy', id],
    queryFn: () => fetchPostCommentCopyById(id),
    enabled: !!id,
  });
};

export const useCreatePostCommentCopy = () => {
  return useMutation({
    mutationFn: createPostCommentCopy,
    onSuccess: () => {
      console.log('Post comment created successfully');
    },
    onError: error => {
      console.error('Error creating post comment:', error);
    },
  });
};

export const useUpdatePostCommentCopy = () => {
  return useMutation({
    mutationFn: updatePostCommentCopy,
    onSuccess: () => {
      console.log('Post comment updated successfully');
    },
    onError: error => {
      console.error('Error updating post comment:', error);
    },
  });
};

export const useDeletePostCommentCopy = () => {
  return useMutation({
    mutationFn: deletePostCommentCopy,
    onSuccess: () => {
      console.log('Post comment deleted successfully');
    },
    onError: error => {
      console.error('Error deleting post comment:', error);
    },
  });
};

export const useDeletePostCommentsCopySeveral = () => {
  return useMutation({
    mutationFn: deletePostCommentsCopySeveral,
    onSuccess: () => {
      console.log('Multiple post comments deleted successfully');
    },
    onError: error => {
      console.error('Error deleting multiple post comments:', error);
    },
  });
};

export const useEnabledPostCommentsCopySeveral = () => {
  return useMutation({
    mutationFn: enabledPostCommentsCopySeveral,
    onSuccess: () => {
      console.log('Multiple post comments enabled successfully');
    },
    onError: error => {
      console.error('Error enabling multiple post comments:', error);
    },
  });
};

export const useDisabledPostCommentsCopySeveral = () => {
  return useMutation({
    mutationFn: disabledPostCommentsCopySeveral,
    onSuccess: () => {
      console.log('Multiple post comments disabled successfully');
    },
    onError: error => {
      console.error('Error disabling multiple post comments:', error);
    },
  });
};
