import { useQuery } from "@tanstack/react-query";

const apiIpInfoUrl = "https://ipinfo.io";
const tokenIpInfo = "844b0687b78073";
const apiIpApiUrl = "http://ip-api.com";

// Función única que intenta primero con ipinfo.io y si falla, usa ip-api.com
const fetchIpService = async () => {
  try {
    // ipinfo.io (HTTPS)
    const response = await fetch(`${apiIpInfoUrl}/json?token=${tokenIpInfo}`);
    if (!response.ok) {
      throw new Error("Error fetching IP using IpInfo (https)");
    }
    return response.json();
  } catch (error) {
    console.warn("Fallo IpInfo, intentando con IpApi...", error);
    const fallbackResponse = await fetch(`${apiIpApiUrl}/json`);
    if (!fallbackResponse.ok) {
      throw new Error("Error fetching IP using IpApi (http)");
    }
    return fallbackResponse.json();
  }
};

export const useFetchIpService = () => {
  return useQuery({
    queryKey: ["IpService"],
    queryFn: fetchIpService,
    staleTime: 600000, 
    retry: 1,
  });
};