import * as Yup from 'yup';

export const validationSchema = Yup.object({
  startDate: Yup.date().required('Fecha de inicio requerida').nullable(),
  startTime: Yup.string().required('Hora de inicio requerida').nullable(),
  endTime: Yup.string().required('Hora de fin requerida').nullable(),
  name: Yup.string()
    .required('Nombre requerido')
    .min(3, 'El nombre debe tener al menos 3 caracteres'),
  email: Yup.string()
    .email('Correo electrónico no válido')
    .required('Correo electrónico requerido'),
  phone: Yup.string()
    .required('Teléfono requerido')
    .matches(/^[0-9]+$/, 'El teléfono solo puede contener números')
    .min(8, 'El teléfono debe tener al menos 8 dígitos')
    .max(15, 'El teléfono no puede tener más de 15 dígitos'),
  guestQuantyty: Yup.number()
    .required('Cantidad de participantes requerida')
    .min(1, 'Debe haber al menos un participante')
    .max(100, 'No puede haber más de 100 participantes'),
});
