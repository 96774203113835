import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { FacebookProvider } from '@kazion/react-facebook-login';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { HelmetProvider } from 'react-helmet-async';
import store from './redux/store';
import './index.css';
import App from './App';
import ReactPixel from 'react-facebook-pixel';
import reportWebVitals from './reportWebVitals';

const queryClient = new QueryClient();
const BrevoScript = () => {
  /*
  useEffect(() => {
    (function (d, w, c) {
      w.BrevoConversationsID = '6724ed87f09e6f7287056e4e';
      w[c] =
        w[c] ||
        function () {
          (w[c].q = w[c].q || []).push(arguments);
        };
      const s = d.createElement('script');
      s.async = true;
      s.src = 'https://conversations-widget.brevo.com/brevo-conversations.js';
      if (d.head) d.head.appendChild(s);
    })(document, window, 'BrevoConversations');
  }, []);
  return null;
  */
};

const pixelId = '933303128976183';

const options = {
  autoConfig: true,
  debug: true,
};

ReactPixel.init(pixelId, options);
ReactPixel.pageView();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrevoScript />
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <GoogleOAuthProvider clientId="722633985733-f75idvl4lsof3mfus8spsoaq33ao9qnb.apps.googleusercontent.com">
            <FacebookProvider appId="872646961658964" version="v19.0">
              <HelmetProvider>
                <App />
              </HelmetProvider>
            </FacebookProvider>
          </GoogleOAuthProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </Provider>
  </React.StrictMode>,
);

reportWebVitals();
