import React, { useEffect } from 'react';
import AppRoutes from '@/router/appRoutes';
import Footer from '@/components/general/Footer/Footer';
import Header from '@/components/general/Header/Header';
import { useDispatch } from 'react-redux';
import { setLocation } from '@/redux/actions/locationActions';
import { useLocation } from 'react-router-dom';
import { setLenguageData } from '@/redux/actions/lenguageDataActions';
import { useSelector } from 'react-redux';
import { useFetchIpService } from '@/services/locationService';
import { isEnglishSpeakingCountry } from '@/helpers/locationHelpers';
import '@/App.css';
import '@/style/main.scss';

function App() {
  const dispatch = useDispatch();

  const routesWithoutHeaderOptions = [
    '/paseDeCoworkingPolanco',
    '/dayPassCoworkingPolanco',
    '/paseDeCoworkingCondesa',
    '/dayPassCoworkingCondesa',
    '/salaDeJuntasPolanco',
    '/meetingRoomPolanco',
  ];

  const { data, isLoading, error } = useFetchIpService();
  const location = useLocation();
  const hideFooter = routesWithoutHeaderOptions?.includes(location.pathname);
  const languageIsFromButton = useSelector((state) => state.lenguage?.isButton);

  if (isLoading) {
    return <></>;
  } else {
    if (data) {
      if (!languageIsFromButton) {
        dispatch(setLocation(data));
        isEnglishSpeakingCountry(data)
          ? dispatch(setLenguageData({ lang: 'EN', isButton: false }))
          : dispatch(setLenguageData({ lang: 'ES', isButton: false }));
      }
      return (
        <div>
          <Header />
          <AppRoutes />
          {!hideFooter && <Footer />}
        </div>
      );
    } else {
      return <></>;
    }
  }
}

export default App;
