import React, { useState, useEffect } from 'react';
import { FlexContainer } from '@/components/containers/FlexContainer/FlexContainer';
import { useSelector } from 'react-redux';
import HelmetComponent from '@/components/general/Helmet/HelmetComponent';
import { PageContainer } from '@/components/containers/PageContainer/PageContainer';
import Label from '@/components/form/Label/Label';
import './TermsAndConditions.scss';

const TermsAndConditions = props => {
  const { spacesData } = props;
  const lenguageData = useSelector(state => state.lenguage.lang);
  const esDescription =
    'Al reservar en Popnest, aceptas nuestras normas de uso, pago y cancelación. Respetamos tu privacidad y te pedimos cuidar el espacio y a otros usuarios. Consulta nuestros términos para más detalles.';
  const enDescription =
    'By booking with Popnest, you agree to our usage, payment, and cancellation policies. We respect your privacy and ask you to care for the space and other users. Check our terms for more details.';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <PageContainer className={'termsView flex-column margtb80 text-justify'}>
      <HelmetComponent
        title={
          lenguageData === 'EN'
            ? 'Terms and Conditions - Popnest'
            : 'Terminos y Condiciones - Popnest'
        }
        description={lenguageData === 'EN' ? enDescription : esDescription}
        graphTitle={
          lenguageData === 'EN'
            ? 'Terms and Conditions - Popnest'
            : 'Terminos y Condiciones - Popnest'
        }
        graphDescription={lenguageData === 'EN' ? enDescription : esDescription}
      />
      <Label className={'text-12 m-text-10'}>
        <h1 className={'Subtitle-1 text-22'}>
          Términos y Condiciones de los anfitriones
        </h1>

        <p>
          FINOVIX S.A.P.I. de C.V. (en lo sucesivo POPNEST), con domicilio en
          Coyoacán, CDMX, da a conocer por este medio el convenio donde se
          describen los Términos y Condiciones generales de uso de su página web
          (en adelante, la “Página”) y/o cualquier otra plataforma digital que
          exponga a tu disposición, en las cuales se pueden encontrar en forma
          enunciativa más no limitativa, información, servicios, consultas,
          contenidos y/o productos (en adelante los “Servicios”).
        </p>

        <p>
          Este contrato describe los términos y condiciones generales (en
          adelante únicamente "TÉRMINOS Y CONDICIONES") aplicables al uso de los
          contenidos, productos y servicios ofrecidos a través del sitio{' '}
          <a href="https://www.popnest.org/">https://www.popnest.org/</a> (en
          adelante, "SITIO WEB"), del cual es titular FINOVIX S.A.P.I. de C.V.
          (en adelante, POPNEST). Quien desee acceder o usar el sitio o los
          servicios ofrecidos, podrá hacerlo sujetándose a los presentes
          TÉRMINOS Y CONDICIONES, así como a políticas y principios incorporados
          a este documento.
        </p>

        <p>
          En todo caso, cualquier persona que no acepte los presentes términos y
          condiciones, deberá abstenerse de utilizar el SITIO WEB y/o adquirir
          los productos y servicios que en su caso sean ofrecidos.
        </p>

        <h3 className={'Subtitle-2 margt40'}>I. DEL OBJETO.</h3>

        <p>
          El objeto de los presentes TÉRMINOS Y CONDICIONES es regular el acceso
          y la utilización del SITIO WEB, entendiendo por este cualquier tipo de
          contenido, producto o servicio que se encuentre a disposición del
          público en general dentro del dominio:{' '}
          <a href="https://www.popnest.org/">https://www.popnest.org/</a>.
        </p>

        <p>
          POPNEST se reserva la facultad de modificar en cualquier momento y sin
          previo aviso, la presentación, los contenidos, la funcionalidad, los
          productos y los servicios, que pudieran estar contenidos en el SITIO
          WEB; en este sentido, el ANFITRIÓN reconoce y acepta que POPNEST en
          cualquier momento podrá interrumpir, desactivar o cancelar cualquiera
          de los elementos que conforman el SITIO WEB o el acceso a los mismos.
        </p>

        <p>
          Se podrán aplicar condiciones suplementarias a determinados Servicios,
          como políticas para una actividad o una promoción particular, y dichas
          condiciones suplementarias se comunicarán en relación con los
          Servicios aplicables. Las condiciones suplementarias se establecen
          además de los Términos y Condiciones, y se considerarán una parte más
          de éstas, para los fines de los Servicios aplicables. Las condiciones
          suplementarias prevalecerán sobre las Condiciones en el caso de
          conflicto con respecto a los Servicios aplicables.
        </p>

        <p>
          El acceso al SITIO WEB por parte del ANFITRIÓN tiene carácter libre y,
          por regla general es gratuito sin que el ANFITRIÓN tenga que
          proporcionar una contraprestación para poder disfrutar de ello, salvo
          en lo referente al costo de la conexión a internet suministrada por el
          proveedor de este tipo de servicios que hubiere contratado el mismo
          ANFITRIÓN.
        </p>

        <p>
          El SITIO WEB se encuentra dirigido exclusivamente a personas que
          cuenten con la mayoría de edad (mayores de 18 años); en este sentido,
          POPNEST declina cualquier responsabilidad por el incumplimiento de
          este requisito.
        </p>

        <p>
          El SITIO WEB está dirigido principalmente a residentes en la República
          Mexicana, por lo cual, POPNEST no asegura que el SITIO WEB cumpla
          total o parcialmente con la legislación de otros países, de forma que,
          si el ANFITRIÓN reside o tiene su domicilio establecido en otro país y
          decide acceder o utilizar el SITIO WEB lo hará bajo su propia
          responsabilidad y deberá asegurarse de que tal acceso y navegación
          cumple con la legislación local que le es aplicable, no asumiendo
          POPNEST ninguna responsabilidad que se pueda derivar de dicho acto.
        </p>

        <p>
          Se hace del conocimiento del ANFITRIÓN que POPNEST podrá administrar o
          gestionar el SITIO WEB de manera directa o a través de un tercero, lo
          cual no modifica en ningún sentido lo establecido en los presentes
          TÉRMINOS Y CONDICIONES.
        </p>

        <h3 className={'Subtitle-2 margt40'}>II. DEL ANFITRIÓN.</h3>

        <p>
          El acceso o utilización del SITIO WEB, así como de los recursos
          habilitados para interactuar entre los USUARIOS, o entre el ANFITRIÓN
          y POPNEST tales como medios para realizar publicaciones o comentarios,
          confiere la condición de ANFITRIÓN del SITIO WEB, por lo que quedará
          sujeto a los presentes TÉRMINOS Y CONDICIONES, así como a sus
          ulteriores modificaciones, sin perjuicio de la aplicación de la
          legislación aplicable, por tanto, se tendrán por aceptados desde el
          momento en el que se accede al SITIO WEB. Dada la relevancia de lo
          anterior, se recomienda al ANFITRIÓN revisar las actualizaciones que
          se realicen a los presentes TÉRMINOS Y CONDICIONES.
        </p>

        <p>
          Es responsabilidad del ANFITRIÓN utilizar el SITIO WEB de acuerdo con
          la forma en la que fue diseñado; en este sentido, queda prohibida la
          utilización de cualquier tipo de software que automatice la
          interacción o descarga de los contenidos o servicios proporcionados a
          través del SITIO WEB.
        </p>

        <h3 className={'Subtitle-2 margt40'}>
          X. DEL OFRECIMIENTO DE ESPACIOS
        </h3>

        <p>
          El ANFITRIÓN manifiesta bajo protesta de decir verdad que cuenta con
          las facultades legales necesarias para ofertar el espacio a través del
          SITIO WEB. A fin de respaldar dicha afirmación, el ANFITRIÓN se
          compromete a proporcionar cualquier documentación adicional que pueda
          ser requerida para verificar su legitimidad en la oferta del espacio,
          además se compromete a mantener actualizada la información sobre sus
          facultades legales para ofrecer el inmueble a través del SITIO WEB.
        </p>

        <p>
          POPNEST le ofrece al ANFITRIÓN el derecho a usar su SITIO WEB para
          compartir su espacio, señalando precio, disponibilidad y reglas de
          alojamiento.
        </p>

        <p>
          El ANFITRIÓN se compromete a brindar un servicio de atención al
          cliente eficiente y accesible, disponible para responder cualquier
          duda o inquietud del USUARIO relacionada con el espacio ofertado,
          consolidando su posición como un proveedor confiable y legalmente
          autorizado.
        </p>

        <p>
          El ANFITRIÓN se compromete a proporcionar la información detallada del
          inmueble una vez que el USUARIO manifiesta el interés de reservar o de
          realizar el pago correspondiente. De esta manera, se obtiene acceso a
          detalles específicos sobre el espacio, tales como características
          destacadas, amenidades y cualquier información relevante para su
          estancia, optimizando la experiencia del USUARIO para tomar una
          decisión informada y garantizando que la información proporcionada por
          POPNEST sea actualizada, proporcionando confianza al USUARIO al
          momento de realizar su reserva.
        </p>

        <p>
          POPNEST garantiza una experiencia de reserva transparente al brindar
          información completa y real sobre las condiciones y restricciones de
          cada espacio, permitiendo al USUARIO tomar decisiones informadas al
          momento de realizar su reserva y al ANFITRIÓN tomar decisiones
          informadas al momento de confirmar una reserva.
        </p>

        <h3 className={'Subtitle-2 margt40'}>
          XI. DEL CAMBIO DE LA RESERVACIÓN
        </h3>

        <p>
          El ANFITRIÓN se compromete a cambiar la reserva del USUARIO una vez
          que se refleje el pago total correspondiente, por un espacio de igual
          o superior precio, siempre y cuando realice la solicitud de cambio con
          al menos 15 días naturales de antelación a la fecha de la reserva. Así
          mismo, cualquier cambio estará sujeto a la disponibilidad existente en
          ese momento.
        </p>

        <p>
          El USUARIO se compromete a pagar cualquier cantidad adicional,
          comisión o impuesto que esté vinculado con la modificación.
        </p>

        <h3 className={'Subtitle-2 margt40'}>
          XII. DE LA MODIFICACIÓN DE LA RESERVACIÓN
        </h3>

        <p>
          El ANFITRIÓN se compromete a llevar a cabo la modificación del
          itinerario del USUARIO para extender la duración de estancia en el
          mismo espacio o modificar la fecha de entrada, una vez que se refleje
          el pago total correspondiente, siempre y cuando dicha solicitud se
          realice con al menos 15 días naturales de anticipación a la fecha de
          la reserva. Dicha modificación estará sujeta a la disponibilidad de
          fechas del espacio.
        </p>

        <p>
          Si el USUARIO desea extender la duración de su estancia y añadir horas
          o días a su reserva existente, el ANFITRIÓN se compromete a mantener
          comunicación constante y efectiva con el USUARIO, con el objetivo de
          facilitar el proceso para llevar a cabo el pago correspondiente y
          garantizar la continuidad de la reserva, lo cual estará sujeto a la
          disponibilidad del espacio.
        </p>

        <p>
          Asimismo, el USUARIO se compromete a pagar cualquier cantidad
          adicional, comisión o impuesto que esté vinculado con la modificación.
        </p>

        <p>
          En caso de que el USUARIO requiera prolongar su estancia después de
          haber concluido su reserva inicial, el ANFITRIÓN no está obligado a
          modificar la reserva, por lo que el USUARIO deberá realizar una
          reservación nueva.
        </p>

        <h3 className={'Subtitle-2 margt40'}>
          XIII. DE LA CANCELACIÓN Y DEVOLUCIÓN DE LA RESERVACIÓN
        </h3>

        <p>
          El ANFITRIÓN acepta realizar el reembolso de las cantidades
          correspondientes al pago de alojamiento de conformidad con lo
          siguiente:
        </p>

        <ol>
          <li>
            Para que el USUARIO reciba el 90% de devolución del importe pagado
            por concepto de reservación de algún espacio, deberá cancelar con 30
            días naturales de anticipación a la fecha de inicio del alojamiento.
            De lo contrario, el ANFITRIÓN no está obligado a realizar el
            reembolso.
          </li>
          <li>
            Para que el USUARIO reciba el 70% de devolución del importe pagado
            por concepto de reservación de algún espacio, el ANFITRIÓN deberá
            cancelar con una antelación de 29 a 15 días naturales previos a la
            fecha programada para el inicio del alojamiento. De lo contrario, el
            ANFITRIÓN no está obligado a realizar el reembolso.
          </li>
          <li>
            Para que el USUARIO reciba el 50% de devolución del importe pagado
            por concepto de reservación de espacio, deberá realizar la
            cancelación con una antelación de 14 a 10 días naturales previos a
            la fecha programada para el inicio del alojamiento. De lo contrario,
            el ANFITRIÓN no está obligado a realizar el reembolso.
          </li>
          <li>
            La política de reembolso quedará sin efecto si el USUARIO solicita
            la cancelación dentro de un plazo de 9 días naturales y hasta 24
            horas antes de la fecha de inicio del alojamiento.
          </li>
        </ol>

        <table className={'margtb40'}>
          <tr>
            <th>DÍAS DE ANTICIPACIÓN A LA FECHA DE RESERVA</th>
            <th>PORCENTAJE DE DEVOLUCIÓN</th>
          </tr>
          <tr>
            <td>Hasta 30 días naturales</td>
            <td>90%</td>
          </tr>
          <tr>
            <td>De 29 a 15 días naturales</td>
            <td>70%</td>
          </tr>
          <tr>
            <td>De 14 a 10 días naturales</td>
            <td>50%</td>
          </tr>
          <tr>
            <td>9 días naturales o menos</td>
            <td>No aplica reembolso</td>
          </tr>
        </table>

        <p>
          Se entiende por “día 0”, el día en el que el ANFITRIÓN hace uso del
          espacio. Y se entiende por “día 1”, al día previo al inicio del
          alojamiento.
        </p>

        <p>
          En caso de que la estancia no se pudiere llevar a cabo por
          circunstancias de fuerza mayor o caso fortuito, el ANFITRIÓN se
          compromete a: (i) otorgar el reembolso del monto correspondiente, (ii)
          reprogramar la estancia para otra fecha siempre y cuando se tome en
          consideración la disponibilidad del espacio.
        </p>

        <p>
          POPNEST se compromete a ofrecer flexibilidad en caso de que la
          reprogramación o el cambio de espacio resulten viables, garantizando
          una experiencia de reserva que se ajuste a las preferencias y
          necesidades del ANFITRIÓN.
        </p>

        <h3 className={'Subtitle-2 margt40'}>
          XIV. DE LA POLÍTICA DE RESPONSABILIDAD DEL ANFITRIÓN
        </h3>

        <p>
          El ANFITRIÓN asume la responsabilidad de sus acciones y omisiones
          dentro del espacio proporcionado. Por lo que se compromete a: (i)
          verificar que el USUARIO ocupe y desocupe el espacio en el horario
          convenido, (ii) verificar que el USUARIO dejó el espacio en el mismo
          estado en que se encontraba al momento de su llegada, (iii) solicitar
          al USUARIO el pago por daños para compensar los perjuicios causados
          por este, huéspedes o mascotas a un espacio (iv) el ANFITRIÓN está
          obligado a mantener un comportamiento íntegro, cumpliendo con las
          leyes aplicables en todo momento y (v) mantener comunicación constante
          con el USUARIO y POPNEST.
        </p>

        <h3 className={'Subtitle-2 margt40'}>XV. DEL PAGO</h3>

        <p>
          El sistema de pago para los ANFITRIONES se realizará a través de la
          plataforma de cobro STRIPE, por lo que el ANFITRIÓN deberá completar
          el proceso de incorporación a STRIPE, durante el cual se le solicitará
          agregar la cuenta bancaria designada para recibir los depósitos
          provenientes de POPNEST.
        </p>

        <p>
          POPNEST, como parte de su gestión financiera, administrará una cuenta
          a través de la plataforma STRIPE, desde la que se realizarán los
          depósitos correspondientes a la cuenta de STRIPE del ANFITRIÓN de
          manera segura y eficiente. Este método garantiza una transacción
          financiera fluida y confiable entre POPNEST y el ANFITRIÓN, asegurando
          la integridad de los pagos.
        </p>

        <p>
          El ANFITRIÓN acepta que sus datos bancarios sean recabados por STRIPE,
          para efecto de llevar a cabo el procedimiento de pago. Para más
          información, favor de consultar la siguiente liga:{' '}
          <a href="https://stripe.com/docs/connect/add-and-pay-out-guide">
            https://stripe.com/docs/connect/add-and-pay-out-guide
          </a>
          .
        </p>

        <h3 className={'Subtitle-2 margt40'}>
          XVI. DE LAS DUDAS, RECLAMACIONES O ACLARACIONES
        </h3>

        <p>
          El ANFITRIÓN puede emitir dudas, reclamaciones o aclaraciones a
          POPNEST a través del correo electrónico:{' '}
          <a href="mailto:soporte@popnest.co">soporte@popnest.co</a>.
        </p>

        <h3 className={'Subtitle-2 margt40'}>
          XVI. DE LA ATENCIÓN AL ANFITRIÓN
        </h3>

        <p>
          POPNEST pone a disposición del ANFITRIÓN el correo electrónico{' '}
          <a href="mailto:soporte@popnest.co">soporte@popnest.co</a>, medio a
          través del cual puede comunicarse con el área de Atención a Clientes,
          en el cual se puede resolver cualquier cuestión relativa al uso del
          SITIO WEB o la compra de productos.
        </p>
      </Label>
    </PageContainer>
  );
};

export default TermsAndConditions;
