import React, { useEffect } from 'react';
import { useReservationSuccess } from './hooks/ReservationSuccess.hook';
import ReservationSuccessView from './views/ReservationSuccessView';
import LoadingComponent from '@/components/general/Loading/LoadingComponent';
import { useLocation } from 'react-router-dom';

const ReservationSuccessController = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const transactionId = searchParams.get('transaction_id');
  const languageFromPath = searchParams.get('lang');
  const { reservation, isLoading, error, spaceDetails } = useReservationSuccess(transactionId);

  useEffect(() => {
    if (error) {
      console.log('Error al cargar la reserva:', error);
    }
  }, [error]);

  if (isLoading) {
    console.log('Cargando...');
    return <LoadingComponent loading={isLoading} />;
  }

  return (
    <ReservationSuccessView
      spaceDetail={spaceDetails}
      reservation={reservation}
      languageFromPath={languageFromPath}
    />
  );
};

export default ReservationSuccessController;
